import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InstitutionDto from "../../models/Institutions/institutionDto";
import { useStore } from "../../stores/store";
import * as Yup from "yup";
import { Button, Dimmer, Header, Loader, Segment, Form, Grid } from "semantic-ui-react";
import { Formik } from "formik";
import CustomTextInput from "../../common/forms/CustomTextInput";
import CustomSelectInput from "../../common/forms/CustomSelectInput";
import { LookupDto } from "../../models/lookupDto";
import agent from "../../api/agent";
import CustomTextAreaInput from "../../common/forms/CustomTextAreaInput";
import CustomCheckboxInput from "../../common/forms/CustomCheckboxInput";

interface Params {
  institutionId: string | undefined;
}

export default function AddEditInstitution() {
  const { institutionId } = useParams<Params>();
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [institutionTypes, setInstitutionTypes] = useState<{ text: string; value: number }[]>([]);
  const [allClassificationTypes, setAllClassificationTypes] = useState<{ text: string; value: number }[]>([]);
  const [filteredClassificationTypes, setFilteredClassificationTypes] = useState<{ text: string; value: number }[]>([]);
  const [schoolStages, setSchoolStages] = useState<{ text: string; value: number }[]>([]);
  const [genderTypes, setGenderTypes] = useState<{ text: string; value: number }[]>([]);
  const [governates, setGovernates] = useState<LookupDto[]>([]);
  const [directorates, setDirectorates] = useState<LookupDto[]>([]);
  const [institutionStatuses, setInstitutionStatuses] = useState<{ text: string; value: number }[]>([]);
  const [institution, setInstitution] = useState<InstitutionDto>({
    name:"",
    institutionType:null,
    classification:null,
    schoolStage: null,
    genderType: null ,
    governateId:null,
    governateName:"", 
    directorateId: null,
    directorateName: "",
    address: null,
    contactPerson: "",
    phoneNumber: null,
    schoolPrincipalName:  null,
    schoolPrincipalPhoneNumber: null,
    schoolPrincipalAssistantName: null,
    schoolPrincipalAssistantPhoneNumber: null,
    schoolLeaderName:  null,
    schoolLeaderPhoneNumber: null,
    ministryCoordinatorName:  null,
    ministryCoordinatorPhoneNumber:  null,
    institutionStatus: 0,
    schoolStudentCount: null,
    schoolWorksWithDoubleShift: false,
    hasSyrianStudentsPeriod: false,
    isActive: true
  } as InstitutionDto);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    let institutionTypesList = lookupsStore.getInstitutions(commonStore.lang);
    setInstitutionTypes(institutionTypesList);

    let schoolStagesList = lookupsStore.getSchoolStages(commonStore.lang);
    setSchoolStages(schoolStagesList);

    let genderTypesList = lookupsStore.getGenderPreferenceList(commonStore.lang);
    setGenderTypes(genderTypesList);

    let institutionStatusesList = lookupsStore.getInstitutionStatuses(commonStore.lang);
    setInstitutionStatuses(institutionStatusesList);

    let institutionClassificationsTypesList = lookupsStore.getInstitutionClassificationTypes(commonStore.lang);
    setAllClassificationTypes(institutionClassificationsTypesList);
    setFilteredClassificationTypes(institutionClassificationsTypesList);
  }, [commonStore.lang]);

  useEffect(() => {
    agent.Lookups.governates()
      .then((result) => {
        setGovernates(result);
      })
      .catch((error) => handleError(error));

    agent.Lookups.directorates()
      .then((result) => {
        setDirectorates(result);
      })
      .catch((error) => handleError(error));

    if (institutionId && institutionId != null) {
      //load institution info
      agent.Institutions.details(institutionId)
        .then((result) => {
          setInstitution(result);
        })
        .catch((error) => handleError(error));
    }
  }, []);

  const submitForm = (values: InstitutionDto) => {
    setIsSaving(true);
    values.governateName = "";
    values.directorateName = "";
    setInstitution(values);
    if (!institutionId || institutionId == null) addNewInstitution(values);
    else updateInstitution(values);
  };

  const addNewInstitution = (values: InstitutionDto) => {
    agent.Institutions.add(values)
      .then((result) => {
        toast.success(t("Institutions.InstitutionSavedSuccessfully"));
        history.push(`/institutions/${result}/details?setup=1`);
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error(t("Institutions.InstitutionsNameAlreadyExists"));
        else handleError(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateInstitution = (values: InstitutionDto) => {
    agent.Institutions.update(values)
      .then((result) => {
        toast.success(t("Institutions.InstitutionSavedSuccessfully"));
        history.push(`/institutions/manage`);
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error(t("Institutions.InstitutionsNameAlreadyExists"));
        else handleError(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Common.Required")),
    institutionType: Yup.number().typeError(t("Common.Required")).min(1, t("Common.Required")).required(t("Common.Required")),
    classification: Yup.number().typeError(t("Common.Required")).min(1, t("Common.Required")).required(t("Common.Required")),
    schoolStage: Yup.mixed().when("institutionType", {
      is: 1, //case school
      then: Yup.number().typeError(t("Common.Required")).min(1, t("Common.Required")).required(t("Common.Required")),
    }),
    genderType: Yup.mixed().when("institutionType", {
      is: 1, //case school
      then: Yup.number().typeError(t("Common.Required")).min(1, t("Common.Required")).required(t("Common.Required")),
    }),
    governateId: Yup.number().typeError(t("Common.Required")).min(1, t("Common.Required")).required(t("Common.Required")),
    directorateId: Yup.number().typeError(t("Common.Required")).min(1, t("Common.Required")).required(t("Common.Required")),
    address: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    schoolPrincipalName: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    ministryCoordinatorName: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    schoolStudentCount: Yup.number().typeError(t("Common.InvalidNumber")).required(t("Common.Required")),
  });

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <div>
        <Header as="h2">{t("Institutions.NewInstitution")}</Header>
        <div className="injaz-forms-cancel-btn-container">
          {!isSaving && <Button className="injaz-forms-cancel-btn" basic color="blue" content={t("Common.Cancel")} as={NavLink} to={"/institutions/manage"} />}
        </div>
        <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
          <Segment color="yellow" className="injaz-forms-segment-nopad">
            <Formik validationSchema={validationSchema} validateOnChange={true} validateOnBlur={true} enableReinitialize initialValues={institution} onSubmit={(values) => submitForm(values)}>
              {({ values, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-btn-container ui menu injaz-forms-btns-incl">
                    <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                  </div>
                  <Grid className="injaz-form-pad">
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput label={t("Institutions.Name")+" *"} placeholder={t("Institutions.Name")} name="name" />
                          <CustomSelectInput
                            label={t("Institutions.InstitutionType")+" *"}
                            options={institutionTypes}
                            name="institutionType"
                            placeholder={t("Institutions.InstitutionType")}
                          />
                          <CustomSelectInput
                            label={t("Institutions.Classification")+" *"}
                            options={filteredClassificationTypes}
                            name="classification"
                            placeholder={t("Institutions.Classification")}
                          />
                          <CustomSelectInput
                            label={t("Institutions.SchoolStage")}
                            options={schoolStages}
                            name="schoolStage"
                            placeholder={t("Institutions.SchoolStage")}
                          />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomSelectInput label={t("Institutions.Gender") +" *"} options={genderTypes} name="genderType" placeholder={t("Institutions.Gender")} />
                          <CustomSelectInput label={t("Institutions.Governate")+" *"} options={governates} name="governateId" placeholder={t("Institutions.Governate")} />
                          <CustomSelectInput
                            label={t("Institutions.Directorate")+" *"}
                            options={directorates}
                            name="directorateId"
                            placeholder={t("Institutions.Directorate")}
                          />
                          <CustomTextAreaInput label={t("Institutions.Address") +" *"} name="address" placeholder={t("Institutions.Address")} rows={5} />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput label={t("Institutions.ContactPerson")} placeholder={t("Institutions.ContactPerson")} name="contactPerson" />
                          <CustomTextInput label={t("Institutions.PhoneNumber")} placeholder={t("Institutions.PhoneNumber")} name="phoneNumber" />
                          <CustomTextInput label={t("Institutions.SchoolPrincipalName")+" *"} placeholder={t("Institutions.SchoolPrincipalName")} name="schoolPrincipalName" />
                          <CustomTextInput
                            label={t("Institutions.SchoolPrincipalPhoneNumber")}
                            placeholder={t("Institutions.SchoolPrincipalPhoneNumber")}
                            name="schoolPrincipalPhoneNumber"
                          />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("Institutions.SchoolPrincipalAssistantName")}
                            placeholder={t("Institutions.SchoolPrincipalAssistantName")}
                            name="schoolPrincipalAssistantName"
                          />
                          <CustomTextInput
                            label={t("Institutions.SchoolPrincipalAssistantPhoneNumber")}
                            placeholder={t("Institutions.SchoolPrincipalAssistantPhoneNumber")}
                            name="schoolPrincipalAssistantPhoneNumber"
                          />
                          <CustomTextInput label={t("Institutions.SchoolLeaderName")} placeholder={t("Institutions.SchoolLeaderName")} name="schoolLeaderName" />
                          <CustomTextInput
                            label={t("Institutions.SchoolLeaderPhoneNumber")}
                            placeholder={t("Institutions.SchoolLeaderPhoneNumber")}
                            name="schoolLeaderPhoneNumber"
                          />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("Institutions.MinistryCoordinatorName")+" *"}
                            placeholder={t("Institutions.MinistryCoordinatorName")}
                            name="ministryCoordinatorName"
                          />
                          <CustomTextInput
                            label={t("Institutions.MinistryCoordinatorPhoneNumber")}
                            placeholder={t("Institutions.MinistryCoordinatorPhoneNumber")}
                            name="ministryCoordinatorPhoneNumber"
                          />

                          <CustomSelectInput
                            label={t("Institutions.InstitutionStatus")}
                            options={institutionStatuses}
                            name="institutionStatus"
                            placeholder={t("Institutions.InstitutionStatus")}
                          />

                          <CustomTextInput label={t("Institutions.StudentCount")+" *"} placeholder={t("Institutions.StudentCount")} name="schoolStudentCount" />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <CustomCheckboxInput name="schoolWorksWithDoubleShift" label={t("Institutions.SchoolWorksWithDoubleShift")} type="checkbox" />
                        <CustomCheckboxInput name="hasSyrianStudentsPeriod" label={t("Institutions.HasSyrianStudentsPeriod")} type="checkbox" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Segment>
        </Segment.Group>
      </div>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
