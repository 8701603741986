import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Header, Icon, Table } from "semantic-ui-react";
import CourseMemberDto from "../../models/Courses/courseMemberDto";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { useStore } from "../../stores/store";

export interface Props {
  course: CourseSummaryDto | undefined;
  members: CourseMemberDto[];
  onDelete: (userId: number) => void;
  role: string;
}
export default function MembersList({ members, onDelete, role, course }: Props) {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  const [deleting, setDeleting] = useState<number>(0);
  useEffect(() => {
    //reset currrent active member
    setDeleting(0);
  }, [members]);
  return (
    <>
      <Table basic="very" celled className="members-selection">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Name")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {members &&
            members.length > 0 &&
            members.map((member) => (
              <Table.Row key={member?.memberId}>
                <Table.Cell collapsing>
                  {(userStore.isAdmin || userStore.isEmployee) && (
                    <Icon
                      color="blue"
                      name="trash alternate outline"
                      link={true}
                      disabled={
                        (course?.isPublished && role === "student") || member?.memberId === deleting
                      }
                      loading={member?.memberId === deleting}
                      onClick={() => {
                        setDeleting(member?.memberId);
                        onDelete(member.memberId);
                      }}
                    />
                  )}
                </Table.Cell>
                <Table.Cell>{member.memberId}</Table.Cell>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content as={NavLink} to={"/" + role + "/" + member.memberId + "/profile"}>
                      {member.memberDisplayName}
                      <Header.Subheader>{member.memberEmail}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
