import { Session } from "inspector";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Dimmer, Icon, Loader, Message, Segment, Sidebar, Table } from "semantic-ui-react";
import agent from "../../api/agent";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { SessionDto } from "../../models/sessionDto";
import { useStore } from "../../stores/store";
import AddEditSession from "./AddEditSession";

export interface Props {
  courseId: string;
  sessionsUpdated: () => void;
}

export default function CourseSessions({ courseId, sessionsUpdated }: Props) {
  const { lookupsStore, commonStore, userStore } = useStore();
  const { t } = useTranslation("common");
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [missingConfigurations, setMissingConfigurations] = useState<boolean>(false);
  const [sessions, setSessions] = useState<SessionDto[]>();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<SessionDto>();
  const [course, setCourse] = useState<CourseSummaryDto>();
  const [deleting, setDeleting] = useState<number>(0);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const loadSessions = () => {
    agent.Courses.sessions(courseId)
      .then((result) => {
        setSessions(result);
        if (result != null && result.length > 0) {
          let unconfigured = result.filter((s) => s.isConfigured === false);
          if (unconfigured != null && unconfigured.length > 0) setMissingConfigurations(true);
          else setMissingConfigurations(false);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  };

  const deleteSession = (sessionId: number) => {
    setDeleting(sessionId);
    agent.Sessions.deleteSession(sessionId)
      .then((result) => {
        loadSessions();
        sessionsUpdated();
      })
      .catch((error) => handleError(error))
      .finally(() => setDeleting(0));
  };

  useEffect(() => {
    loadSessions();

    agent.Courses.details(courseId)
      .then((result) => setCourse(result))
      .catch((error) => handleError(error));
  }, [courseId]);

  const configureSession = (session: SessionDto) => {
    if (!session.isConfigured) {
      session.sessionDate = undefined;
      session.sessionTime = undefined;
      session.description = "";
      session.name = "";
    }
    setSelectedSession(session);
    setOpenMenu(true);
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const warning = <Message className="sessions-msg" warning header={t("CourseManagement.CompleteSessionsSetupMessage")} />;

  const content = (
    <>
      <Button
        basic
        disabled={course?.isPublished}
        color="blue"
        onClick={() => {
          let newSession: SessionDto = {} as SessionDto;
          if (!sessions) return;
          newSession.name = "";
          newSession.description = "";
          newSession.duration = 0;
          newSession.id = 0;
          newSession.sessionNumber = sessions.length + 1;
          newSession.courseId = +courseId;

          setSelectedSession(newSession);
          setOpenMenu(true);
        }}
      >
        {t("CourseManagement.AddSession")}
      </Button>
      {sessions && sessions.length > 0 && (
        <>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("CourseManagement.Status")}</Table.HeaderCell>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>{t("CourseManagement.SessionName")}</Table.HeaderCell>
                <Table.HeaderCell>{t("CourseManagement.Required")}</Table.HeaderCell>
                <Table.HeaderCell>{t("CourseManagement.Duration")}</Table.HeaderCell>
                <Table.HeaderCell>{t("CourseManagement.Date")}</Table.HeaderCell>
                <Table.HeaderCell>{t("CourseManagement.Time")}</Table.HeaderCell>
                <Table.HeaderCell>{t("CourseManagement.Type")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sessions.map((session) => (
                <Table.Row key={session?.id}>
                  <Table.Cell className="session-filled">
                    <Icon color={session.isConfigured ? "olive" : "red"} name="circle"></Icon>
                    {(userStore.isAdmin || userStore.isEmployee) && (
                      <a href="#" onClick={() => configureSession(session)}>
                        {session.isConfigured ? t("CourseManagement.Edit") : t("CourseManagement.Configure")}
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell>{session?.sessionNumber}</Table.Cell>
                  <Table.Cell>{session?.name}</Table.Cell>
                  <Table.Cell>{session?.isRequired && <Icon name="check" />}</Table.Cell>
                  <Table.Cell>
                    {session?.duration} {session?.duration && session?.duration === 1 ? t("CourseManagement.Hour") : t("CourseManagement.Hours")}
                  </Table.Cell>
                  <Table.Cell>{session?.sessionDateString}</Table.Cell>
                  <Table.Cell>{lookupsStore.getCourseTimeValue(session?.sessionTime, commonStore.lang)}</Table.Cell>
                  <Table.Cell>{session?.inPerson === true ? t("Common.InPerson") : t("Common.Online")}</Table.Cell>
                  <Table.Cell>
                    {course != null && session.sessionNumber > course.minimumNumberOfSessions && (
                      <Icon
                        name="trash alternate outline"
                        link={true}
                        loading={session.id === deleting}
                        disabled={session.id === deleting}
                        onClick={() => deleteSession(session.id)}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Sidebar className="AddEditSessionSidebar" animation="overlay" direction="right" width="very wide" visible={openMenu}>
            <AddEditSession
              onClose={() => {
                setOpenMenu(false);
                sessionsUpdated();
                loadSessions();
              }}
              session={selectedSession}
              course={course}
              onSessionAdded={() => {
                loadSessions();
              }}
            />
          </Sidebar>
        </>
      )}
    </>
  );

  return (
    <>
      {missingConfigurations && warning}
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
