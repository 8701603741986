import React from "react";
import { useTranslation } from "react-i18next";
import { Segment, Header, Image, Icon } from "semantic-ui-react";

export default function InjazNews() {
  const { t } = useTranslation("common");
  return (
    <>
      <h1>{t("PublicSite.News")}</h1>
      <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">{t("PublicSite.News")}</a>
      </nav>
      <Segment className="full-injaz-news" color="blue">
      <Header as='h2'>
      INJAZ and Learning Possibilities sign an agreement to deliver an award-winning technology to the MENA region
      <Header.Subheader>
      The partnership will offer schools and governments with solutions to help drive learning into the 21st Century, enabling educators to support learners in school and at home.
        </Header.Subheader>
      </Header>
      <Image className="full-news-img" size="large" src="/img/INJAZ LP Signing Agreement-June012022_0.jpg" floated='left' />
    <p>
    Amman, Jordan, June 1st 2022. INJAZ, a leading non-profit Jordanian organization, and Learning Possibilities, the leading global education-technology provider, are delighted to announce the launch of their exclusive partnership to bring award-winning technology solutions to the MENA region.
    After many years of jointly working in Jordan, the partnership will see INJAZ offer Learning Possibilities’ ‘LP+’ platforms, together with local consultancy and support to schools and governments in the MENA region. LP+ platforms are already deployed in many countries worldwide, either directly or through programs run by Asian Development Bank, UNICEF and others. They have proven efficacy and bring solutions that drive learner impact at such challenging times.
    </p>
    <p>
    Dr. Mehool Sanghrajka, Learning Possibilities Chief Executive said, “I am delighted that Learning Possibilities and INJAZ will be partnering in the MENA region to deliver ed-tech solutions. INJAZ has a long history of delivering major educational projects in Jordan and shares both our ethos and vision. We look forward to serving schools and governments to benefit learners after a difficult two years”.
    </p>
    <p>
    Deema Bibi, INJAZ CEO said, “The partnership between INJAZ and Learning Possibilities (LP) comes at a crucial time for education and technology globally and regionally. INJAZ is excited to combine its rich experience in localized educational programs along with LP’s global virtual learning platforms into the MENA region. This synergy will be a vehicle for high-quality educational experiences that enrich the learning process for school and university students”.
    </p>
    <p>
    Sarah Chidgey, Education Sector Lead, UK Department of International Trade (DIT) said, “I am delighted that the UK’s Learning Possibilities and Jordan’s INJAZ have formed this joint venture partnership having previously worked together to deliver a World Bank funded contract. UK education technology is a global leader, an important strand of the UK Government’s International Education Strategy, and Learning Possibilities success showcases how UK innovation in education technology can positively impact millions of students worldwide. We look forward to continuing our work with Learning Possibilities to open up further opportunities globally”.
    </p>
    <p>
    Learning Possibilities has a Global Alliance with Microsoft in Education and has worked in MENA countries over many years.
    </p>
    <p>
    Mark East, Senior Education Director EMEA Region at Microsoft, said, “Microsoft is excited to see the partnership between Learning Possibilities and INJAZ as it brings to the region proven education solutions and best practice. We look forward to seeing solutions incorporating Microsoft technology, together with education consulting and local presence, support schools to recover and progress in their digital journey."
    </p>
      </Segment>
      <Segment className="full-injaz-news" color="red">
      <Header as='h2'>
      JoPACC honors INJAZ as the DFS Champion for 2022
      <Header.Subheader>
      July 31, 2022
        </Header.Subheader>
      </Header>
      <Image className="full-news-img" size="large" src="/img/News_Article1_1.png" floated='left' />
    <p>
    In the second annual cycle of the Digital Champions Program launched by the Jordan Payments and Clearing Systems Company (JoPACC), 
    JoPACC, represented by its CEO, Ms. Maha Al-Baho, hosted the team of INJAZ, represented by its CEO, Ms. Deema Bibi, 
    to recognize them as the 2022 Digital Champion.
    </p>
    <p>
    The Digital Champions Program aims to recognize and honor the individuals and institutions pioneering change across 
    the financial sector who contribute to expanding the acceptance and use of digital financial services. 
    The champions are nominated by stakeholders in the financial sector, after which an evaluation conducted 
    by JoPACC’s team determined the candidate(s) chosen for that year.
    </p>
    <p>
    INJAZ is a leader in raising financial literacy, especially in the realm of digital financial services, 
    for school students, university students, community colleges, and youth centers by conducting educational programs and courses for them. 
    INJAZ is also credited with the achievement of introducing the national financial literacy curriculum (Financial Education Program) 
    in collaboration with the Central Bank of Jordan and the Ministry of Education, which was mainstreamed in schools 
    as a subject taught to students from the 7th to the 12th grade.
    </p>
    <p>
    Ms. Maha praised the entrepreneurial role INJAZ has played in enforcing the teaching of life skills at an early age and 
    expanding the horizons of the educational process to include practical elements, which shall equip youth to lead their lives and 
    make decisions in a responsible and informed manner. Ms. Deema Bibi commended JoPACC's efforts in promoting financial awareness in 
    Jordan and also affirmed INJAZ's belief in the importance of financial awareness and education from a young age.
    </p>
    <p>
    JoPACC and INJAZ have also agreed to intensify their efforts to enhance awareness of core financial concepts by holding joint 
    training sessions and building financial awareness programs, which aim towards enhancing the levels of financial inclusion across the Kingdom.
    </p>
    <p>
    Building on its mission to inspire and prepare youth to become productive members in their society and accelerate the development of 
    the national economy, INJAZ has established itself over the past 22 years as a leading solution provider linking the public, private, 
    and civil society sectors to bridge the skills gap between the educational system and the changing needs of the labor market. 
    In 2018, INJAZ established mySTARTUP incubator that builds on INJAZ’ resources, expertise, and network to promote entrepreneurship 
    and enable startups to become a driving force toward the enhanced and equal participation and economic development of youth and women.
    </p>
    <p>
    JoPACC, on the other hand, manages several payment systems in Jordan, introduces innovative digital solutions, 
    and conducts analyses and studies in the field of financial technology and inclusion.
    </p>
    <Image className="full-news-img" size="large" src="/img/News_Article1_2.jpg" />
      </Segment>
    </>
  );
}
