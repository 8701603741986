import React from "react";
import { Container, Header, Segment, Image, Grid } from "semantic-ui-react";
import StudentCourseCertificateDto from "../../models/Courses/studentCourseCertificateDto";

interface Params {
  certificate: StudentCourseCertificateDto | undefined;
}

export default function StudentCertificate({ certificate }: Params) {
  return (
    <>
      <Container
        id={`pdf-${certificate?.studentId}`}
        textAlign="center"
        className="injaz-certificate-wrapper"
      >
        <div className="cert-bg">
          <Image src="/img/Certificate_bg.png" size="small" />
        </div>
        <Segment className="injaz-certificate-container">
          <Grid>
            <Grid.Column width={13}>
              <div className="injaz-certificate">
                <div className="cert-logos">
                  <Image src="/img/injaz-blue.png" size="small" />
                </div>
                <Header as="h1">{certificate?.courseName}</Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={12}>
                      <div className="injaz-cert">
                        <p className="cert-lbl">
                          <span>:</span>تَشهد <strong>مؤسَّسة إنجاز</strong>أنّ الطالب/الطالبة
                        </p>
                        <p className="cert-input">
                          {certificate?.studentFirstName} {certificate?.studentLastName}
                        </p>
                        <p className="cert-lbl">من مدرسة</p>
                        <p className="cert-input">{certificate?.schoolName}</p>
                        <p className="cert-lbl">قَد أتمّ/ت بَرنامج</p>
                        <p className="cert-input">{certificate?.courseName}</p>
                        <p className="cert-lbl">السّنة الدراسيّة</p>
                        <p className="cert-input">{certificate?.academicYearName}</p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="cert-footer">
                    <Grid.Column width={4}>
                      <Image src="/img/injaz-stamp.png" size="small" />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <p className="cert-lbl">المُعلّم/المُعلّمة</p>
                      <p className="cert-input"></p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <p className="cert-lbl">المتطوّع</p>
                      <p className="cert-input"></p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Grid.Column>
            <Grid.Column width={3}></Grid.Column>
          </Grid>
        </Segment>
      </Container>
    </>
  );
}
