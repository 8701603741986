import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Grid, Button, Dimmer, Loader, Segment, List, Icon } from "semantic-ui-react";
import agent from "../../../api/agent";
import TeacherDetailsDto from "../../../models/Users/teacherDetailsDto";
import { useStore } from "../../../stores/store";
import TeacherCourses from "./TeacherCourses";
import { toast } from "react-toastify";

interface TeacherProfileParams {
  userId: string;
}

export default function TeacherProfile() {
  let { userId } = useParams<TeacherProfileParams>();
  const { lookupsStore, commonStore, userStore } = useStore();
  const [user, setUser] = useState<TeacherDetailsDto>();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const { t } = useTranslation("common");

  const handleError = () => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Profiles.teacher(userId)
      .then((result) => {
        setUser(result);
      })
      .catch((error) => handleError())
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          {userStore.user?.id !== +userId && <span>{t("UserManagement.TeacherProfile")}</span>}
          {userStore.user?.id === +userId && <span>{t("UserManagement.MyProfile")}</span>}
          </a>
      </nav>
      <h2>{t("UserManagement.TeacherProfile")}</h2>
      {(userStore.isAdmin || userStore.isEmployee) && (
        <div className="ui menu injaz-users-forms-btns">
          <div className="item">
            <Button basic color="blue" as={NavLink} to="/manageusers/teacher">
              {t("Common.Back")}
            </Button>
          </div>
          <div className="item">
            {(userStore.isAdmin || userStore.isEmployee) && (
              <Button
                content={t("Common.Edit")}
                basic
                color="blue"
                as={NavLink}
                to={`/teacher/${userId}/edit`}
              />
            )}
          </div>
        </div>
      )}
      <Segment className="injaz-forms-segment">
        <Grid columns={2} className="profile-info" divided stackable>
          <Grid.Row className="">
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.FirstName")}: </List.Header>
                  {user?.firstName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.MiddleName")}:</List.Header>
                  {user?.middleName == null ? "--" : user?.middleName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.LastName")}: </List.Header> {user?.lastName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.EmailAddress")}: </List.Header>
                  {user?.email}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Teachers.SchoolName")}: </List.Header>
                  {user?.schoolName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Gender")}: </List.Header>
                  {lookupsStore.getGender(user?.gender, commonStore.lang)}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <List>
                <List.Header>{t("UserManagement.PhoneNumber")}:</List.Header>
                {user?.phoneNumber == null ? "--" : user?.phoneNumber}
                <List.Item>
                  <List.Header>{t("UserManagement.Teachers.PreviousInjazTeacher")}: </List.Header>
                  {user && user.workedOnPreviousInjazProjects == null && "--"}
                  {user &&
                    user.workedOnPreviousInjazProjects != null &&
                    user.workedOnPreviousInjazProjects === true &&
                    t("Common.Yes")}
                  {user &&
                    user.workedOnPreviousInjazProjects != null &&
                    user.workedOnPreviousInjazProjects === false &&
                    t("Common.No")}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Teachers.RemoteTeachingExperience")}: </List.Header>
                  {user && user.haveComputerOnlineExperience == null && "--"}
                  {user &&
                    user.haveComputerOnlineExperience != null &&
                    user.haveComputerOnlineExperience === true &&
                    t("Common.Yes")}
                  {user &&
                    user.haveComputerOnlineExperience != null &&
                    user.haveComputerOnlineExperience === false &&
                    t("Common.No")}
                </List.Item>
                <List.Item>
                  <List.Header>
                    {t("UserManagement.Teachers.GoalProgramPreviousParticipation")}:
                  </List.Header>
                  {user && user.previouslyParticipatedInGoalProgram == null && "--"}
                  {user &&
                    user.previouslyParticipatedInGoalProgram != null &&
                    user.previouslyParticipatedInGoalProgram === true &&
                    t("Common.Yes")}
                  {user &&
                    user.previouslyParticipatedInGoalProgram != null &&
                    user.previouslyParticipatedInGoalProgram === false &&
                    t("Common.No")}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {userStore.user?.id != +userId && <TeacherCourses teacherId={+userId} />}
      {/*<Header as="h2">Completed Courses</Header>
      <Grid>
        <Grid.Row columns={5} className="completed-courses">
          <Grid.Column className="completed-col">
            <Card>
              <Card.Content>
                <Card.Header>Course Name</Card.Header>
                <Image floated="left" size="medium" src="/img/program-1.png" />
                <Card.Description>
                  <strong>Supported By: </strong> Artlink_mashrek
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                More <Icon name="arrow right" floated="left" />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
                  </Grid>*/}
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}

      {/*<Header as='h2'>My Certifications</Header>
    <Segment>
    <Item>
      <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' />
      <Item.Content>
        <Item.Header as='a'>Header</Item.Header>
        <Item.Meta>Description</Item.Meta>
        <Item.Description>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.
        </Item.Description>
        <Item.Extra>Additional Details</Item.Extra>
      </Item.Content>
    </Item>
    </Segment>*/}
    </>
  );
}
