import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Header,
  Loader,
  Search,
  Segment,
  Table,
} from "semantic-ui-react";
import agent from "../../api/agent";
import CourseMemberDto from "../../models/Courses/courseMemberDto";
import { UserSummary } from "../../models/Users/userSummary";
import { toast } from "react-toastify";

export interface Props {
  onClose: (role: string) => void;
  role: string;
  courseId: string;
  reload: Date;
  excludedMembers: CourseMemberDto[];
}

export default function AddCourseMember({ onClose, role, courseId, excludedMembers, reload }: Props) {
  const { t } = useTranslation("common");
  const [isSaving, setIsSaving] = useState(false);
  const [inProgess, setInProgress] = useState(false);
  const [users, setUsers] = useState<UserSummary[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserSummary[]>([]);
  const [query, setQuery] = useState<string>("");

  const selectUser = (user: UserSummary) => {
    if (selectedUsers.filter((u) => u.id === user.id).length > 0) {
      var updatedList = selectedUsers.filter((u) => u.id !== user.id);
      setSelectedUsers(updatedList);
    } else {
      var list = [...selectedUsers, user];
      setSelectedUsers(list);
    }
  };

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const search = (e: any) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    setSelectedUsers([]);
    if (role === "") return;
    setInProgress(true);
    agent.Users.list(role, query, 1, 100000)
      .then((result: any) => {
        let filteredUsers: UserSummary[] = [];
        if (result != null && result !== "") {
          filteredUsers = result.filter(
            (u: UserSummary) => !excludedMembers.find(({ memberId }) => u.id === memberId)
          );
        }
        setUsers(filteredUsers);
        setInProgress(false);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setInProgress(false);
      });
  }, [role, reload, query]);

  const showSuccessMessage = () => {
    toast.success(t("CourseManagement.CourseMemberAddedSuccessfully"));
  };

  const addUsers = () => {
    setIsSaving(true);
    if (role === "student") {
      agent.Courses.addStudents(courseId, selectedUsers)
        .then((result) => showSuccessMessage())
        .catch((error) => handleError(error))
        .finally(() => {
          setIsSaving(false);
          onClose("student");
        });
    } else if (role === "teacher") {
      agent.Courses.addTeachers(courseId, selectedUsers)
        .then((result) => showSuccessMessage())
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          setIsSaving(false);
          onClose("teacher");
        });
    } else if (role === "volunteer") {
      agent.Courses.addVolunteers(courseId, selectedUsers)
        .then((result) => showSuccessMessage())
        .catch((error) => handleError(error))
        .finally(() => {
          setIsSaving(false);
          onClose("volunteer");
        });
    }
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const usersList = (
    <Table basic="very" celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.HeaderCell>{t("UserManagement.Name")}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {users &&
          users.length > 0 &&
          users.map((user) => (
            <Table.Row key={user?.id}>
              <Table.Cell collapsing>
                <Checkbox
                  onChange={() => selectUser(user)}
                  checked={selectedUsers.filter((u) => u.id === user.id).length > 0}
                />
              </Table.Cell>
              <Table.Cell>{user.id}</Table.Cell>
              <Table.Cell>
                <Header as="h4">
                  <Header.Content>
                    {user.displayName}
                    <Header.Subheader>{user.email}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );

  return (
    <>
      <Container>
        <Header as="h2">{t("CourseManagement.AddMembers")}</Header>
        <Search
          value={query}
          onSearchChange={search}
          className="injaz-home-search"
          placeholder={t("Common.Search")}
          floated="right"
          position="right"
        />
        {inProgess && loader}
        {!inProgess && usersList}
        <div className="members-sidebar-container"></div>
        <div className="sidebar-btns">
          <Button
            primary
            loading={isSaving}
            onClick={() => addUsers()}
            disabled={selectedUsers == null || selectedUsers.length === 0}
          >
            {t("Common.Add")}{" "}
            {selectedUsers &&
              selectedUsers.length > 0 &&
              selectedUsers.length.toString() + " " + t("Common.Users")}
          </Button>
          {!isSaving && (
            <Button secondary onClick={() => onClose("none")}>
              {t("Common.Cancel")}
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}
