import { useField } from "formik";
import React from "react";
import { Form, Label, Input } from "semantic-ui-react";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  autoComplete?: string;
  disabled?: boolean;
  valuechanged?: (event: any) => void;
}

export default function CustomTextInput(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <Form.Field error={meta.touched && !!meta.error}>
        <Input
          {...field}
          {...props}
          onChange={(event: any) => {
            if (props.valuechanged) props.valuechanged(event);
            helpers.setValue(event.target.value);
          }}
        ></Input>
        {meta.touched && meta.error ? (
          <Label basic color="red">
            {meta.error}
          </Label>
        ) : null}
      </Form.Field>
    </>
  );
}
