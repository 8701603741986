import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header, Segment, Grid, Image, Tab, Pagination, Message } from "semantic-ui-react";
import agent from "../../../api/agent";
import CourseSummaryDto from "../../../models/Courses/courseSummaryDto";
import { useStore } from "../../../stores/store";
import { toast } from "react-toastify";

export interface Props {
  studentId: number;
}

export default function StudentCourses({ studentId }: Props) {
  const pageSize = 5;
  const { userStore } = useStore();
  const { t } = useTranslation("common");
  const [courses, setCourses] = useState<CourseSummaryDto[]>([]);
  const [coursesPage, setCoursesPage] = useState<CourseSummaryDto[]>([]);
  const [currentCoursesPageIndex, setCurrentCoursesPageIndex] = useState<number>(1);
  const [completedCourses, setCompletedCourses] = useState<CourseSummaryDto[]>([]);
  const [completedCoursesPageIndex, setCompletedCoursesPageIndex] = useState<number>(1);
  const [completedCoursesPage, setCompletedCoursesPage] = useState<CourseSummaryDto[]>([]);

  const handleError = () => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Courses.studentCourses(studentId)
      .then((result) => {
        setCourses(result);
        if (result && result.length <= pageSize) setCoursesPage(courses);
        else {
          let page = result.slice(0, pageSize - 1);
          setCoursesPage(page);
        }
      })
      .catch(() => handleError());

    agent.Courses.studentCompletedCourses(studentId)
      .then((result) => {
        setCompletedCourses(result);
        if (result && result.length <= pageSize) setCompletedCoursesPage(courses);
        else {
          let page = result.slice(0, pageSize - 1);
          setCompletedCoursesPage(page);
        }
      })
      .catch(() => handleError());
  }, [studentId]);

  const onCurrentCoursesPageChanged = (pageIndex: number) => {
    setCurrentCoursesPageIndex(pageIndex);
    let skip = (pageIndex - 1) * pageSize;
    let page = courses.slice(skip, skip + pageSize - 1);
    setCoursesPage(page);
  };

  const onCompletedCoursesPageChanged = (pageIndex: number) => {
    setCompletedCoursesPageIndex(pageIndex);
    let skip = (pageIndex - 1) * pageSize;
    let page = completedCourses.slice(skip, skip + pageSize - 1);
    setCompletedCoursesPage(page);
  };

  const currentCourses = (
    <>
      {(coursesPage == null || coursesPage.length === 0) && (
        <Message className="empty-list-msg" header={t("CourseManagement.NoCourses")} />
      )}

      {coursesPage &&
        coursesPage.length > 0 &&
        coursesPage.map((course) => (
          <Segment key={course.id} color="yellow">
            <Grid className="injaz-mycourses" divided="vertically" padded>
              <Grid.Row>
                {/*<Grid.Column width={3}>
                  <Image
                    size="small"
                    src={course.imageUrl == null ? "/img/program-1.png" : course.imageUrl}
                  />
                </Grid.Column>*/}
                <Grid.Column width={13}>
                  <Header as="h3">
                    <a href={course.edxAppCourseUrl}>{course.name}</a>
                  </Header>
                  <div className="injaz-lbl injaz-course-startenddate">
                    <span className="injaz-lbl injaz-course-start">{t("Common.From")} </span>
                    <span>{course.startDate}</span>
                    <span className="injaz-lbl injaz-course-end">{t("Common.To")} </span>
                    <span>{course.endDate}</span>
                  </div>
                  {/*<div className="injaz-lbl">
                    <span className="injaz-lbl">{t("CourseManagement.NumberOfSessions")}: </span>{" "}
                    {course.numberOfSessions}
                  </div>*/}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
      {courses && courses.length > 0 && coursesPage && coursesPage.length > 0 && (
        <Pagination
          boundaryRange={0}
          defaultActivePage={currentCoursesPageIndex}
          totalPages={Math.ceil(courses.length / pageSize)}
          onPageChange={(e, d) => onCurrentCoursesPageChanged(+d.activePage!)}
        />
      )}
    </>
  );

  const completedCoursesList = (
    <>
      {(completedCourses == null || completedCourses.length === 0) && (
        <Message className="empty-list-msg" header={t("CourseManagement.NoCourses")} />
      )}

      {completedCourses &&
        completedCourses.length > 0 &&
        completedCourses.map((course) => (
          <Segment key={course.id} color="yellow">
            <Grid className="injaz-mycourses" divided="vertically" padded>
              <Grid.Row>
                {/*<Grid.Column width={3}>
                  <Image
                    size="small"
                    src={course.imageUrl == null ? "/img/program-1.png" : course.imageUrl}
                  />
                </Grid.Column>*/}
                <Grid.Column width={13}>
                  <Header as="h3">
                    <a href={course.edxAppCourseUrl}>{course.name}</a>
                  </Header>
                  <div className="injaz-lbl injaz-course-startenddate">
                    <span className="injaz-lbl injaz-course-start">{t("Common.From")} </span>
                    <span>{course.startDate}</span>
                    <span className="injaz-lbl injaz-course-end">{t("Common.To")} </span>
                    <span>{course.endDate}</span>
                  </div>
                  {/*<div className="injaz-lbl">
                    <span className="injaz-lbl">{t("CourseManagement.NumberOfSessions")}: </span>{" "}
                    {course.numberOfSessions}
                  </div>*/}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
      {completedCourses && (
        <Pagination
          boundaryRange={0}
          defaultActivePage={completedCoursesPageIndex}
          totalPages={Math.ceil(completedCourses.length / pageSize)}
          onPageChange={(e, d) => onCompletedCoursesPageChanged(+d.activePage!)}
        />
      )}
    </>
  );

  var tabs = [
    {
      menuItem: t("CourseManagement.CurrentCourses"),
      pane: {
        key: "CurrentCourses",
        content: currentCourses,
      },
    },
    {
      menuItem: t("CourseManagement.CompletedCourses"),
      pane: {
        key: "CompletedCourses",
        content: completedCoursesList,
      },
    },
  ];

  return (
    <>
      {userStore.user?.id !== studentId && (
        <Header as="h2">{t("CourseManagement.StudentCourses")}</Header>
      )}
      {userStore.user?.id === studentId && <Header as="h2">{t("UserManagement.MyCourses")}</Header>}
      <Tab panes={tabs} renderActiveOnly={false} />
    </>
  );
}
