import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Header, Icon, Image, Message } from "semantic-ui-react";

export interface Props{
  fileUploaded:(file:File)=>void;
}

export default function ImageUploadDropZone({fileUploaded}:Props) {
  const { t } = useTranslation("common");
  const dzStyles = {
    border: "dashed 3px #eee",
    borderColoe: "#eee",
    borderRadius: "5px",
    paddingTop: "30px",
    textAlign: "center" as "center",
    height: 300,
  };
  const dzActive = {
    borderColoe: "green",
  };

  const [files, setFiles] = useState<any>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      if(acceptedFiles && acceptedFiles.length > 0)
        fileUploaded(acceptedFiles[0]);
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop , accept: {
      'image/jpeg': [],
      'image/png': []
    }});

  return (
    <>
      <div className="injaz-user-dragdrop" {...getRootProps()} style={isDragActive ? { ...dzStyles, ...dzActive } : { ...dzStyles }}>
        <input {...getInputProps()} />
        <Icon name="upload" size="huge" />
        <Header content={t("ProgramManagement.DropMessage")} />
      </div>
      <Message>{t("ProgramManagement.AllowedImageTypesMessage")}</Message>
      <div className="injaz-user-dragdrop">{files && files.length > 0 && <Image src={files[0].preview} />}</div>
    </>
  );
}
