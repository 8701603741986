import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Icon, Loader, Message, Segment, Table } from "semantic-ui-react";
import agent from "../../api/agent";
import AttendanceLogDto from "../../models/attendanceLogDto";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";

export interface Props {
  course: CourseSummaryDto | undefined;
  sessionId: number;
  takeAttendance: () => void;
}

export default function ViewAttendance({ course, sessionId, takeAttendance }: Props) {
  const { t } = useTranslation("common");
  const [inProgress, setInProgress] = useState<boolean>(true);
  const handleError = (error: any) => console.log(error);
  const [logs, setLogs] = useState<AttendanceLogDto[]>([]);

  useEffect(() => {
    setInProgress(true);
    agent.Sessions.viewAttendance(sessionId)
      .then((result) => {
        setLogs(result);
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [sessionId]);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <Button
        className="attendance_btn"
        basic
        color="blue"
        disabled={course?.isPublished}
        content={t("CourseManagement.TakeAttendance")}
        onClick={() => takeAttendance()}
      />

      {logs && logs.length > 0 && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("UserManagement.FirstName")}</Table.HeaderCell>
              <Table.HeaderCell>{t("UserManagement.LastName")}</Table.HeaderCell>
              <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
              <Table.HeaderCell>{`${t("CourseManagement.Present")} (${
                logs == null || logs.length === 0 ? "-" : logs.filter((l) => l.status === true).length
              })`}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {logs.map((log) => (
              <Table.Row key={log.studentId}>
                <Table.Cell>{log.studentFirstName}</Table.Cell>
                <Table.Cell>{log.studentLastName}</Table.Cell>
                <Table.Cell>{log.studentEmail}</Table.Cell>
                <Table.Cell>
                  {log.status === true && <Icon color="olive" name="check"></Icon>}
                  {log.status === false && <Icon color="red" name="close"></Icon>}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {(!logs || logs.length === 0) && (
        <Message
          className="attendance-msg"
          header={t("CourseManagement.NoAttendanceRecordedForSessionMessage")}
        />
      )}
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
