import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Checkbox, Dimmer, Loader, Message, Pagination, Segment, Table } from "semantic-ui-react";
import { UserSummary } from "../../models/Users/userSummary";
import { use } from "i18next";

interface UsersListProps {
  users: UserSummary[] | undefined;
  userType: string;
  loadingInProgress: boolean;
  currentPage: number | string | undefined;
  pageSize: number;
  totalUserCount: number;
  blockActions: boolean;
  onPageChanged: (index: number | string | undefined) => void;
  onItemSelected: (user: UserSummary | null) => void;
}

export default function UsersList({
  users,
  userType,
  loadingInProgress,
  currentPage,
  pageSize,
  totalUserCount,
  onPageChanged,
  blockActions,
  onItemSelected,
}: UsersListProps) {
  const { t } = useTranslation("common");
  const [selectedUser, setSelectedUser] = useState<UserSummary | null>(null);

  const selectUser = (user: UserSummary) => {
    if (selectedUser && selectedUser?.id === user.id) {
      setSelectedUser(null);
      onItemSelected(null);
    } else {
      setSelectedUser(user);
      onItemSelected(user);
    }
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const emptyList = <Message className="empty-list-msg" header={t("UserManagement.NoUsersAddedYet")} />;

  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalUserCount / pageSize)}
      onPageChange={(e, d) => onPageChanged(d.activePage)}
    />
  );

  const list = (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "2%" }}></Table.HeaderCell>
            <Table.HeaderCell style={{ width: "2%" }}>#</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.LastName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.FirstName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.MiddleName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users &&
            users.map((user) => (
              <Table.Row key={user.id}>
                <Table.Cell>
                  <Checkbox
                    checked={selectedUser != null && selectedUser.id === user.id}
                    onChange={() => {
                      selectUser(user);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{user.id}</Table.Cell>
                <Table.Cell>
                  <NavLink to={"/" + user.userRole + "/" + user.id + "/profile"}>{user.lastName}</NavLink>
                </Table.Cell>
                <Table.Cell>{user.firstName}</Table.Cell>
                <Table.Cell>{user.middleName}</Table.Cell>
                <Table.Cell className="injaz-user-email">{user.email}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalUserCount > 10 && gridPaging}
    </>
  );

  return (
    <>
      <Segment className="injaz-forms-segment">
        {loadingInProgress && loader}
        {!loadingInProgress && users != null && users.length > 0 && list}
        {!loadingInProgress && (users == null || users.length === 0) && emptyList}
      </Segment>
    </>
  );
}
