import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Dimmer,
  Loader,
  Segment,
  List,
  Input,
  Icon,
  Item,
  Header,
  Card,
  Image,
} from "semantic-ui-react";
import agent from "../../../api/agent";

import { StudentDetailsDto } from "../../../models/Users/Students/studentDetailsDto";
import { useStore } from "../../../stores/store";
import StudentCourses from "./StudentCourses";

interface StudentProfileParams {
  userId: string;
}

export default function StudentProfile() {
  let { userId } = useParams<StudentProfileParams>();
  const { lookupsStore, commonStore, userStore } = useStore();
  const [user, setUser] = useState<StudentDetailsDto>();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const { t } = useTranslation("common");

  useEffect(() => {
    agent.Profiles.student(userId)
      .then((result) => {
        setUser(result);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <h2>{t("UserManagement.Students.StudentProfile")}</h2>

      <div className="ui menu injaz-users-forms-btns">
        <div className="item">
          {userStore.isLoggedIn && userStore.isAdmin && (
            <Button basic color="blue" as={NavLink} to="/manageusers/student">
              {t("Common.Back")}
            </Button>
          )}
        </div>
        <div className="item">
          {(userStore.isAdmin || userStore.isEmployee) && (
            <Button
              content={t("Common.Edit")}
              basic
              color="blue"
              as={NavLink}
              to={`/student/${userId}/edit`}
            />
          )}
        </div>
      </div>
      <Segment className="injaz-forms-segment">
        <Grid columns={3} className="profile-info" divided stackable>
          <Grid.Row className="">
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.FirstName")}: </List.Header>
                  {user?.firstName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.MiddleName")}: </List.Header>
                  {user?.middleName == null ? "--" : user?.middleName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.LastName")}: </List.Header>
                  {user?.lastName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.EmailAddress")}: </List.Header>
                  {user?.email == null ? "--" : user?.email}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.PhoneNumber")}: </List.Header>
                  {user?.phoneNumber == null ? "--" : user?.phoneNumber}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.InstitutionName")}: </List.Header>
                  {user?.institutionName == null ? "--" : user?.institutionName}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.Status")}: </List.Header>
                  {user?.status === true ? t("UserManagement.Active") : t("UserManagement.InActive")}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Nationality")}: </List.Header>
                  {user?.nationality == null ? "--" : user?.nationality}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.NationalID")}: </List.Header>
                  {user?.nationalId == null ? "--" : user?.nationalId}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.Address")}: </List.Header>
                  {user?.address == null ? "--" : user?.address}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.FullArabicName")}: </List.Header>
                  {user?.fullArabicName == null ? "--" : user?.fullArabicName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Gender")}: </List.Header>
                  {lookupsStore.getGender(user?.gender, commonStore.lang)}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.Class")}: </List.Header>
                  {lookupsStore.getClassValue(user?.classId, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.Section")}: </List.Header>
                  {lookupsStore.getSectionValue(user?.sectionId, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.Birthdate")}: </List.Header>
                  {user?.birthdate == null ? "--" : user?.birthdate}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Students.PreviousInjazCompetition")}: </List.Header>
                  {user && user.participatedOnPreviousInjazPrograms == null && "--"}
                  {user &&
                    user.participatedOnPreviousInjazPrograms != null &&
                    user.participatedOnPreviousInjazPrograms === true &&
                    t("Common.Yes")}
                  {user &&
                    user.participatedOnPreviousInjazPrograms != null &&
                    user.participatedOnPreviousInjazPrograms === false &&
                    t("Common.No")}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {userStore.user?.id != +userId && <StudentCourses studentId={+userId} />}

      {/*<div className="profile-certificates">
        <Header as="h2">My Certifications</Header>
        <Segment>
          <Item>
            <Item.Image size="tiny" src="https://react.semantic-ui.com/images/wireframe/image.png" />
            <Item.Content>
              <Item.Header as="a">Header</Item.Header>
              <Item.Meta>Description</Item.Meta>
              <Item.Description>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet.
              </Item.Description>
              <Item.Extra>Additional Details</Item.Extra>
            </Item.Content>
          </Item>
        </Segment>
        <Segment>
          <Item>
            <Item.Image size="tiny" src="https://react.semantic-ui.com/images/wireframe/image.png" />
            <Item.Content>
              <Item.Header as="a">Header</Item.Header>
              <Item.Meta>Description</Item.Meta>
              <Item.Description>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet.
              </Item.Description>
              <Item.Extra>Additional Details</Item.Extra>
            </Item.Content>
          </Item>
        </Segment>
      </div>*/}
      {/*<Header as="h2">Completed Courses</Header>
      <Grid>
        <Grid.Row columns={5} className="completed-courses">
          <Grid.Column className="completed-col">
            <Card>
              <Card.Content>
                <Card.Header>Course Name</Card.Header>
                <Image floated="left" size="medium" src="/img/program-1.png" />
                <Card.Description>
                  <strong>Supported By: </strong> Artlink_mashrek
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                More <Icon name="arrow right" floated="left" />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
    </Grid>*/}
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
