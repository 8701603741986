import { Button, Checkbox, Dimmer, Input, Label, Loader, Modal, Segment, Table } from "semantic-ui-react";
import { LookupDto } from "../../models/lookupDto";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface Props {
  items: LookupDto[] | null;
  onItemSelected: (item: LookupDto | null) => void;
  onItemAdded: (item: LookupDto) => void;
  onItemDeleted: (item: LookupDto) => void;
  onItemUpdated: (item: LookupDto) => void;
  loading: boolean;
}

export default function SettingsItemList({ items, onItemSelected, onItemAdded, onItemDeleted, onItemUpdated, loading }: Props) {
  const { t } = useTranslation("common");
  const [selectedItem, setSelectedItem] = useState<LookupDto | null>();
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const[required,setRequired]=useState<boolean>(false);
  const [text, setText] = useState<string>("");

  const openAddPopup = () => {
    setText("");
    setInEditMode(false);
    setSelectedItem(null);
    setShowEditPopup(true);
  };

  const openEditPopup = () => {
    if (selectedItem == null) return;
    setText(selectedItem.text);
    setInEditMode(true);
    setShowEditPopup(true);
  };

  const selectItem = (item: LookupDto) => {
    if (selectedItem != null && selectedItem.value === item.value) {
      setSelectedItem(null);
      onItemSelected(null);
    } else {
      setSelectedItem(item);
      onItemSelected(item);
    }
  };

  const save = () => {
    if(text ==  null || text.trim()==="")
      {
        setRequired(true);
        return;
      }else
      setRequired(false);
    if (inEditMode === true && selectedItem) {
     
      
      let obj = { ...selectedItem };
      obj.text = text;
      onItemUpdated(obj);
    } else {
      let obj: LookupDto = { key: 0, text: text, value: 0 };
      onItemAdded(obj);
    }
    setShowEditPopup(false);
  };

  const deleteItem = () => {
    if (selectedItem == null) return;
    onItemDeleted(selectedItem);
    setShowDeletePopup(false);
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const popup = (
    <Modal className="t4-popup" open={true}>
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Input type="text" label={t("Common.ItemName")+" *"} value={text} onChange={(e) => setText(e.target.value)} />
          {required &&  <Label basic color="red">
            {t("Common.Required")}
          </Label>}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content={t("Common.Confirm")} onClick={() => save()} />
        <Button content={t("Common.Cancel")} onClick={() => setShowEditPopup(false)} />
      </Modal.Actions>
    </Modal>
  );

  const confirmDeletePopup = (
    <Modal className="t4-popup" open={true}>
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <Modal.Description>{t("Common.ConfirmDeleteSelectedItem")}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content={t("Common.Confirm")} onClick={() => deleteItem()} />
        <Button content={t("Common.Cancel")} onClick={() => setShowDeletePopup(false)} />
      </Modal.Actions>
    </Modal>
  );

  const content = (
    <>
      <div className="ui menu injaz-forms-btns">
        <div className="item flt-left">
          <Button basic color="blue" content={t("Common.Add")} onClick={() => openAddPopup()} />
          </div>
          <div className="item flt-left">
          <Button basic color="blue" content={t("Common.Edit")} onClick={() => openEditPopup()} disabled={!selectedItem || selectedItem == null} />
          </div>
          <div className="item flt-left">
          <Button basic color="blue" content={t("Common.Delete")} onClick={() => setShowDeletePopup(true)} disabled={!selectedItem || selectedItem == null} />
        </div>
      </div>
      <Segment className="injaz-forms-segment">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "2%" }}></Table.HeaderCell>
            <Table.HeaderCell style={{ width: "2%" }}>#</Table.HeaderCell>
            <Table.HeaderCell>{t("Common.ItemName")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items &&
            items.map((item) => (
              <Table.Row key={item.value}>
                <Table.Cell>
                  <Checkbox
                    onChange={() => {
                      selectItem(item);
                    }}
                    checked={selectedItem != null && selectedItem.value === item.value}
                  />
                </Table.Cell>
                <Table.Cell>{item.value}</Table.Cell>
                <Table.Cell>{item.text}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      </Segment>
    </>
  );
  return (
    <>
      {!loading && showDeletePopup && confirmDeletePopup}
      {!loading && showEditPopup && popup}
      {loading && loader}
      {!loading && content}
    </>
  );
}
