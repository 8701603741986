import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tab, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import InstitutionDto from "../../models/Institutions/institutionDto";
import InstitutionInfo from "./InstitutionInfo";
import InstitutionStudents from "./InstitutionStudents";

interface Params {
  institutionId: string;
}

export default function ViewInstitutionDetails() {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  const { search } = useLocation();
  let { institutionId } = useParams<Params>();
  const [institution, setInstitution] = useState<InstitutionDto>();
  const inSetupMode = () => {
    return search != null && search.includes("setup");
  };

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Institutions.details(institutionId)
      .then((result) => {
        setInstitution(result);
      })
      .catch((error) => handleError(error));
  }, [institutionId]);

  const infoTab = {
    menuItem: t("Institutions.InstitutionInfo"),
    pane: {
      key: "InstitutionInfo",
      content: <InstitutionInfo institution={institution} />,
    },
  };

  const studentsTab = {
    menuItem: t("Institutions.Students"),
    pane: {
      key: "InstitutionStudents",
      content: <InstitutionStudents institution={institution} />,
    },
  };

  let panes: any[] = [];
  if (userStore.isAdmin || userStore.isEmployee) panes = [infoTab, studentsTab];

  return (
    <>
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          <span>{t("Institutions.InstitutionDetails")}</span>
        </a>
      </nav>
      <h1>{t("Institutions.InstitutionDetails")}</h1>
      <Tab panes={panes} renderActiveOnly={false} defaultActiveIndex={inSetupMode() === true ? 1 : 0} />
    </>
  );
}
