import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Button, Icon, Input } from "semantic-ui-react";
import agent from "../../api/agent";
import { UserSummary } from "../../models/Users/userSummary";
import { useStore } from "../../stores/store";
import UsersList from "./UsersList";
import { toast } from "react-toastify";
import DeleteUserConfirmation from "./DeleteUserConfirmation";

interface ManageUsersParams {
  userType: string;
}

export default function ManageUsers() {
  let { userType } = useParams<ManageUsersParams>();
  const { userStore } = useStore();
  const { t } = useTranslation("common");
  const [users, setUsers] = useState<UserSummary[]>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [totalUserCount, setTotalUserCount] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number | string | undefined>(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState<UserSummary | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const loadUsers = () => {
    setInProgress(true);
    agent.Users.list(userType, filterQuery, currentPageIndex, pageSize)
      .then((result) => {
        setUsers(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setInProgress(false));
  };

  useEffect(() => {
    setInProgress(true);
    agent.Users.count(userType, filterQuery)
      .then((result) => {
        setTotalUserCount(result);
        loadUsers();
      })
      .catch((error) => {
        handleError(error);
      });
  }, [userType, currentPageIndex, filterQuery]);

  const searchUsers = () => {
    setCurrentPageIndex(1);
    setFilterQuery(searchText);
  };

  return (
    <>
      {showConfirmDelete && userType && selectedUser && (
        <DeleteUserConfirmation
          user={selectedUser}
          userType={userType}
          onClose={(refresh) => {
            setShowConfirmDelete(false);
            if (refresh) loadUsers();
          }}
        />
      )}
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          {userType === "admin" && <span>{t("UserManagement.AdministratorsTitle")}</span>}
          {userType === "teacher" && <span>{t("UserManagement.TeachersTitle")}</span>}
          {userType === "student" && <span>{t("UserManagement.StudentsTitle")}</span>}
          {userType === "volunteer" && <span>{t("UserManagement.VolunteersTitle")}</span>}
          {userType === "employee" && <span>{t("UserManagement.EmployeesTitle")}</span>}
          {userType === "coursemanager" && <span>{t("UserManagement.CourseManagersTitle")}</span>}
        </a>
      </nav>
      {userType === "admin" && <h1>{t("UserManagement.AdministratorsTitle")}</h1>}
      {userType === "teacher" && <h1>{t("UserManagement.TeachersTitle")}</h1>}
      {userType === "student" && <h1>{t("UserManagement.StudentsTitle")}</h1>}
      {userType === "volunteer" && <h1>{t("UserManagement.VolunteersTitle")}</h1>}
      {userType === "employee" && <h1>{t("UserManagement.EmployeesTitle")}</h1>}
      {userType === "coursemanager" && <h1>{t("UserManagement.CourseManagersTitle")}</h1>}

      <div className="ui menu injaz-forms-btns">
        {(userStore.isAdmin || (userStore.isEmployee && userType !== "employee")) && (
          <>
            <div className="item flt-left">
              <Button basic color="blue" content={t("UserManagement.NewUser")} as={NavLink} to={`/${userType}/add`} />
            </div>
            <div className="item flt-left">
              <Button content={t("Common.Edit")} basic disabled={selectedUser == null} as={NavLink} to={`/${userType}/${selectedUser?.id}/edit`} />
            </div>
            <div className="item flt-left">
              <Button content={t("UserManagement.DeleteUser")} basic disabled={selectedUser == null} onClick={() => setShowConfirmDelete(true)} />
            </div>
          </>
        )}

        <div className="right menu flt-right">
          <div className="item injaz-srch">
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              autoComplete="new-password"
              placeholder={t("Common.Search") + " ..."}
              icon={
                <Icon
                  name="delete"
                  link
                  onClick={() => {
                    setSearchText("");
                    setCurrentPageIndex(1);
                    setFilterQuery("");
                  }}
                />
              }
            />
            <Button
              onClick={() => {
                setCurrentPageIndex(1);
                searchUsers();
              }}
            >
              <Icon className="search" />
            </Button>
          </div>
        </div>
      </div>
      <UsersList
        users={users}
        userType={userType}
        loadingInProgress={inProgress}
        currentPage={currentPageIndex}
        pageSize={pageSize}
        totalUserCount={totalUserCount}
        blockActions={inProgress}
        onItemSelected={(u) => setSelectedUser(u)}
        onPageChanged={(pageIndex) => {
          setCurrentPageIndex(pageIndex);
        }}
      />
    </>
  );
}
