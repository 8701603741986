export default class LookupsStore {

    semsters_en: { text: string; value: number }[] = [
        { text: 'Fall', value: 1 },
        { text: 'Spring', value: 2 },
        { text: 'Summer', value: 3 },
        { text: 'Year Long', value: 4 },
    ];

    semsters_ar: { text: string; value: number }[] = [
        { text: 'خريف', value: 1 },
        { text: 'ربيع', value: 2 },
        { text: 'صيف', value: 3 },
        { text: 'طوال السنة', value: 4 },
    ];

    getSemsters = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.semsters_ar;
        return this.semsters_en;
    }

    getSemsterValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.semsters_ar.filter(t => t.value === key);
        else
            match = this.semsters_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    institutions_en: { text: string; value: number }[] = [
        { text: 'School', value: 1 },
        { text: 'University', value: 2 },
        { text: 'Collage', value: 3 },
        { text: 'TVET', value: 4 },
        { text: 'Youth center', value: 5 },
    ];



    institutions_ar: { text: string; value: number }[] = [
        { text: 'مدرسة', value: 1 },
        { text: 'جامعة', value: 2 },
        { text: 'كليّة', value: 3 },
        { text: 'مركز تّدريب مهنيّ', value: 4 },
        { text: 'مركز شبابي', value: 5 },
    ];

    thematicArea_en: { text: string; value: number }[] = [
        { text: 'Financial Education', value: 1 },
        { text: 'Entrepreneurship and Business Skills', value: 2 },
        { text: 'Social Leadership', value: 3 },
        { text: 'Life Skills', value: 4 },
        { text: 'Digital Skills', value: 5 },
    ];

    thematicArea_ar: { text: string; value: number }[] = [
        { text: 'الثّقافة الماليّة', value: 1 },
        { text: 'مهارات إدارة الأعمال والرّيادة', value: 2 },
        { text: 'القيادة المجتمعيّة', value: 3 },
        { text: 'المهارات الحياتيّة', value: 4 },
        { text: 'المهارات الرّقميّة', value: 5 },
    ];

    genderList_ar: { text: string; value: number }[] = [
        { text: 'أنثى', value: 1 },
        { text: 'ذكر', value: 2 },
    ];

    genderList_en: { text: string; value: number }[] = [
        { text: 'Female', value: 1 },
        { text: 'Male', value: 2 },
    ];

    genderPreferenceList_ar: { text: string; value: number }[] = [
        { text: 'أنثى', value: 1 },
        { text: 'ذكر', value: 2 },
        { text: 'كلاهما', value: 3 },
    ];

    getGenderPreferenceList = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.genderPreferenceList_ar;
        return this.genderPreferenceList_en;
    }

    getGenderPreferenceValue = (key: number | undefined, language: string | null): string => {
        return this.getItemValue(key, this.genderPreferenceList_en, this.genderPreferenceList_ar, language);
    }

    genderPreferenceList_en: { text: string; value: number }[] = [
        { text: 'Female', value: 1 },
        { text: 'Male', value: 2 },
        { text: 'Both', value: 3 },
    ];

    yesNoList_en: { text: string; value: number }[] = [
        { text: 'No', value: 1 },
        { text: 'Yes', value: 2 }
    ];

    yesNoList_ar: { text: string; value: number }[] = [
        { text: 'لا', value: 1 },
        { text: 'نعم', value: 2 },
    ];

    classList_en: { text: string; value: number }[] = [
        { text: 'Grade 1', value: 1 },
        { text: 'Grade 2', value: 2 },
        { text: 'Grade 3', value: 3 },
        { text: 'Grade 4', value: 4 },
        { text: 'Grade 5', value: 5 },
        { text: 'Grade 6', value: 6 },
        { text: 'Grade 7', value: 7 },
        { text: 'Grade 8', value: 8 },
        { text: 'Grade 9', value: 9 },
        { text: 'Grade 10', value: 10 },
        { text: 'Grade 11', value: 11 },
        { text: 'Grade 12', value: 12 },
    ];

    classList_ar: { text: string; value: number }[] = [
        { text: 'الصف الأول', value: 1 },
        { text: 'الصف الثاني', value: 2 },
        { text: 'الصف الثالث', value: 3 },
        { text: 'الصف الرابع', value: 4 },
        { text: 'الصف الخامس', value: 5 },
        { text: 'الصف السادس', value: 6 },
        { text: 'الصف السابع', value: 7 },
        { text: 'الصف الثامن', value: 8 },
        { text: 'الصف التاسع', value: 9 },
        { text: 'الصف العاشر', value: 10 },
        { text: 'الصف الحادي عشر', value: 11 },
        { text: 'الصف الثاني عشر', value: 12 },
    ];

    sectionList_en: { text: string; value: number }[] = [
        { text: 'Section 1', value: 1 },
        { text: 'Section 2', value: 2 },
        { text: 'Section 3', value: 3 },
        { text: 'Section 4', value: 4 },
        { text: 'Section 5', value: 5 }
    ];

    sectionList_ar: { text: string; value: number }[] = [
        { text: 'الشعبة الأولى', value: 1 },
        { text: 'الشعبة الثانية', value: 2 },
        { text: 'الشعبة الثالثة', value: 3 },
        { text: 'الشعبة الرابعة', value: 4 },
        { text: 'الشعبة الخامسة', value: 5 }
    ];

    volunteerTypes_en: { text: string; value: number }[] = [
        { text: 'Individual', value: 1 },
        { text: 'Corporate', value: 2 },
        { text: 'Teacher', value: 3 }
    ];

    volunteerTypes_ar: { text: string; value: number }[] = [
        { text: 'أفراد', value: 1 },
        { text: 'شركات', value: 2 },
        { text: 'معلمين', value: 3 }
    ];

    volunteerStatus_en: { text: string; value: number }[] = [
        { text: 'Matched', value: 1 },
        { text: 'Not Matched', value: 2 },
        { text: 'Dropped', value: 3 }
    ];

    volunteerStatus_ar: { text: string; value: number }[] = [
        { text: 'Matched', value: 1 },
        { text: 'Not Matched', value: 2 },
        { text: 'Dropped', value: 3 }
    ];

    educationDegrees_en: { text: string; value: number }[] = [
        { text: 'Seconadry', value: 1 },
        { text: 'Diploma', value: 2 },
        { text: 'Bachelor degree', value: 3 },
        { text: 'Masters', value: 4 },
        { text: 'PHD', value: 5 }
    ];

    educationDegrees_ar: { text: string; value: number }[] = [
        { text: 'ثانوي', value: 1 },
        { text: 'دبلوم', value: 2 },
        { text: 'بكالوريوس', value: 3 },
        { text: 'ماجستير', value: 4 },
        { text: 'دكتوراه', value: 4 }
    ];

    weekdays_ar: { text: string; value: number }[] = [
        { text: 'الأحد', value: 1 },
        { text: 'الأثنين', value: 2 },
        { text: 'الثلاثاء', value: 3 },
        { text: 'الأربعاء', value: 4 },
        { text: 'الخميس', value: 5 },
        { text: 'الجمعه', value: 6 },
        { text: 'السبت', value: 7 },
    ];

    weekdays_en: { text: string; value: number }[] = [
        { text: 'Sunday', value: 1 },
        { text: 'Monday', value: 2 },
        { text: 'Tuesday', value: 3 },
        { text: 'Wedensday', value: 4 },
        { text: 'Thusday', value: 5 },
        { text: 'Friday', value: 6 },
        { text: 'Saturday', value: 7 },
    ];

    courseDeliverySchemes_en: { text: string; value: number }[] = [
        { text: 'Online', value: 1 },
        { text: 'Offline', value: 2 },
        { text: 'Both', value: 3 },
    ];

    courseDeliverySchemes_ar: { text: string; value: number }[] = [
        { text: 'اون لاين', value: 1 },
        { text: 'اوف لاين', value: 2 },
        { text: 'كلاهما', value: 3 },
    ];

    courseTimes_en: { text: string; value: number }[] = [
        { text: '6:00AM', value: 6 },
        { text: '7:00AM', value: 7 },
        { text: '8:00AM', value: 8 },
        { text: '9:00AM', value: 9 },
        { text: '10:00AM', value: 10 },
        { text: '11:00AM', value: 11 },
        { text: '12:00PM', value: 12 },
        { text: '1:00PM', value: 13 },
        { text: '2:00PM', value: 14 },
        { text: '3:00PM', value: 15 },
        { text: '4:00PM', value: 16 },
        { text: '5:00PM', value: 17 },
        { text: '6:00PM', value: 18 },
    ];

    courseTimes_ar: { text: string; value: number }[] = [
        { text: '٦ ص', value: 6 },
        { text: '٧ ص', value: 7 },
        { text: '٨ ص', value: 8 },
        { text: '٩ ص', value: 9 },
        { text: '١٠ ص', value: 10 },
        { text: '١١ ص', value: 11 },
        { text: '١٢ م', value: 12 },
        { text: '١ م', value: 13 },
        { text: '٢ م', value: 14 },
        { text: '٣ م', value: 15 },
        { text: '٤ م', value: 16 },
        { text: '٥ م', value: 17 },
        { text: '٦ م', value: 18 },
    ];

    getCourseTimes = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.courseTimes_ar;
        return this.courseTimes_en;
    }

    areas_en: { text: string; value: number }[] = [
        { text: 'North', value: 1 },
        { text: 'Middle', value: 2 },
        { text: 'South', value: 3 },
    ];

    areas_ar: { text: string; value: number }[] = [
        { text: 'شمال', value: 1 },
        { text: 'المنتصف', value: 2 },
        { text: 'جنوب', value: 3 },
    ];

    getAreas = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.areas_ar;
        return this.areas_en;
    }

    getAreaValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.areas_ar.filter(t => t.value === key);
        else
            match = this.areas_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    departments_en: { text: string; value: number }[] = [
        { text: 'PMO', value: 1 },
        { text: 'Quality', value: 2 },
        { text: 'Operations', value: 3 },
        { text: 'Programs', value: 4 },
        { text: 'Marketing', value: 5 },
        { text: 'Volunteers', value: 6 },
    ];

    departments_ar: { text: string; value: number }[] = [
        { text: 'PMO', value: 1 },
        { text: 'Quality', value: 2 },
        { text: 'Operations', value: 3 },
        { text: 'Programs', value: 4 },
        { text: 'Marketing', value: 5 },
        { text: 'Volunteers', value: 6 },
    ];

    getDepartments = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.departments_ar;
        return this.departments_en;
    }

    getDepartmentValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.departments_ar.filter(t => t.value === key);
        else
            match = this.departments_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getInstitutions = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.institutions_ar;
        return this.institutions_en;
    }

    getThematicAreas = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.thematicArea_ar;
        return this.thematicArea_en;
    }

    getThematicAreaName = (thematicAreaId: number, language: string | null): string => {
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.thematicArea_ar.filter(t => t.value === thematicAreaId);
        else
            match = this.thematicArea_en.filter(t => t.value === thematicAreaId);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getGenders = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.genderList_ar;
        return this.genderList_en;
    }

    getGender = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.genderList_ar.filter(t => t.value === key);
        else
            match = this.genderList_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getEducationDegree = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.educationDegrees_ar.filter(t => t.value === key);
        else
            match = this.educationDegrees_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getYesNoList = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.yesNoList_ar;
        return this.yesNoList_en;
    }

    getClassList = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.classList_ar;
        return this.classList_en;
    }

    getSectionList = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.sectionList_ar;
        return this.sectionList_en;
    }

    getVolunteersTypes = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.volunteerTypes_ar;
        return this.volunteerTypes_en;
    }

    getVolunteerType = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.volunteerTypes_ar.filter(t => t.value === key);
        else
            match = this.volunteerTypes_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getVolunteersStatuses = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.volunteerStatus_ar;
        return this.volunteerStatus_en;
    }

    getVolunteerStatus = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.volunteerStatus_ar.filter(t => t.value === key);
        else
            match = this.volunteerStatus_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getEducationDegrees = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.educationDegrees_ar;
        return this.educationDegrees_en;
    }

    getWeekdays = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.weekdays_ar;
        return this.weekdays_en;
    }

    getCourseDeliverySchemes = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.courseDeliverySchemes_ar;
        return this.courseDeliverySchemes_en;
    }

    getCourseDeliveryScheme = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.courseDeliverySchemes_ar.filter(t => t.value === key);
        else
            match = this.courseDeliverySchemes_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getGraduationYears = (language: string | null): { text: string; value: number }[] => {
        let years: { text: string; value: number }[] = [];
        let currentYear = new Date().getFullYear();
        for (var i = 1970; i <= currentYear; i++)
            years.push({ text: i.toString(), value: i });
        return years;
    }

    getWeekDaysValues = (values: number[], language: string | null): string[] => {
        if (values === null || values.length === 0)
            return [];
        let days: string[] = [];
        let weekdays = language === "ar" ? this.weekdays_ar : this.weekdays_en;
        for (var i = 0; i < values.length; i++) {
            let day = weekdays.filter(d => d.value === values[i]);
            if (day != null && day.length > 0)
                days.push(day[0].text);
        }
        return days;
    }

    getInstitutionsTypesValues = (values: number[], language: string | null): string[] => {
        if (values === null || values.length === 0)
            return [];
        let types: string[] = [];
        let list = language === "ar" ? this.institutions_ar : this.institutions_en;
        for (var i = 0; i < values.length; i++) {
            let item = list.filter(d => d.value === values[i]);
            if (item != null && item.length > 0)
                types.push(item[0].text);
        }
        return types;
    }

    getInstitutionsTypesValue = (key: number, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.institutions_ar.filter(t => t.value === key);
        else
            match = this.institutions_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getClassValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.classList_ar.filter(t => t.value === key);
        else
            match = this.classList_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getSectionValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.sectionList_ar.filter(t => t.value === key);
        else
            match = this.sectionList_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getCourseTimeValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.courseTimes_ar.filter(t => t.value === key);
        else
            match = this.courseTimes_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    getWeekdayValue = (key: number | undefined, language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = this.weekdays_ar.filter(t => t.value === key);
        else
            match = this.weekdays_en.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

    institutionGenderTypes_ar: { text: string; value: number }[] = [
        { text: 'إيناث', value: 1 },
        { text: 'ذكور', value: 2 },
        { text: 'مختلط', value: 3 },
    ];

    institutionGenderTypes_en: { text: string; value: number }[] = [
        { text: 'Females', value: 1 },
        { text: 'Males', value: 2 },
        { text: 'Co-ed', value: 3 },
    ];

    getInstitutionGenderTypeValue = (key: number | undefined, language: string | null): string => {
        return this.getItemValue(key, this.institutionGenderTypes_en, this.institutionGenderTypes_ar, language);
    }

    institutionClassificationTypes_ar: { text: string; value: number }[] = [
        { text: 'Private', value: 1 },
        { text: 'Public', value: 2 },
        { text: 'Military', value: 3 },
        { text: 'UNRWA', value: 4 },
    ];

    institutionClassificationTypes_en: { text: string; value: number }[] = [
        { text: 'Private', value: 1 },
        { text: 'Public', value: 2 },
        { text: 'Military', value: 3 },
        { text: 'UNRWA', value: 4 },
    ];

    getInstitutionClassificationTypes = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.institutionClassificationTypes_ar;
        return this.institutionClassificationTypes_en;
    }

    getInstitutionClassificationTypeValue = (key: number | undefined, language: string | null): string => {
        return this.getItemValue(key, this.institutionClassificationTypes_en, this.institutionClassificationTypes_ar, language);
    }

    schoolStages_ar: { text: string; value: number }[] = [
        { text: 'ابتدائي', value: 1 },
        { text: 'ثانوي', value: 2 },
        { text: 'كلاهما', value: 3 }
    ];

    schoolStages_en: { text: string; value: number }[] = [
        { text: 'Primary', value: 1 },
        { text: 'Secondary', value: 2 },
        { text: 'Both', value: 3 }
    ];

    getSchoolStages = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.schoolStages_ar;
        return this.schoolStages_en;
    }

    private InstitutionStatuses_ar: { text: string; value: number }[] = [
        { text: 'مفتوحه', value: 1 },
        { text: 'مغلقه', value: 2 },
        { text: 'لم يتم العمل معه من قبل', value: 3 }
    ];

    private InstitutionStatuses_en: { text: string; value: number }[] = [
        { text: 'Open', value: 1 },
        { text: 'Closed', value: 2 },
        { text: 'No Recent Experience', value: 3 }
    ];

    getInstitutionStatuses = (language: string | null): { text: string; value: number }[] => {
        if (language === "ar")
            return this.InstitutionStatuses_ar;
        return this.InstitutionStatuses_en;
    }

    getInstitutionStatusValue = (key: number | undefined, language: string | null): string => {
        return this.getItemValue(key, this.InstitutionStatuses_en, this.InstitutionStatuses_ar, language);
    }

    private getItemValue = (key: number | undefined, englishList: { text: string; value: number }[], arabicList: { text: string; value: number }[], language: string | null): string => {
        if (!key) return '';
        let match: { text: string; value: number }[];
        if (language === "ar")
            match = arabicList.filter(t => t.value === key);
        else
            match = englishList.filter(t => t.value === key);
        if (match && match.length > 0)
            return match[0].text;
        return "";
    }

}