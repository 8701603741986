import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button, Segment, Form, Header, Grid, Dimmer, Loader } from "semantic-ui-react";
import agent from "../../../api/agent";
import { LookupDto } from "../../../models/lookupDto";
import { useStore } from "../../../stores/store";
import * as Yup from "yup";
import CustomSelectInput from "../../../common/forms/CustomSelectInput";
import { Formik } from "formik";
import CustomTextInput from "../../../common/forms/CustomTextInput";
import CustomDateInput from "../../../common/forms/CustomDateInput";
import CustomCheckboxInput from "../../../common/forms/CustomCheckboxInput";
import { toast } from "react-toastify";
import AddStudentDto from "../../../models/Users/Students/addStudentDto";

interface Params {
  userId: string | undefined;
}

export default function AddEditStudent() {
  const { userId } = useParams<Params>();
  const { t } = useTranslation("common");
  const history = useHistory();
  const { lookupsStore, commonStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [maleFemaleValues, setMaleFemaleValues] = useState<{ text: string; value: number }[]>([]);
  const [classesValues, setClassesValues] = useState<{ text: string; value: number }[]>([]);
  const [sectionsValues, setSectionsValues] = useState<{ text: string; value: number }[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [yesNoValues, setYesNoValues] = useState<{ text: string; value: number }[]>([]);
  const [institutions, setInstitutions] = useState<LookupDto[]>([]);
  const [countries, setCountries] = useState<LookupDto[]>([]);
  const [user, setUser] = useState<AddStudentDto>({
    userId: 0,
    firstName: "",
    middleName: null,
    lastName: "",
    email: "",
    phoneNumber: null,
    address: null,
    gender: null,
    fullArabicName: "",
    birthdate: null,
    nationality: null,
    nationalId: null,
    institutionId: 0,
    classId: 0,
    sectionId: 0,
    participatedOnPreviousInjazPrograms: false,
    isActive: false,
  });

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    //load institution
    agent.Institutions.listLookups()
      .then((result) => setInstitutions(result))
      .catch((error) => handleError(error));
    agent.Lookups.countries()
      .then((result) => {
        setCountries(result);
      })
      .catch((error) => handleError(error));
  }, []);

  useEffect(() => {
    if (userId == undefined) return;
    setInProgress(true);
    agent.Users.editStudent(userId)
      .then((result) => setUser(result))
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [userId]);

  useEffect(() => {
    let translatedGenders = lookupsStore.getGenders(commonStore.lang);
    setMaleFemaleValues(translatedGenders);

    let translatedYesNoList = lookupsStore.getYesNoList(commonStore.lang);
    setYesNoValues(translatedYesNoList);

    let classesList = lookupsStore.getClassList(commonStore.lang);
    setClassesValues(classesList);

    let sectionsList = lookupsStore.getSectionList(commonStore.lang);
    setSectionsValues(sectionsList);
  }, [commonStore.lang]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Common.Required")),
    lastName: Yup.string().required(t("Common.Required")),
    fullArabicName: Yup.string().required(t("Common.Required")),
    email: Yup.string()
      .typeError(t("Common.InvalidEmailAddress"))
      .required(t("Common.Required"))
      .email(t("Common.InvalidEmailAddress")),
    classId: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    sectionId: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    nationality: Yup.number()
      .typeError(t("Common.Required"))
      .min(1, t("Common.Required"))
      .required(t("Common.Required")),
  });

  const submitForm = (values: AddStudentDto) => {
    setUser(values);
    if (!userId || userId == null) addNewUser(values);
    else updateUser(values);
  };

  const addNewUser = (values: AddStudentDto) => {
    setIsSaving(true);
    agent.Users.addStudent(values)
      .then((result) => {
        toast.success(t("UserManagement.UserAdddedSuccessfully"));
        history.push("/manageusers/student");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const updateUser = (values: AddStudentDto) => {
    setIsSaving(true);
    agent.Users.updateStudent(values)
      .then((result) => {
        toast.success(t("UserManagement.UserUpdatedSucessfully"));
        history.push("/manageusers/student");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <Header as="h2">{t("UserManagement.Students.NewStudent")}</Header>
      <div>
        <div className="injaz-forms-cancel-btn-container">
          <Button
            className="injaz-forms-cancel-btn"
            basic
            color="blue"
            content={t("Common.Cancel")}
            as={NavLink}
            to={"/manageusers/student"}
          />
        </div>
        <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
          <Segment color="yellow" className="injaz-forms-segment-nopad">
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={user}
              onSubmit={(values) => submitForm(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-btn-container ui menu injaz-forms-btns-incl">
                    <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                  </div>
                  <Grid className="injaz-form-pad">
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("UserManagement.FirstName") + " *"}
                            placeholder={t("UserManagement.FirstName")}
                            name="firstName"
                          />
                          <CustomTextInput
                            label={t("UserManagement.MiddleName")}
                            placeholder={t("UserManagement.MiddleName")}
                            name="middleName"
                          />
                          <CustomTextInput
                            label={t("UserManagement.LastName") + " *"}
                            placeholder={t("UserManagement.LastName")}
                            name="lastName"
                          />
                          <CustomTextInput
                            label={t("UserManagement.Students.FullArabicName") + " *"}
                            placeholder={t("UserManagement.Students.FullArabicName")}
                            name="fullArabicName"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <div className="field">
                            <div className="ui label label">Birthdate</div>
                            <CustomDateInput
                              label={t("UserManagement.Students.Birthdate")}
                              name="birthdate"
                            />
                          </div>
                          <CustomTextInput
                            label={t("UserManagement.EmailAddress") + " *"}
                            placeholder={t("UserManagement.EmailAddress")}
                            name="email"
                          />
                          <CustomTextInput
                            label={t("UserManagement.PhoneNumber")}
                            placeholder={t("UserManagement.PhoneNumber")}
                            name="phoneNumber"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("UserManagement.Students.Address")}
                            placeholder={t("UserManagement.Students.Address")}
                            name="address"
                          />
                          <CustomSelectInput
                            label={t("UserManagement.Nationality") + " *"}
                            placeholder={t("UserManagement.Nationality")}
                            name="nationality"
                            options={countries}
                          />
                          <CustomTextInput
                            label={t("UserManagement.NationalID")}
                            placeholder={t("UserManagement.NationalID")}
                            name="nationalId"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomSelectInput
                            label={t("UserManagement.Gender") + " *"}
                            options={maleFemaleValues}
                            name="gender"
                            placeholder={t("UserManagement.Gender")}
                          />
                          <CustomSelectInput
                            label={t("UserManagement.Students.InstitutionName")}
                            options={institutions}
                            name="institutionId"
                            placeholder={t("UserManagement.Students.InstitutionName")}
                          />
                          <CustomSelectInput
                            label={t("UserManagement.Students.Class") + " *"}
                            options={classesValues}
                            name="classId"
                            placeholder={t("UserManagement.Students.Class")}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomSelectInput
                            label={t("UserManagement.Students.Section") + " *"}
                            options={sectionsValues}
                            name="sectionId"
                            placeholder={t("UserManagement.Students.Section")}
                          />
                          <div className="checkbox-align field">
                            <CustomCheckboxInput
                              name="participatedOnPreviousInjazPrograms"
                              label={t("UserManagement.Students.PreviousInjazCompetition")}
                              type="checkbox"
                            />
                          </div>
                          <div className="checkbox-align field">
                            <CustomCheckboxInput
                              name="isActive"
                              label={t("UserManagement.Active")}
                              type="checkbox"
                            />
                          </div>
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Segment>
        </Segment.Group>
      </div>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
