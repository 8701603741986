import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import agent from "../../api/agent";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { SessionDto } from "../../models/sessionDto";
import TakeAttendance from "./TakeAttendance";
import ViewAttendance from "./ViewAttendance";

export interface Props {
  course: CourseSummaryDto | undefined;
  courseId: string;
  timeStamp: Date;
}

export default function CourseAttendance({ course, courseId, timeStamp }: Props) {
  const { t } = useTranslation("common");
  const [inTakeAttendanceMode, setInTakeAttendanceMode] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [sessions, setSessions] = useState<SessionDto[]>([]);
  const [selectedSession, setSelectedSession] = useState<number>();

  const handleError = (error: any) => console.log(error);

  useEffect(() => {
    agent.Courses.sessions(courseId)
      .then((result) => {
        setSessions(result);
        if (result != null && result.length > 0) setSelectedSession(result[0].id);
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [courseId, timeStamp]);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <div className="ui form session-select injaz-take-attendance">
        <div className="injaz-attendance-actions">
        <label>{t("CourseManagement.SessionNumber")}: </label>
        <select
          className="attendancesessionsellect"
          id="attendancesessionsellect1"
          name="attendancesessionsellect"
          onChange={(e) => {
            setSelectedSession(+e.target.value);
          }}
        >
          {sessions &&
            sessions.length > 0 &&
            sessions.map((session) => (
              <option value={session.id} key={session.id}>
                {session.sessionNumber + " - " + session.name}
              </option>
            ))}
        </select>
        </div>
        {inTakeAttendanceMode && (
          <TakeAttendance sessionId={selectedSession!} onClose={() => setInTakeAttendanceMode(false)} />
        )}
        {!inTakeAttendanceMode && (
          <ViewAttendance
            course={course}
            sessionId={selectedSession!}
            takeAttendance={() => setInTakeAttendanceMode(true)}
          />
        )}
      </div>
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
