import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Card, Grid, Icon, Image } from "semantic-ui-react";
import agent from "../../api/agent";
import { ProgramSummaryDto } from "../../models/programSummaryDto";

export default function LatestPrograms() {
  const { t } = useTranslation("common");
  const [programs, setPrograms] = useState<ProgramSummaryDto[]>([]);
  useEffect(() => {
    agent.Programs.latest()
      .then((result) => setPrograms(result))
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <Grid.Row columns={5} className="injaz-public-row2">
        {programs &&
          programs.length > 0 &&
          programs.map((program, index) => (
            <Grid.Column key={program.id} className={"injaz-row2-col" + (index + 1).toString()}>
              <Card>
                <Card.Content>
                  <Card.Header>{program.name}</Card.Header>
                  <Image
                    floated="left"
                    size="medium"
                    src={
                      program.imageUrl == null ? "/img/default_course.png" : program.imageUrl
                    }
                  />
                  <Card.Description>
                    <strong>{t("Common.SupportedBy")}: </strong>
                    {program.donorName}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <NavLink to={`/programdetails/${program.id}`}>
                    {t("Common.More")} <Icon name="arrow right" floated="left" />
                  </NavLink>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
      </Grid.Row>
    </>
  );
}
