import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Header, Icon, Loader, Segment, Table } from "semantic-ui-react";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { useStore } from "../../stores/store";
import * as XLSX from "xlsx";
import VolunteerInformationDto from "../../models/Reports/volunteerInformationDto";

export default function VolunteersReport() {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();
  const [records, setRecords] = useState<VolunteerInformationDto[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(true);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Reports.volunteersReport()
      .then((result) => setRecords(result))
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, []);

  const exportExcel = () => {
    var table_elt = document.getElementById("tblReport");
    const workSheet = XLSX.utils.table_to_sheet(table_elt);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Data");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "volunteers_report.xlsx");
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const table = (
    <div id="volunteer-tbl" className="reports-tbl">
      <Table id="tblReport" className="single line">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Reports.VolunteerName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.PhoneNumber")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reports.MF")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reports.Birthdate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reports.IndividualsCompanies")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reports.EducationalDegree")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.Major")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reports.UniversityOrCollege")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.YearOfGraduation")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Reports.Governate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.VolunteerAddress")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.VolunteerCompanyAddress")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("UserManagement.Volunteers.CumulativeYearsWorkExperience")}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.PositionTitle")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.VolunteerCompany")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.PMP")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.PMPExperience")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.TOT")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("UserManagement.Volunteers.PreferredVolunteeringDays")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("UserManagement.Volunteers.PreferredInstitutionTypes")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("UserManagement.Volunteers.FavoriteRegionToVolunteerIn")}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.Volunteers.VDNotes")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {records &&
            records.length > 0 &&
            records.map((record, index) => (
              <Table.Row key={index}>
                <Table.Cell>{record.volunteerName}</Table.Cell>
                <Table.Cell>{record.phone}</Table.Cell>
                <Table.Cell>{lookupsStore.getGender(record.gender, commonStore.lang)}</Table.Cell>
                <Table.Cell>{record.email}</Table.Cell>
                <Table.Cell>{record.birthdate}</Table.Cell>
                <Table.Cell>
                  {lookupsStore.getVolunteerType(record.volunteerType, commonStore.lang)}
                </Table.Cell>
                <Table.Cell>{record.educationDegree}</Table.Cell>
                <Table.Cell>{record.major}</Table.Cell>
                <Table.Cell>{record.educationInstitutionName}</Table.Cell>
                <Table.Cell>{record.graduationYear}</Table.Cell>
                <Table.Cell>{record.governateName}</Table.Cell>
                <Table.Cell>{record.volunteerAddress}</Table.Cell>
                <Table.Cell>{record.volunteerCompanyAddress}</Table.Cell>
                <Table.Cell>{record.experienceYears}</Table.Cell>
                <Table.Cell>{record.positionTitle}</Table.Cell>
                <Table.Cell>{record.volunteerCompany}</Table.Cell>
                <Table.Cell>{record.pmp ? t("Common.Yes") : t("Common.No")}</Table.Cell>
                <Table.Cell>{record.pmpExperienceYears}</Table.Cell>
                <Table.Cell>{record.tot ? t("Common.Yes") : t("Common.No")}</Table.Cell>
                <Table.Cell>
                  {record.preferredVolunteeringDays &&
                    record.preferredVolunteeringDays.length > 0 &&
                    record.preferredVolunteeringDays.map((day) => (
                      <span>{lookupsStore.getWeekdayValue(day, commonStore.lang) + " "} </span>
                    ))}
                </Table.Cell>
                <Table.Cell>
                  {record.preferredVolunteeringInstitutionTypes &&
                    record.preferredVolunteeringInstitutionTypes.length > 0 &&
                    record.preferredVolunteeringInstitutionTypes.map((inst) => (
                      <span>{lookupsStore.getInstitutionsTypesValue(inst, commonStore.lang) + " "}</span>
                    ))}
                </Table.Cell>
                <Table.Cell>{record.preferredVolunteeringGovernateName}</Table.Cell>
                <Table.Cell>{record.vdNotes}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  );

  const content = (
    <>
      <div className="ui menu injaz-forms-btns">
        <div className="item">
          <Button basic color="blue" onClick={exportExcel}>
            <Icon name="download" />Download
          </Button>
        </div>
      </div>
      {table}
    </>
  );

  return (
    <>
      <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="/reports" className="injaz-breadcrumbs__item">Reports</a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">
            <span>{t("Reports.VolunteersReport")}</span>
          </a>
      </nav>
      <Header as="h1">{t("Reports.VolunteersReport")}</Header>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
