import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Dimmer, Loader, Segment, Header, Grid, Image, Label, Icon, Sidebar, Button } from "semantic-ui-react";
import agent from "../../api/agent";
import { programDetailsDto } from "../../models/programDetailsDto";
import { useStore } from "../../stores/store";
import ChangeProgramImage from "./ChangeProgramImage";
import ProgramCourses from "./ProgramCourses";

interface ViewProgramDetailsParams {
  programId: string;
}
export default function ViewProgramDetails() {
  let { programId } = useParams<ViewProgramDetailsParams>();
  const history = useHistory();
  const { lookupsStore, commonStore, userStore } = useStore();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [openImageMenu, setOpenImageMenu] = useState(false);
  const { t } = useTranslation("common");
  const [program, setProgram] = useState<programDetailsDto>();

  const handleError = (error: any) => {
    toast.error(t("Common.GeneralErrorMessage"));
  };

  const loadProgram = () => {
    setInProgress(true);
    agent.Programs.details(programId)
      .then((result) => {
        if (result === null) history.push("/notfound");
        setProgram(result);
      })
      .catch((error) => history.push("/notfound"))
      .finally(() => {
        setInProgress(false);
      });
  };
  useEffect(() => {
    loadProgram();
  }, []);

  const changeProgramImage = () => {
    setOpenImageMenu(true);
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        {userStore.isLoggedIn && userStore.isAdmin && (
          <a href="/ManagePrograms" className="injaz-breadcrumbs__item">
            {t("ProgramManagement.ManagePrograms.ManageProgramsTitle")}
          </a>
        )}
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          <span>{t("ProgramManagement.ProgramDetails")}</span>
        </a>
      </nav>
      <Header as="h1">{/*t("ProgramManagement.ProgramDetails")*/}</Header>
      <Grid className="injaz-program-information" stackable>
        <Grid.Row>
          <Grid.Column width={3}>
            {program?.imageUrl == null && <Image src="https://react.semantic-ui.com/images/wireframe/square-image.png" />}
            {program?.imageUrl != null && <Image src={program.imageUrl} />}
            {userStore.isLoggedIn && userStore.isAdmin && (
              <a className="change-program-image" onClick={() => changeProgramImage()}>
                <Icon name="camera" />
                {t("Common.Change")}
              </a>
            )}
          </Grid.Column>
          <Grid.Column width={10}>
            <Header as="h2">{program?.name}</Header>
            <div className="injaz-progranm-shortname">{program?.shortName}</div>
            <Label color="red" className="injaz-thematic-area">
              {program && program.thematicAreaType && lookupsStore.getThematicAreaName(program.thematicAreaType, commonStore.lang)}
            </Label>
            <div className="injaz-program-brief">{program?.brief}</div>
            {/*<div className="panel-wrapper">
              <a href="#show" className="show btn" id="show">more...</a> 
              <a href="#hide" className="hide btn" id="hide">less...</a> 
              <div className="panel injaz-program-brief">
                {program?.brief}  
              </div>
              </div>*/}
            <div className="injaz-program-outcome">{program?.outcomes}</div>
            <div>
              <span className="injaz-program-label">{t("ProgramManagement.PrerequisiteCourses")}: </span>
              {(program?.prerequisites == null || program.prerequisites.length === 0) && "--"}
              {program?.prerequisites != null &&
                program.prerequisites.length > 0 &&
                program.prerequisites.map((p) => (
                  <span key={p} className="injaz-program-label-detail">
                    {p}
                  </span>
                ))}
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div>
              {/*hrefs={`https://lms.injaz.edu.jo/auth/login/azuread-oauth2/?auth_entry=login&next=https://studio.injaz.edu.jo/course/${encodeURI(
                  program?.adoptCourseId!
                )}`}*/}
              {userStore.isLoggedIn && userStore.isAdmin && (
                <a className="ui blue basic button course-btn" role="button" href={program?.edxCourseUrl} target="_blank" rel="noreferrer">
                  {t("ProgramManagement.EditProgramContent")}
                </a>
              )}
            </div>
            {userStore.isLoggedIn && userStore.isAdmin && (
              <div>
                <Button className="course-btn" basic color="blue" content={t("ProgramManagement.EditProgramDetails")} as={NavLink} to={`/editprogram/${programId}`} />
              </div>
            )}
            <div>
              {userStore.isLoggedIn && userStore.isAdmin && (
                <a
                  className="ui blue basic button course-btn"
                  role="button"
                  href={`https://studio.injaz.edu.jo/course_team/${program?.adoptCourseId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("ProgramManagement.AddAnotherAdmin")}
                </a>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className="injaz-program-details" stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            {userStore.isLoggedIn && !userStore.isStudent && (
              <Segment color="red" className="program-details-segment">
                <Header as="h3">{t("ProgramManagement.ProgramDetails")}</Header>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.DonorName")}: </span>
                  <span className="injaz-program-label-detail">{program?.donorName}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.ProjectName")}: </span>
                  <span className="injaz-program-label-detail">{program?.projectName}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.AcademicYear")}: </span>
                  <span className="injaz-program-label-detail">{program?.academicYearName}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.Semester")}: </span>
                  <span className="injaz-program-label-detail">{lookupsStore.getSemsterValue(program?.semester, commonStore.lang)}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.NumberofSessions")}: </span>
                  <span className="injaz-program-label-detail">{program?.numberOfsessions}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.Numberofprogramhours")}:</span>
                  <span className="injaz-program-label-detail">
                    {program?.numberOfProgramHours} {t("Common.Hours")}
                  </span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.InstitutionTypes")}: </span>
                  {program?.institutionTypes &&
                    program.institutionTypes.length > 0 &&
                    program.institutionTypes.map((institution) => (
                      <span key={institution} className="injaz-program-label-detail">
                        {lookupsStore.getInstitutionsTypesValue(institution, commonStore.lang)}
                      </span>
                    ))}
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.TargetGroup")}: </span>
                  <span className="injaz-program-label-detail">{program?.targetGroupName}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.Version")}: </span>
                  <span className="injaz-program-label-detail">{program?.version}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.ProgramConcatenatedName")}: </span>
                  <span className="injaz-program-label-detail">{program?.programConcatenatedName}</span>
                </div>
              </Segment>
            )}
          </Grid.Column>
          <Grid.Column width={6}>
            {userStore.isLoggedIn && !userStore.isStudent && (
              <Segment color="yellow">
                <Header as="h3">{t("ProgramManagement.GraduationCriteria")}</Header>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.MinimumNumberOfAttendedSessions")}: </span>
                  <span>{program?.graduationCriteria_MinimumNumberOfAttendedSessions == null ? "N/A" : program?.graduationCriteria_MinimumNumberOfAttendedSessions}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.FillSurvey")}: </span>
                  <span>{program?.graduationCriteria_SurveyFilled ? t("ProgramManagement.SurveyRequired") : t("ProgramManagement.SurveyNotRequired")}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.AttendSpecificSessions")}: </span>
                  {program?.graduationRequiredSessiosNumbers && program.graduationRequiredSessiosNumbers.length > 0
                    ? program.graduationRequiredSessiosNumbers.map((session) => <span key={session}>{session}</span>)
                    : "N/A"}
                </div>
              </Segment>
            )}
            {userStore.isLoggedIn && !userStore.isStudent && (
              <Segment color="blue">
                <Header as="h3">{t("ProgramManagement.VolunteerSelectionCriteria")}</Header>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.MinimumLevelOfEducation")}: </span>
                  <span>{program?.volunteerSelectionCriteria_MinimumLevelOfeducationName}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.Major")}: </span>
                  {program?.volunteerSelectionMajors &&
                    program?.volunteerSelectionMajors.length > 0 &&
                    program?.volunteerSelectionMajors.map((major) => (
                      <span className="injaz-lbl-detail" key={major}>
                        {major}
                      </span>
                    ))}
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.MinimumYearsOfExperience")}: </span>
                  <span>{program?.volunteerSelectionCriteria_MinimumYearsOfExperience}</span>
                </div>
                <div>
                  <span className="injaz-program-label">{t("ProgramManagement.ExperienceDomains")}: </span>
                  {program?.volunteerSelectionExperienceDomains &&
                    program?.volunteerSelectionExperienceDomains.length > 0 &&
                    program?.volunteerSelectionExperienceDomains.map((domain) => (
                      <span className="injaz-lbl-detail" key={domain}>
                        {domain}
                      </span>
                    ))}
                </div>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
        {userStore.isLoggedIn && (
          <Grid.Row>
            <Grid.Column>
              <Segment color="blue" className="program-details-segment">
                <Header as="h3">{t("Common.Courses")}</Header>
                <ProgramCourses programId={programId} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <Sidebar className="AddEditSessionSidebar" animation="overlay" direction={commonStore.lang === "ar" ? "left" : "right"} width="very wide" visible={openImageMenu}>
        <ChangeProgramImage
          onClose={() => {
            setOpenImageMenu(false);
            loadProgram();
          }}
          programId={program?.id!}
        />
      </Sidebar>
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
