import { stat } from "fs/promises";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dimmer, Loader, Segment, Table } from "semantic-ui-react";
import agent from "../../api/agent";
import TakeAttendanceDto from "../../models/takeAttendanceDto";

export interface Props {
  onClose: () => void;
  sessionId: number;
}

export default function TakeAttendance({ onClose, sessionId }: Props) {
  const { t } = useTranslation("common");
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [logs, setLogs] = useState<TakeAttendanceDto[]>([]);

  const handleError = (error: any) => console.log(error);

  useEffect(() => {
    setInProgress(true);
    agent.Sessions.initializeAttendance(sessionId)
      .then((result) => {
        setLogs(result);
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [sessionId]);

  const changeStatus = (studentId: number, status: boolean | undefined) => {
    setLogs((current) =>
      current.map((obj) => {
        if (obj.studentId === studentId) return { ...obj, status: status! };
        return obj;
      })
    );
  };

  const saveAttendance = () => {
    setInProgress(true);
    agent.Sessions.saveAttendance(sessionId, logs)
      .then((result) => {
        onClose();
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  var content = (
    <div className="ui form session-select">
      <div className="session-select-btns">
        <Button
          className="attendance_btn"
          color="blue"
          content={t("Common.Save")}
          onClick={() => saveAttendance()}
        />
        <Button
          className="attendance_btn"
          color="blue"
          content={t("Common.Cancel")}
          onClick={() => onClose()}
        />
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("UserManagement.FirstName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.LastName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
            <Table.HeaderCell>{`${t("CourseManagement.Present")} (${logs == null || logs.length === 0 ?"-": logs.filter(l=>l.status === true).length})`} </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {logs &&
            logs.length > 0 &&
            logs.map((log) => (
              <>
                <Table.Row key={log.studentId}>
                  <Table.Cell>{log.studentFirstName}</Table.Cell>
                  <Table.Cell>{log.studentLastName}</Table.Cell>
                  <Table.Cell>{log.studentEmail}</Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      toggle
                      checked={log.status}
                      onChange={(e, d) => {
                        changeStatus(log.studentId, d.checked);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </>
            ))}
        </Table.Body>
      </Table>
    </div>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
