import React from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

export default function Login() {
  const { t } = useTranslation("common");

  function signInClickHandler(instance: IPublicClientApplication) {
    instance.loginRedirect();
  }

  function signOutClickHandler(instance: IPublicClientApplication) {
    instance.logoutRedirect({postLogoutRedirectUri : "/"} );
  }

  function SignInButton() {
    const { instance } = useMsal();
    return (
      <button onClick={() => signInClickHandler(instance)}>
        {t("Navigation.TopNavigationMenu.SignIn")}
      </button>
    );
  }

  function SignOutButton() {
    const { instance } = useMsal();
    return (
      <button className="ui basic inverted button sidenav-btn" onClick={() => signOutClickHandler(instance)}>
        {t("Navigation.TopNavigationMenu.SignOut")}
      </button>
    );
  }

  function WelcomeUser() {
    const { accounts } = useMsal();
    const username = accounts[0].username;
    return (
      <div>
        <Header as="h3" className="sidebar-welcome"> {t("Common.Welcome")}</Header>
        <p>{username}</p>
        <SignOutButton />
      </div>
    );
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <SignInButton />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <WelcomeUser />
      </AuthenticatedTemplate>
    </>
  );
}
