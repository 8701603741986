import React from "react";
import { ProgramSummaryDto } from "../../models/programSummaryDto";
import { Table, Message, Segment, Loader, Dimmer, Pagination } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";
import { NavLink } from "react-router-dom";

export interface ProgramsListProps {
  programs: ProgramSummaryDto[] | undefined;
  loadingInProgress: boolean;
  currentPage: number | string | undefined;
  pageSize: number;
  totalItemsCount: number;
  blockActions: boolean;
  onPageChanged: (index: number | string | undefined) => void;
}

export default function ProgramsList({
  programs,
  loadingInProgress,
  blockActions,
  onPageChanged,
  totalItemsCount,
  currentPage,
  pageSize,
}: ProgramsListProps) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const emptyList = (
    <Segment className="injaz-empty-list-msg">
      <Message className="empty-list-msg" header={t("ProgramManagement.NoProgramsAdded")} />
    </Segment>
  );
  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalItemsCount / pageSize)}
      onPageChange={(e, d) => onPageChanged(d.activePage)}
    />
  );

  const list = (
    <Segment className="injaz-manage-segment">
      <Table>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell></Table.HeaderCell> */}
            <Table.HeaderCell>{t("ProgramManagement.ProgramConcatenatedName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.ProgramName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.DonorName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.ProjectName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.AcademicYear")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.Semester")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.NumberofSessions")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.Numberofprogramhours")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.ThematicArea")}</Table.HeaderCell>
            <Table.HeaderCell>{t("ProgramManagement.Version")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {programs &&
            programs.map((program) => (
              <Table.Row key={program.id}>
                {/*<Table.Cell><Checkbox /></Table.Cell>*/}
                <Table.Cell>
                  <NavLink to={`/programdetails/${program.id}`}>
                    {program.programConcatenatedName}
                  </NavLink>
                </Table.Cell>
                <Table.Cell>{program.name}</Table.Cell>
                <Table.Cell>{program.donorName}</Table.Cell>
                <Table.Cell>{program.projectName}</Table.Cell>
                <Table.Cell>{program.academicYear}</Table.Cell>
                <Table.Cell>
                  {lookupsStore.getSemsterValue(program.semester, commonStore.lang)}
                </Table.Cell>
                <Table.Cell>{program.numberOfsessions}</Table.Cell>
                <Table.Cell>{program.numberOfProgramHours}</Table.Cell>
                <Table.Cell>
                  {lookupsStore.getThematicAreaName(program.thematicArea, commonStore.lang)}
                </Table.Cell>
                <Table.Cell>{program.version}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalItemsCount > 10 && gridPaging}
    </Segment>
  );
  return (
    <>
      {loadingInProgress && loader}
      {!loadingInProgress && programs != null && programs.length > 0 && list}
      {!loadingInProgress && (programs == null || programs.length === 0) && emptyList}
    </>
  );
}
