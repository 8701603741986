import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container } from "semantic-ui-react";
import agent from "../../api/agent";
import ImageUploadDropZone from "../../common/ImageUploadDropZone";
import { toast } from "react-toastify";

export interface Props {
  onClose: () => void;
  programId: number;
}

export default function ChangeProgramImage({ programId, onClose }: Props) {
  const { t } = useTranslation("common");
  const [file,setFile] = useState<File>();
  const [isSaving,setIsSaving] = useState<boolean>(false);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const updateImage = () => {
    setIsSaving(true);
    if(file)
      agent.Programs.changeImage(programId,file)
      .then(result=>{
        toast.success(t("ProgramManagement.ImageUpdatedSuccessfully"))
        onClose();
      })
      .catch(error=>handleError(error))
      .finally(()=>setIsSaving(false));
    
  };

  return (
    <>
      <Container>
        <ImageUploadDropZone fileUploaded={(f)=>setFile(f)} />
        <Button content={t("Common.Save")} loading={isSaving} onClick={updateImage} disabled={file == undefined || file == null} />
        {!isSaving && <Button content={t("Common.Cancel")} onClick={() => onClose()} />}
      </Container>
    </>
  );
}
