import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { Table } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { useStore } from "../../stores/store";

interface Params {
  programId: string;
}

export default function ProgramCourses({ programId }: Params) {
  const { commonStore, lookupsStore } = useStore();
  const { t } = useTranslation("common");
  const [courses, setCourses] = useState<CourseSummaryDto[]>([]);

  useEffect(() => {
    agent.Programs.courses(programId)
      .then((result) => {
        setCourses(result);
      })
      .catch(() => toast.error("Failed to load related courses"));
  }, []);

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.Name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Common.Institution")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.StartDate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.EndDate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.NumberOfSessions")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.NumberOfSessionsTaken")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.Time")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.DeliveryMethod")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {courses &&
            courses.length > 0 &&
            courses.map((course, index) => (
              <Table.Row key={course.id}>
                <Table.Cell>{course.id}</Table.Cell>
                <Table.Cell>
                  <NavLink to={`/coursedetails/${course.id}`}>{course.name}</NavLink>
                </Table.Cell>
                <Table.Cell>{course.institutionName}</Table.Cell>
                <Table.Cell>{course.startDate}</Table.Cell>
                <Table.Cell>{course.endDate}</Table.Cell>
                <Table.Cell>{course.numberOfSessions}</Table.Cell>
                <Table.Cell>{course.numberOfSessionsTaken}</Table.Cell>
                <Table.Cell>{lookupsStore.getCourseTimeValue(course.courseTime, commonStore.lang)}</Table.Cell>
                <Table.Cell>{course.inPerson === true ? t("Common.InPerson") : t("Common.Online")}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
