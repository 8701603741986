import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button, Form, Segment, Header, Grid, Loader, Dimmer } from "semantic-ui-react";
import { AddTeacherDto } from "../../../models/Users/addTeacherDto";
import * as Yup from "yup";
import agent from "../../../api/agent";
import CustomTextInput from "../../../common/forms/CustomTextInput";
import { LookupDto } from "../../../models/lookupDto";
import CustomSelectInput from "../../../common/forms/CustomSelectInput";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../stores/store";
import CustomCheckboxInput from "../../../common/forms/CustomCheckboxInput";
import { toast } from "react-toastify";

interface Params {
  userId: string | undefined;
}

export default function AddEditTeacher() {
  const { userId } = useParams<Params>();
  const { t } = useTranslation("common");
  const history = useHistory();
  const { lookupsStore, commonStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [schools, setSchools] = useState<LookupDto[]>([]);
  const [maleFemaleValues, setMaleFemaleValues] = useState<{ text: string; value: number }[]>([]);
  const [user, setUser] = useState<AddTeacherDto>({
    userId: 0,
    firstName: "",
    lastName: "",
    middleName: null,
    email: null,
    phoneNumber: null,
    gender: null,
    schoolId: null,
    workedOnPreviousInjazProjects: false,
    haveComputerOnlineExperience: false,
    previouslyParticipatedInGoalProgram: false,
  });

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Lookups.schools()
      .then((result) => setSchools(result))
      .catch((error) => handleError(error));
  }, []);

  useEffect(() => {
    if (userId == undefined) return;
    setInProgress(true);
    agent.Users.editTeacher(userId)
      .then((result) => setUser(result))
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [userId]);

  useEffect(() => {
    let translatedGenders = lookupsStore.getGenders(commonStore.lang);
    setMaleFemaleValues(translatedGenders);
  }, [commonStore.lang]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Common.Required")),
    lastName: Yup.string().required(t("Common.Required")),
    email: Yup.string()
      .typeError(t("Common.InvalidEmailAddress"))
      .required(t("Common.Required"))
      .email(t("Common.InvalidEmailAddress")),
    schoolId: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
    gender: Yup.string().typeError(t("Common.Required")).required(t("Common.Required")),
  });

  const submitForm = (values: AddTeacherDto) => {
    setIsSaving(true);

    //if (values.haveComputerOnlineExperience != null)
    //  values.haveComputerOnlineExperience = values.haveComputerOnlineExperience === 2 ? false : true;

    //if (values.previouslyParticipatedInGoalProgram != null)
    //  values.previouslyParticipatedInGoalProgram =
    //    values.previouslyParticipatedInGoalProgram === 2 ? false : true;

    //if (values.workedOnPreviousInjazProjects != null)
    //  values.workedOnPreviousInjazProjects = values.workedOnPreviousInjazProjects === 2 ? false : true;

    setUser(values);
    if (!userId || userId == null) addNewUser(values);
    else updateUser(values);
  };

  const addNewUser = (values: AddTeacherDto) => {
    agent.Users.addTeacher(values)
      .then((result) => {
        toast.success(t("UserManagement.UserAdddedSuccessfully"));
        history.push("/manageusers/teacher");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const updateUser = (values: AddTeacherDto) => {
    agent.Users.updateTeacher(values)
      .then((result) => {
        toast.success(t("UserManagement.UserUpdatedSucessfully"));
        history.push("/manageusers/teacher");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <Header as="h2">{t("UserManagement.Teachers.NewTeacher")}</Header>
      <div>
        <div className="injaz-forms-cancel-btn-container">
          <Button
            className="injaz-forms-cancel-btn"
            basic
            color="blue"
            content={t("Common.Back")}
            as={NavLink}
            to={"/manageusers/teacher"}
          />
        </div>
        <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
          <Segment color="yellow" className="injaz-forms-segment-nopad">
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={user}
              onSubmit={(values) => submitForm(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-btn-container ui menu injaz-forms-btns-incl">
                    <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                  </div>
                  <Grid className="injaz-form-pad">
                    <Grid.Row>
                      <Grid.Column>
                      <Form.Group widths='equal'>
                        <CustomTextInput
                          label={t("UserManagement.FirstName") + " *"}
                          placeholder={t("UserManagement.FirstName")}
                          name="firstName"
                        />
                        <CustomTextInput
                          label={t("UserManagement.MiddleName")}
                          placeholder={t("UserManagement.MiddleName")}
                          name="middleName"
                        />
                        <CustomTextInput
                          label={t("UserManagement.LastName") + " *"}
                          placeholder={t("UserManagement.LastName")}
                          name="lastName"
                        />
                        <CustomSelectInput
                          label={t("UserManagement.Gender") + " *"}
                          options={maleFemaleValues}
                          name="gender"
                          placeholder={t("UserManagement.Gender")}
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <CustomTextInput
                          label={t("UserManagement.EmailAddress") + " *"}
                          placeholder={t("UserManagement.EmailAddress")}
                          name="email"
                        />
                        <CustomTextInput
                          label={t("UserManagement.PhoneNumber")}
                          placeholder={t("UserManagement.PhoneNumber")}
                          name="phoneNumber"
                        />
                        <CustomSelectInput
                          label={t("UserManagement.Teachers.SchoolName") + " *"}
                          options={schools}
                          name="schoolId"
                          placeholder={t("UserManagement.Teachers.SchoolName")}
                        />
                      </Form.Group>
                      <Form.Group>
                        
                        <CustomCheckboxInput
                          name="workedOnPreviousInjazProjects"
                          type="checkbox"
                          label={t("UserManagement.Teachers.PreviousInjazTeacher")}
                        />
                        <CustomCheckboxInput
                          name="haveComputerOnlineExperience"
                          type="checkbox"
                          label={t("UserManagement.Teachers.RemoteTeachingExperience")}
                        />
                        <CustomCheckboxInput
                          name="previouslyParticipatedInGoalProgram"
                          type="checkbox"
                          label={t("UserManagement.Teachers.GoalProgramPreviousParticipation")}
                        />
                      </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Segment>
        </Segment.Group>
      </div>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
