import "./App.css";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import SideNavMenu from "./Components/Navigation/SideNavMenu";
import AdminDashboard from "./Components/Dashboards/AdminDashboard";
import { Button, Container, Icon, Menu, Sidebar, Image } from "semantic-ui-react";
import InjazHome from "./Components/PublicPages/Injaz/InjazHome";
import AboutInjaz from "./Components/PublicPages/Injaz/AboutInjaz";
import InjazOfferedCourses from "./Components/PublicPages/Injaz/InjazOfferedCourses";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useStore } from "./stores/store";
import agent from "./api/agent";
import ManagePrograms from "./Components/ProgramManagement/ManagePrograms";
import ManageUsers from "./Components/UserManagement/ManageUsers";
import ManageCourses from "./Components/CourseManagement/ManageCourses";
import UnauthorizedAcess from "./common/UnauthorizedAccess";
import LoadingComponent from "./Layouts/LoadingComponent";
import { useTranslation } from "react-i18next";
import TopNavBar from "./Components/PublicPages/Injaz/TopNavBar";
import AddEditProgram from "./Components/ProgramManagement/AddEditProgram";
import StudentProfile from "./Components/UserManagement/Students/StudentProfile";
import AddEditStudent from "./Components/UserManagement/Students/AddEditStudent";
import TeacherProfile from "./Components/UserManagement/Teachers/TeacherProfile";
import AddEditTeacher from "./Components/UserManagement/Teachers/AddEditTeacher";
import AdministartorProfile from "./Components/UserManagement/Administrators/AdministartorProfile";
import AddEditAdministrator from "./Components/UserManagement/Administrators/AddEditAdministrator";
import VolunteerProfile from "./Components/UserManagement/Volunteers/VolunteerProfile";
import AddEditVolunteer from "./Components/UserManagement/Volunteers/AddEditVolunteer";
import AddEditCourse from "./Components/CourseManagement/AddEditCourse";
import MyCourses from "./Components/CourseManagement/MyCourses";
import ViewProgramDetails from "./Components/ProgramManagement/ViewProgramDetails";
import ViewCourseDetails from "./Components/CourseManagement/ViewCourseDetails";
import AddEditEmployee from "./Components/UserManagement/Employees/AddEditEmplyee";
import EmployeeProfile from "./Components/UserManagement/Employees/EmployeeProfile";
import InjazNews from "./Components/PublicPages/Injaz/InjazNews";
import { ToastContainer } from "react-toastify";
import CourseProgramInformationReport from "./Components/Reports/CourseProgramInformationReport";
import InstitutionInformationReport from "./Components/Reports/InstitutionInformationReport";
import SemesterReport from "./Components/Reports/SemesterReport";
import ReportsHub from "./Components/Reports/ReportsHub";
import VolunteersReport from "./Components/Reports/VolunteersReport";
import PageNotFound from "./Components/Navigation/PageNotFound";
import ManageInstitutions from "./Components/Institutions/ManageInstitutions";
import ViewInstitutionDetails from "./Components/Institutions/ViewInstitutionDetails";
import AddEditInstitution from "./Components/Institutions/AddEditInstitution";
import ImportStudents from "./Components/UserManagement/Students/ImportStudents";
import DownloadStudentCertificate from "./Components/CourseManagement/DownloadStudentCertificate";
import DownloadCourseCertificates from "./Components/CourseManagement/DownloadCourseCertificates";
import ManageMajors from "./Components/Settings/manageMajors";
import ManageDonors from "./Components/Settings/manageDonors";
import ImportStudentMemberships from "./Components/CourseManagement/ImportStudentMemberships";
import AddEditCourseManager from "./Components/UserManagement/CourseManagers/AddEditCourseManager";
import ManageCountries from "./Components/Settings/manageCountries";

export default observer(function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const { userStore, commonStore } = useStore();
  const { i18n } = useTranslation("common");
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    userStore.login();
  }

  //set MSAL and account instance after user login to be used by axios
  const { instance, accounts } = useMsal();
  agent.setMsalData(instance, accounts[0]);
  useEffect(() => {
    if (!commonStore.lang || commonStore.lang == null) {
      commonStore.setLanguage(commonStore.defaultLang);
      i18n.changeLanguage(commonStore.defaultLang);
    } else {
      i18n.changeLanguage(commonStore.lang);
    }

    agent.Accounts.current()
      .then((result) => {
        if (result == null) {
          //Case user signed in using valid O365 but is not a member on the Adopt system
          //redirect to unauthorized page
          setIsAuthorized(false);
        } else {
          userStore.setUser(result);
          setIsAuthorized(true);
        }
      })
      .catch((error) => {
        setIsAuthorized(false);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }, []);

  const [menuOpened, setMenuOpened] = React.useState(false);

  const unauthorizedContent = <UnauthorizedAcess />;

  const appContent = (
    <>
      <ToastContainer position="bottom-right" hideProgressBar />
      <div className={"App injaz-main " + (commonStore.lang === "ar" ? "ar-content" : "")}>
        <Button className="sidebar-burger" icon floated={commonStore.lang === "ar" ? "right" : "left"} onClick={() => setMenuOpened(!menuOpened)}>
          <Icon name="bars" size="big" />
        </Button>

        <Sidebar
          className="injaz-sidebar"
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction={commonStore.lang === "ar" ? "right" : "left"}
          vertical
          visible={menuOpened}
          width="thin"
        >
          <Container className="sibebar-container">
            <Container>
              <a className="sidebar-logo" href="/">
                <Image src="/img/injaz-white.png" size="small" />
              </a>
              <Button className="navbarmenu-btn" icon floated="right" onClick={() => setMenuOpened(false)}>
                <Icon name="arrow left" />
              </Button>
            </Container>
            <Container className="sidebar-scroll-menu">
              <SideNavMenu onClose={() => setMenuOpened(false)} />
            </Container>
          </Container>
          <div className="sidebar-footer">
            <Image className="injaz-powered" src="/img/PoweredBy_LPADOPT.png" size="small" />
          </div>
        </Sidebar>
        <TopNavBar />
        <Container className="appContent injaz">
          <Switch>
            {/* Admin routes */}
            {userStore.isAdmin && <Route exact path="/addprogram/" component={AddEditProgram} />}
            {userStore.isAdmin && <Route exact path="/editprogram/:programId" component={AddEditProgram} />}
            {userStore.isAdmin && <Route exact path="/manageprograms" component={ManagePrograms} />}
            <Route exact path="/programdetails/:programId" component={ViewProgramDetails} />
            <Route exact path="/about" component={AboutInjaz} />
            <Route exact path="/news" component={InjazNews} />
            <Route exact path="/offeredcourses" component={InjazOfferedCourses} />
            <Route exact path="/dashboard" component={AdminDashboard} />
            <Route exact path="/manageusers/:userType" component={ManageUsers} />
            <Route exact path="/managecourses" component={ManageCourses} />
            <Route exact path="/mycourses" component={MyCourses} />
            <Route exact path="/course/:courseId/importstudents" component={ImportStudentMemberships} />
            <Route exact path="/coursedetails/:courseId" component={ViewCourseDetails} />
            <Route exact path="/volunteer/add" component={AddEditVolunteer} />
            <Route exact path="/addcourse" component={AddEditCourse} />
            <Route exact path="/editcourse/:courseId" component={AddEditCourse} />

            <Route exact path="/student/:userId/profile" component={StudentProfile} />
            <Route exact path="/student/:userId/edit" component={AddEditStudent} />
            <Route exact path="/student/add" component={AddEditStudent} />
            <Route exact path="/student/import/:institutionId" component={ImportStudents} />
            <Route exact path="/employee/:userId/profile" component={EmployeeProfile} />
            <Route exact path="/employee/:userId/edit" component={AddEditEmployee} />
            <Route exact path="/employee/add" component={AddEditEmployee} />
            <Route exact path="/coursemanager/add" component={AddEditCourseManager} />
            <Route exact path="/teacher/:userId/profile" component={TeacherProfile} />
            <Route exact path="/teacher/:userId/edit" component={AddEditTeacher} />
            <Route exact path="/teacher/add" component={AddEditTeacher} />
            <Route exact path="/admin/:userId/profile" component={AdministartorProfile} />
            <Route exact path="/admin/:userId/edit" component={AddEditAdministrator} />
            <Route exact path="/admin/add" component={AddEditAdministrator} />
            <Route exact path="/volunteer/:userId/profile" component={VolunteerProfile} />
            <Route exact path="/volunteer/:userId/edit" component={AddEditVolunteer} />
            <Route exact path="/volunteer/add" component={AddEditVolunteer} />
            <Route exact path="/reports" component={ReportsHub} />
            <Route exact path="/downloadstudentcertificate/:userId/:courseId" component={DownloadStudentCertificate} />
            <Route exact path="/downloadcoursecertificate/:courseId" component={DownloadCourseCertificates} />
            <Route exact path="/reports/courseprograminformation" component={CourseProgramInformationReport} />
            <Route exact path="/reports/InstitutionInformation" component={InstitutionInformationReport} />
            <Route exact path="/reports/semesterreport" component={SemesterReport} />
            <Route exact path="/reports/volunteersreport" component={VolunteersReport} />
            <Route exact path="/institutions/manage" component={ManageInstitutions} />
            <Route exact path="/institutions/add" component={AddEditInstitution} />
            <Route exact path="/institutions/:institutionId/edit" component={AddEditInstitution} />
            <Route exact path="/institutions/:institutionId/details" component={ViewInstitutionDetails} />
            <Route exact path="/settings/majors" component={ManageMajors} />
            <Route exact path="/settings/donors" component={ManageDonors} />
            <Route exact path="/settings/countries" component={ManageCountries} />
            <Route exact path="/notfound" component={PageNotFound} />
            <Route path="/" component={InjazHome} />
          </Switch>
        </Container>
      </div>
    </>
  );
  return (
    <>
      {fetchingData && <LoadingComponent content="Loading ..." />}
      {!fetchingData && (isAuthorized ? appContent : unauthorizedContent)}
    </>
  );
});
