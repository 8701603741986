import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Icon, Input } from "semantic-ui-react";
import agent from "../../api/agent";
import InstitutionDto from "../../models/Institutions/institutionDto";
import InstitutionsList from "./InstitutionsList";

export default function ManageInstitutions() {
  const { t } = useTranslation("common");
  const [institutions, setInstitutions] = useState<InstitutionDto[]>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number | string | undefined>(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedInstitution, setSelectedInstitution] = useState<InstitutionDto | null>(null);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const loadInstitutions = () => {
    setInProgress(true);
    agent.Institutions.list(filterQuery, currentPageIndex, pageSize)
      .then((result) => {
        setInstitutions(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  useEffect(() => {
    setInProgress(true);
    agent.Institutions.count(filterQuery)
      .then((result) => {
        setTotalCount(result);
        loadInstitutions();
      })
      .catch((error) => {
        handleError(error);
      });
  }, [currentPageIndex, filterQuery]);

  const searchInstitutions = () => {
    setCurrentPageIndex(1);
    setFilterQuery(searchText);
  };

  return (
    <>
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          <span>{t("Institutions.ManageInstitutions")}</span>
        </a>
      </nav>

      <h2>{t("Institutions.ManageInstitutions")}</h2>

      <div className="ui menu injaz-forms-btns">
        <div className="item flt-left">
          <Button basic color="blue" content={t("Institutions.NewInstitution")} as={NavLink} to={"/institutions/add"} />
          </div>
          <div className="item flt-left">
          <Button
            basic
            color="blue"
            content={t("Institutions.EditInstitution")}
            as={NavLink}
            to={`/institutions/${selectedInstitution?.id}/edit`}
            disabled={!selectedInstitution || selectedInstitution == null}
          />
        </div>
        <div className="right menu flt-right">
          <div className="item injaz-srch">
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              autoComplete="new-password"
              placeholder={t("Common.Search") + " ..."}
              icon={
                <Icon
                  name="delete"
                  link
                  onClick={() => {
                    setSearchText("");
                    setCurrentPageIndex(1);
                    setFilterQuery("");
                  }}
                />
              }
            />
            <Button
              onClick={() => {
                setCurrentPageIndex(1);
                searchInstitutions();
              }}
            >
              <Icon className="search" />
            </Button>
          </div>
        </div>
      </div>
      <InstitutionsList
        institutions={institutions}
        loadingInProgress={inProgress}
        currentPage={currentPageIndex}
        pageSize={pageSize}
        totalItemsCount={totalCount}
        blockActions={inProgress}
        onItemSelected={(i) => setSelectedInstitution(i)}
        onPageChanged={(pageIndex: any) => {
          setCurrentPageIndex(pageIndex);
        }}
      />
    </>
  );
}
