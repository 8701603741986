import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Segment, List } from "semantic-ui-react";
import InstitutionDto from "../../models/Institutions/institutionDto";
import { useStore } from "../../stores/store";

interface Props {
  institution: InstitutionDto | undefined;
}

export default function InstitutionInfo({ institution }: Props) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore, userStore } = useStore();

  return (
    <>
      <Grid columns={2} className="profile-info" divided stackable>
        <Grid.Row className="">
          <Grid.Column>
            <Header as="h3">{institution?.name}</Header>
            <List>
                <List.Item>
                  <List.Header>{t("Institutions.InstitutionType")}: </List.Header>
                  {institution?.institutionType &&
                  lookupsStore.getInstitutionsTypesValue(institution?.institutionType, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.Address")}:</List.Header>
                  {institution?.address}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.PhoneNumber")}:</List.Header>
                  {institution?.phoneNumber}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.ContactPerson")}:</List.Header>
                  {institution?.contactPerson}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.MinistryCoordinatorName")}:</List.Header>
                  {institution?.ministryCoordinatorName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.MinistryCoordinatorPhoneNumber")}:{" "}</List.Header>
                  {institution?.ministryCoordinatorPhoneNumber}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.Directorate")}:</List.Header>
                  {institution?.directorateName}
                </List.Item>
            </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Header>{t("Institutions.Governate")}:</List.Header>
                  {institution?.governateName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.Gender")}:</List.Header>
                  {institution?.genderType &&
                  lookupsStore.getGenderPreferenceValue(institution?.genderType, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.HasSyrianStudentsPeriod")}:</List.Header>
                  {institution?.hasSyrianStudentsPeriod}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.SchoolLeaderName")}:</List.Header>
                  {institution?.schoolLeaderName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.SchoolLeaderPhoneNumber")}:</List.Header>
                  {institution?.schoolLeaderPhoneNumber}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.SchoolPrincipalAssistantName")}:{" "}</List.Header>
                  {institution?.schoolPrincipalAssistantName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.SchoolPrincipalAssistantPhoneNumber")}:{" "}</List.Header>
                  {institution?.schoolPrincipalAssistantPhoneNumber}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Institutions.SchoolWorksWithDoubleShift")}:{" "}</List.Header>
                  {institution?.schoolWorksWithDoubleShift ? t("Common.Yes") : t("Common.No")}
                </List.Item>
              </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
