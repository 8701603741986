import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tab, Icon } from "semantic-ui-react";
import CourseAttendance from "./CourseAttendance";
import CourseInfo from "./CourseInfo";
import CourseSessions from "./CourseSessions";
import CourseMembers from "./CourseMembers";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";
import CourseSurveys from "./CourseSurveys";
import CourseCertificates from "./CourseCertificates";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";

interface ViewCourseDetailsParams {
  courseId: string;
}

export default function ViewCourseDetails() {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  let { courseId } = useParams<ViewCourseDetailsParams>();
  const { search } = useLocation();
  const [timeStamp, setTimeStamp] = useState<Date>(new Date());
  const [course, setCourse] = useState<CourseSummaryDto>();
  const inSetupMode = () => {
    return search != null && search.includes("setup");
  };

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Courses.details(courseId)
      .then((result) => {
        setCourse(result);
      })
      .catch((error) => handleError(error));
  }, [courseId, timeStamp]);

  const infoTab = {
    menuItem: t("CourseManagement.CourseInformation"),
    pane: {
      key: "Course Information",
      content: <CourseInfo courseId={courseId} course={course} />,
    },
  };

  const sessionsTab = {
    menuItem: t("CourseManagement.Sessions"),
    pane: {
      key: "Sessions",
      content: <CourseSessions courseId={courseId} sessionsUpdated={() => setTimeStamp(new Date())} />,
    },
  };

  const membersTab = {
    menuItem: t("CourseManagement.Members"),
    pane: {
      key: "Members",
      content: (
        <CourseMembers
          courseId={courseId}
          course={course}
          membersUpdated={() => setTimeStamp(new Date())}
        />
      ),
    },
  };

  const attendanceTab = {
    menuItem: t("CourseManagement.Attendance"),
    pane: {
      key: "Attendance",
      content: <CourseAttendance courseId={courseId} timeStamp={timeStamp} course={course} />,
    },
  };

  const surveysTab = {
    menuItem: t("CourseManagement.Surveys"),
    pane: {
      key: "Surveys",
      content: (
        <CourseSurveys
          courseId={courseId}
          course={course}
          timeStamp={timeStamp}
          surveysUpdated={() => setTimeStamp(new Date())}
        />
      ),
    },
  };

  const certificatesTab = {
    menuItem: t("CourseManagement.Certificates"),
    pane: {
      key: "Certificates",
      content: (
        <CourseCertificates
          courseId={courseId}
          course={course}
          timeStamp={timeStamp}
          coursePublished={() => setTimeStamp(new Date())}
        />
      ),
    },
  };

  let panes: any[] = [];
  if (userStore.isAdmin)
    panes = [infoTab, sessionsTab, membersTab, attendanceTab, surveysTab, certificatesTab];
  if (userStore.isVolunteer) panes = [infoTab, membersTab, attendanceTab, surveysTab, certificatesTab];
  if (userStore.isEmployee)
    panes = [infoTab, sessionsTab, membersTab, attendanceTab, surveysTab, certificatesTab];
  if (userStore.isTeacher) panes = [infoTab, membersTab, attendanceTab, surveysTab, certificatesTab];

  return (
    <>
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          <span>{t("CourseManagement.CourseDetails")}</span>
        </a>
      </nav>
      <h1>{t("CourseManagement.CourseDetails")}</h1>
      <Tab panes={panes} renderActiveOnly={false} defaultActiveIndex={inSetupMode() === true ? 1 : 0} />
    </>
  );
}
