import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

interface LoaderProps {
  content: string;
}

export default function LoadingComponent({ content }: LoaderProps) {
  return (
    <>
      <Dimmer active inverted>
        <Loader size="large">{content}</Loader>
      </Dimmer>
    </>
  );
}
