import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";

export default class LoginService {

    getAccessToken = async (instance: IPublicClientApplication, account: AccountInfo) => {
        const accessTokenRequest = {
            scopes: ["api://cdfc7308-2870-4fc7-b095-b166d0b6c615/access_as_user"],
            account: account,
        };

        try {
            let accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            let accessToken = accessTokenResponse.accessToken;
            return accessToken;
        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                instance
                    .acquireTokenPopup(accessTokenRequest)
                    .then(function (accessTokenResponse) {
                        // Acquire token interactive success
                        let accessToken = accessTokenResponse.accessToken;
                        return accessToken;
                    })
                    .catch(function (error) {
                        // Acquire token interactive failure
                        console.log(error);
                        return "";
                    });
            }
        }

    }
} 