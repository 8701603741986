import { toast } from "react-toastify";
import agent from "../../api/agent";
import { useTranslation } from "react-i18next";
import { UserSummary } from "../../models/Users/userSummary";
import { Button, Modal } from "semantic-ui-react";
import { useState } from "react";

interface Props {
  user: UserSummary | undefined;
  userType: string | undefined;
  onClose: (refresh: boolean) => void;
}

export default function DeleteUserConfirmation({ user, userType, onClose }: Props) {
  const { t } = useTranslation("common");
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const deleteUser = () => {
    if (!user || !userType) return;

    setDeleting(true);
    if (userType.toLowerCase() === "admin") {
      agent.Users.deleteAdmin(user.id)
        .catch((e) => handleError(e))
        .finally(() => {
          setDeleting(false);
          onClose(true);
        });
    } else if (userType.toLowerCase() === "employee") {
      agent.Users.deleteEmployee(user.id)
        .catch((e) => handleError(e))
        .finally(() => {
          setDeleting(false);
          onClose(true);
        });
    } else if (userType.toLowerCase() === "student") {
      agent.Users.deleteStudent(user.id)
        .catch((e) => handleError(e))
        .finally(() => {
          onClose(true);
        });
    } else if (userType.toLowerCase() === "teacher") {
      agent.Users.deleteTeacher(user.id)
        .catch((e) => handleError(e))
        .finally(() => {
          setDeleting(false);
          onClose(true);
        });
    } else if (userType.toLowerCase() === "volunteer") {
      agent.Users.deleteVolunteer(user.id)
        .catch((e) => handleError(e))
        .finally(() => {
          setDeleting(false);
          onClose(true);
        });
    }
  };

  return (
    <>
      <Modal className="t4-popup" open={true}>
        <Modal.Header></Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {t("UserManagement.ConfirmUserDeleteMessage")} {user?.displayName}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("Common.Confirm")} onClick={() => deleteUser()} loading={deleting} disabled={deleting} />
          <Button content={t("Common.Cancel")} onClick={() => onClose(false)} disabled={deleting} />
        </Modal.Actions>
      </Modal>
    </>
  );
}
