import React from "react"
import Slider from "react-slick";
import { Segment, Grid, Header, Image } from "semantic-ui-react";

export default function NewsSlider(){
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <Slider {...settings}>
          <div>
          <Segment className="injaz-home-news">
              <Grid stackable>
                <Grid.Column width={6}>
                  <Header as="h3">INJAZ and Learning Possibilities sign an agreement to deliver an award-winning technology to the MENA region</Header>
                  <div className="news-slider-text">
                  The partnership will offer schools and governments with solutions to help drive learning into the 21st Century, enabling educators to support learners in school and at home.
                  Amman, Jordan, June 1st 2022. INJAZ, a leading non-profit Jordanian organization, and Learning Possibilities, the leading global education-technology provider, are delighted to announce the launch of their exclusive partnership to bring award-winning technology solutions to the MENA region.                  </div>
                  <a href="/News"> More...</a>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Image src="/img/INJAZ LP Signing Agreement-June012022_0.jpg" wrapped ui={false} />
                </Grid.Column>
              </Grid>
            </Segment>
          </div>
          <div>
          <Segment className="injaz-home-news">
              <Grid stackable>
                <Grid.Column width={6}>
                  <Header as="h3">JoPACC honors INJAZ as the DFS Champion for 2022</Header>
                  <div>
                  In the second annual cycle of the Digital Champions Program launched by the Jordan 
                  Payments and Clearing Systems Company (JoPACC), JoPACC, represented by its CEO, Ms. Maha Al-Baho, 
                  hosted the team of INJAZ, represented by its CEO, Ms. Deema Bibi, to recognize them as the 2022 Digital Champion.
                  The Digital Champions Program aims to recognize and honor the individuals and institutions pioneering change 
                  across the financial sector who contribute to expanding the acceptance and use of digital financial services. 
                  The champions are nominated by stakeholders in the financial sector, after which an evaluation conducted by JoPACC’s 
                  team determined the candidate(s) chosen for that year.
                  </div>
                  <a href="/News"> More...</a>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Image src="/img/News_Article1_1.png" wrapped ui={false} />
                </Grid.Column>
              </Grid>
            </Segment>
          </div>
          
        </Slider>
      );
}