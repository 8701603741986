import { IPublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import React from "react";

function signInClickHandler(instance: IPublicClientApplication) {
  instance.loginRedirect();
}

function SignInButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance } = useMsal();
  return <button className="ui basic blue button navbar-btn" onClick={() => signInClickHandler(instance)}>Sign In</button>;
}

function WelcomeUser() {
  const { accounts } = useMsal();
  const username = accounts[0].username;
  return <p>Welcome, {username}</p>;
}
export default function PublicLogin() {
  return (
    <>
      <UnauthenticatedTemplate>
        <SignInButton />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <WelcomeUser />
      </AuthenticatedTemplate>
    </>
  );
}
