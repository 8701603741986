import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Header, Icon, Segment, Sidebar } from "semantic-ui-react";
import agent from "../../api/agent";
import CourseMemberDto from "../../models/Courses/courseMemberDto";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { useStore } from "../../stores/store";
import AddCourseMember from "./AddCourseMember";
import MembersList from "./MembersList";
import { NavLink } from "react-router-dom";

export interface Props {
  course: CourseSummaryDto | undefined;
  courseId: string;
  membersUpdated: () => void;
}

export default function CourseMembers({ course, courseId, membersUpdated }: Props) {
  const { userStore } = useStore();
  const { t } = useTranslation("common");
  const [openMenu, setOpenMenu] = useState(false);
  const [currentRole, setCurrentRole] = useState<string>("");
  const [students, setStudents] = useState<CourseMemberDto[]>([]);
  const [teachers, setTeachers] = useState<CourseMemberDto[]>([]);
  const [volunteers, setVolunteers] = useState<CourseMemberDto[]>([]);
  const [excludeMembers, setExcludeMembers] = useState<CourseMemberDto[]>([]);
  const [reload, setReload] = useState<Date>(new Date());

  const handleError = (error: any) => console.log(error);

  const loadStudents = () => {
    agent.Courses.students(courseId)
      .then((result) => {
        setStudents(result);
      })
      .catch((error) => handleError(error));
  };

  const loadTeachers = () => {
    agent.Courses.teachers(courseId)
      .then((result) => {
        setTeachers(result);
      })
      .catch((error) => handleError(error));
  };

  const loadVolunteers = () => {
    agent.Courses.volunteers(courseId)
      .then((result) => {
        setVolunteers(result);
      })
      .catch((error) => handleError(error));
  };

  const removeStudent = (userId: number) => {
    agent.Courses.removeStudent(courseId, userId)
      .then((result) => {
        loadStudents();
        membersUpdated();
      })
      .catch((error) => handleError(error));
  };

  const removeTeacher = (userId: number) => {
    agent.Courses.removeTeacher(courseId, userId)
      .then((result) => {
        loadTeachers();
      })
      .catch((error) => handleError(error));
  };

  const removeVolunteer = (userId: number) => {
    agent.Courses.removeVolunteer(courseId, userId)
      .then((result) => {
        loadVolunteers();
      })
      .catch((error) => handleError(error));
  };

  useEffect(() => {
    loadStudents();
    loadTeachers();
    loadVolunteers();
  }, [courseId]);

  return (
    <>
      <div>
        <Sidebar className="AddMemberSidebar" animation="overlay" direction="right" width="very wide" visible={openMenu}>
          <AddCourseMember
            role={currentRole}
            courseId={courseId}
            excludedMembers={excludeMembers}
            reload={reload}
            onClose={(refreshRole: string) => {
              setOpenMenu(false);
              if (refreshRole === "student") {
                loadStudents();
                membersUpdated();
              }
              if (refreshRole === "teacher") loadTeachers();
              if (refreshRole === "volunteer") loadVolunteers();
            }}
          />
        </Sidebar>
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <Header as="h2">{t("CourseManagement.Teachers")}</Header>
                {(userStore.isAdmin || userStore.isEmployee) && (
                  <Button
                    className="add-member-btn"
                    floated="right"
                    icon
                    labelPosition="right"
                    primary
                    size="small"
                    onClick={() => {
                      setReload(new Date());
                      setExcludeMembers(teachers);
                      setCurrentRole("teacher");
                      setOpenMenu(true);
                    }}
                  >
                    <Icon name="user" /> {t("CourseManagement.AddTeacher")}
                  </Button>
                )}
                <MembersList course={course} members={teachers} role="teacher" onDelete={(userId) => removeTeacher(userId)} />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header as="h2">{t("CourseManagement.Students")}</Header>
                {(userStore.isAdmin || userStore.isEmployee) && (
                  <>
                    <Button
                      className="add-member-btn"
                      floated="right"
                      icon
                      labelPosition="right"
                      primary
                      size="small"
                      disabled={course?.isPublished}
                      as={NavLink}
                      to={`/course/${courseId}/importstudents`}
                    >
                      <Icon name="download" /> Import
                    </Button>

                    <Button
                      className="add-member-btn"
                      floated="right"
                      icon
                      labelPosition="right"
                      primary
                      size="small"
                      disabled={course?.isPublished}
                      onClick={() => {
                        setReload(new Date());
                        setExcludeMembers(students);
                        setCurrentRole("student");
                        setOpenMenu(true);
                      }}
                    >
                      <Icon name="user" /> {t("CourseManagement.AddStudent")}
                    </Button>
                  </>
                )}
                <MembersList course={course} members={students} role="student" onDelete={(userId) => removeStudent(userId)} />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header as="h2">{t("CourseManagement.Volunteers")}</Header>
                {(userStore.isAdmin || userStore.isEmployee) && (
                  <Button
                    className="add-member-btn"
                    floated="right"
                    icon
                    labelPosition="right"
                    primary
                    size="small"
                    onClick={() => {
                      setReload(new Date());
                      setExcludeMembers(volunteers);
                      setCurrentRole("volunteer");
                      setOpenMenu(true);
                    }}
                  >
                    <Icon name="user" /> {t("CourseManagement.AddVolunteer")}
                  </Button>
                )}
                <MembersList course={course} members={volunteers} role="volunteer" onDelete={(userId) => removeVolunteer(userId)} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
}
