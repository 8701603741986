import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Dimmer, Loader, Message, Pagination, Segment, Table } from "semantic-ui-react";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { useStore } from "../../stores/store";

export interface CourseListProps {
  courses: CourseSummaryDto[] | undefined;
  loadingInProgress: boolean;
  currentPage: number | string | undefined;
  pageSize: number;
  totalItemsCount: number;
  blockActions: boolean;
  onPageChanged: (index: number | string | undefined) => void;
}

export default function CourseList({
  courses,
  loadingInProgress,
  blockActions,
  onPageChanged,
  totalItemsCount,
  currentPage,
  pageSize,
}: CourseListProps) {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const emptyList = (
    <Segment className="injaz-empty-list-msg">
      <Message className="empty-list-msg" header={t("CourseManagement.NoCourses")} />
    </Segment>
  );

  const gridPaging = (
    <Pagination
      disabled={blockActions}
      boundaryRange={0}
      defaultActivePage={currentPage}
      totalPages={Math.ceil(totalItemsCount / pageSize)}
      onPageChange={(e, d) => onPageChanged(d.activePage)}
    />
  );

  const list = (
    <Segment className="injaz-manage-segment">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.Name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Common.Institution")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.StartDate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.EndDate")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.NumberOfSessions")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.NumberOfSessionsTaken")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.Time")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.DeliveryMethod")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {courses &&
            courses.map((course) => (
              <Table.Row key={course.id}>
                {/*<Table.Cell><Checkbox /></Table.Cell>*/}
                <Table.Cell>{course.id}</Table.Cell>
                <Table.Cell>
                  <NavLink to={`/coursedetails/${course.id}`}>{course.name}</NavLink>
                </Table.Cell>
                <Table.Cell>{course.institutionName}</Table.Cell>
                <Table.Cell>{course.startDate}</Table.Cell>
                <Table.Cell>{course.endDate}</Table.Cell>
                <Table.Cell>{course.numberOfSessions}</Table.Cell>
                <Table.Cell>{course.numberOfSessionsTaken}</Table.Cell>
                <Table.Cell>
                  {lookupsStore.getCourseTimeValue(course.courseTime, commonStore.lang)}
                </Table.Cell>
                <Table.Cell>
                  {course.inPerson === true ? t("Common.InPerson") : t("Common.Online")}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {totalItemsCount > 10 && gridPaging}
    </Segment>
  );

  return (
    <>
      {loadingInProgress && loader}
      {!loadingInProgress && courses != null && courses.length > 0 && list}
      {!loadingInProgress && (courses == null || courses.length === 0) && emptyList}
    </>
  );
}
