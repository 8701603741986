import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header, Segment, Grid, Image, Tab } from "semantic-ui-react";
import agent from "../../../api/agent";
import CourseSummaryDto from "../../../models/Courses/courseSummaryDto";
import { useStore } from "../../../stores/store";
import { toast } from "react-toastify";

export interface Props {
  teacherId: number;
}

export default function TeacherCourses({ teacherId }: Props) {
  const { userStore } = useStore();
  const { t } = useTranslation("common");
  const [courses, setCourses] = useState<CourseSummaryDto[]>([]);
  const [completedCourses, setCompletedCourses] = useState<CourseSummaryDto[]>([]);

  const handleError = () => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Courses.teacherCourses(teacherId)
      .then((result) => setCourses(result))
      .catch((error) => handleError());

    agent.Courses.teacherCompletedCourses(teacherId)
      .then((result) => setCompletedCourses(result))
      .catch((error) => handleError());
  }, [teacherId]);

  const currentCourses = (
    <>
      {courses &&
        courses.length > 0 &&
        courses.map((course, index) => (
          <Segment color="yellow" key={index}>
            <Grid className="injaz-mycourses" divided="vertically" padded>
              <Grid.Row>
                {/*<Grid.Column width={3}>
                  <Image
                    size="small"
                    src={course.imageUrl == null ? "/img/program-1.png" : course.imageUrl}
                  />
                </Grid.Column>
                */}
                <Grid.Column width={13}>
                  <Header as="h3">
                    <a>{course.name}</a>
                  </Header>
                  <div className="injaz-lbl injaz-course-startenddate">
                    <span className="injaz-lbl injaz-course-start">{t("Common.From")} </span>
                    <span>{course.startDate}</span>
                    <span className="injaz-lbl injaz-course-end">{t("Common.To")} </span>
                    <span>{course.endDate}</span>
                  </div>
                  {/*<div className="injaz-lbl">
                    <span className="injaz-lbl">{t("CourseManagement.NumberOfSessions")}: </span>{" "}
                    {course.numberOfSessions}
                    </div>*/}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
    </>
  );

  const completedCoursesList = (
    <>
      {completedCourses &&
        completedCourses.length > 0 &&
        completedCourses.map((course, index) => (
          <Segment color="yellow" key={index}>
            <Grid className="injaz-mycourses" divided="vertically" padded>
              <Grid.Row>
                {/*<Grid.Column width={3}>
                  <Image
                    size="small"
                    src={course.imageUrl == null ? "/img/program-1.png" : course.imageUrl}
                  />
                </Grid.Column>*/}
                <Grid.Column width={13}>
                  <Header as="h3">
                    <a>{course.name}</a>
                  </Header>
                  <div className="injaz-lbl injaz-course-startenddate">
                    <span className="injaz-lbl injaz-course-start">{t("Common.From")} </span>
                    <span>{course.startDate}</span>
                    <span className="injaz-lbl injaz-course-end">{t("Common.To")} </span>
                    <span>{course.endDate}</span>
                  </div>
                  {/*<div className="injaz-lbl">
                    <span className="injaz-lbl">{t("CourseManagement.NumberOfSessions")}: </span>{" "}
                    {course.numberOfSessions}
                  </div>*/}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
    </>
  );

  var tabs = [
    {
      menuItem: t("CourseManagement.CurrentCourses"),
      pane: {
        key: "CurrentCourses",
        content: currentCourses,
      },
    },
    {
      menuItem: t("CourseManagement.CompletedCourses"),
      pane: {
        key: "CompletedCourses",
        content: completedCoursesList,
      },
    },
  ];

  return (
    <>
      {userStore.user?.id !== teacherId && (
        <Header as="h2">{t("UserManagement.CoursesTeacherisTeaching")}</Header>
      )}
      {userStore.user?.id === teacherId && (
        <Header as="h2">{t("UserManagement.MyTeachingCourses")}</Header>
      )}
      <Tab panes={tabs} renderActiveOnly={false} />
    </>
  );
}
