import React from "react";
import { Segment, Container, Header } from "semantic-ui-react";
import TopNavBar from "./TopNavBar";
export default function AboutInjaz() {
  return (
    <>
      <h1>About Injaz</h1>
      <Segment className="full-injaz-news" color="blue">
        <Header as="h2">INJAZ Company Brief</Header>
    <p>
    INJAZ was founded in 1999 as a project under (Save the Children Organization) programs. In 2001, INJAZ became an independent, nonprofit Jordanian organization (INJAZ is a private shareholding company that does not aim to achieve profit with a capital of 50,000 Jordanian dinars, registered under the Private Shareholding Companies Register under No. (101)). Building on its mission to inspire and prepare youth to become productive members in their society and accelerate the development of the national economy through contributing to the advancement of the education and entrepreneurship ecosystems, INJAZ has established itself over the past 22 years as a leading solution provider linking the public, private, and civil society sectors to bridge the skills gap between the educational system and the changing needs of the labor market.
    </p>
    <p>
    INJAZ’s portfolio of programs addresses the wide range of needs of young Jordanians and complements the education and training provided by traditional educational institutions with programs that develop vital soft and hard skills (Life Skills, Financial Education, Entrepreneurship and Business Skills, Digital Skills, and Social Leadership) necessary to become competent entrepreneurs and/or employees in the private sector. Over the years, INJAZ has developed its own programs in consultation with local and regional experts and collaborated with international development organizations to bring new, high-impact programs to Jordan and adapt them to local sensibilities. INJAZ has been consistently successful in achieving high rates of female participation in its programs – female students make up more than 60 percent of beneficiaries at the school and university levels each year, as well as student teams for project-based programs are just as often led by young women as by young men.
    </p>
    <p>
    INJAZ is strongly positioned in Jordan. It has cooperation agreements with key ministries, including the Ministry of Education (MOE), the Ministry of Planning and International Cooperation (MOPIC), the Ministry of Social Development (MOSD), the King Abdullah II Fund for Development (KAFD), and the Vocational Training Center (VTC). Furthermore, INJAZ is distinguished by its robust network of private sector partners who provide critical insight into employer perspectives which guides the demand-driven design of INJAZ programs. INJAZ also strategically partnered with the Central Bank of Jordan (CBJ) and the Ministry of Education (MoE) to mainstream the Financial Education Program (FEP) in all schools across the Kingdom.
    </p>
    <p>
    The expansive operational capacity of INJAZ today allows it to reach over 800,000 youth a year with its career-oriented capacity building programs; to date, it has benefited almost 4,000,000 youth since its establishment. Last year, INJAZ implemented programs in all 12 governorates of Jordan working alongside and within all schools from the 7th grade to the 12th grade, 46 universities and colleges, and a range of social institutions, including vocational training centers, centers for youth with disabilities, youth centers and community centers. INJAZ implements its various programs in partnership with the private and public sectors and the civil society sector and through more than 2,000 volunteers annually who come with competence and experience. In addition to its Amman headquarters, INJAZ maintains permanent field offices in Irbid, Zarqa, Karak, Aqaba, Tafilah, and Ma’an, where local staff and close relationships with community stakeholders strengthen INJAZ’s knowledge of locally effective program design and implementation strategies.
    </p>
    <p>
    In year 2018, INJAZ established mySTARTUP incubator that builds on INJAZ’ resources, expertise and network to promote entrepreneurship and enable startups to become a driving force towards the enhanced and equal participation and economic development of youth and women. mySTARTUP’s mission is to advance Jordan’s entrepreneurship ecosystem by empowering youth and women entrepreneurs towards launching their scalable and socially responsible startups that will drive job creation and economic development. The current operational capacity of mySTARTUP allows it to incubate 100 startups a year.
    </p>
    <p>
    INJAZ has extensive experience and institutional knowledge in the areas of program design, implementation, and management. It adheres to the best of regional and international standards in program and project management in terms of stakeholder mapping, planning, procurement, reporting, and follow-up.
    </p>
    <p>
    It was also announced by UNICEF that INJAZ and its activities represent a model of best practice in integrating youth among 79 organizations working in the MENA region. INJAZ has also received national and regional awards for its efforts in building youth skills, specifically in relation to financial education, entrepreneurship, and social leadership. INJAZ is expanding its impact regionally and is increasingly being recognized as a regional hub of expertise for youth economic empowerment programs and a solution provider for pressing challenges facing youth, specifically youth unemployment.
    </p>
      </Segment>
      <Segment className="full-injaz-news-ar" color="red">
        <Header as="h2">لمحة عامة عن إنجاز</Header>
    <p>
    تأسّست إنجاز عام ١٩٩٩ كمشروع ضمن برامج مؤسسة إنقاذ الطفل (Save the Children) لتصبح عام ۲۰۰١ مؤسّسة أردنيّة غير ربحيّة (إنجاز هي شركة مساهمة خاصة لا تهدف الى تحقيق الربح برأس مال ٥۰,۰۰۰ دينار أردني، مسجلة في سجل الشركات المساهمة الخاصة تحت رقم) ١۰١)(. وانطلاقاً من رسالة مؤسّسة إنجاز والتي تهدف إلى تحفيز وإعداد الشّباب ليصبحوا أعضاءَ فاعلين في مجتمعهم ويساهموا في تنمية الاقتصاد الوطني من خلال المساهمة في تطوير منظومة التّعليم وريادة الأعمال، وبناءً على خبرة المؤسّسة القيّمة على مدار اثنتي وعشرين سنةً الماضية في تطوير أساليب التّعليم التّشاركي والتّفاعلي وتدريب المدرّبين، تقوم إنجاز بدور رئيس في تصميم البرامج وتنفيذها وذلك ضمن اتفاقيات استراتيجيّة مع القطاع العام والخاص وقطاع المجتمع المدني، بهدف تقليل الفجوة في المهارات بين مخرجات النّظام التعليمي والمتطلبات المتغيّرة في سوق العمل.
    </p>
    <p>
    تعمل إنجاز على تمكين الشّباب الأردني ومراعاة احتياجاتهم من خلال مجموعة واسعة من البرامج التي تُعنى بصقل المهارات الحياتيّة، والثّقافة الماليّة، ومهارات إدارة الأعمال والرّيادة، والمهارات الرّقميّة، والقيادة المجتمعيّة. وقد طوَّرت إنجاز على مرّ السنوات برامجها الخاصّة بالتّشاور مع الخبراء المحلّيّين والعالميين، وتعاونت مع منظمات تنمويّة دوليّة لتطوير البرامج الهادفة وعالية الأثر وتكييفها بما يتلاءم مع المتطلّبات المحليّة، كما واصلت إنجاز نجاحاتها في تحقيق نسب عالية من مشاركة الإناث في البرامج، حيث شكّلت الطالبات أكثر من (٦٠٪) من المستفيدين على نطاق المدارس والجامعات كل عام.
    </p>
    <p>
    أُبرمت إنجاز على مدى السّنوات الماضية اتفاقيّات تعاون مع الوزارات الخدميّة الرئيسة، بما فيها وزارة التّربية والتّعليم، ووزارة التّخطيط والتّعاون الدّولي، ووزارة التّنمية الاجتماعية، إضافة إلى مجموعة من المؤسّسات الوطنيّة كصندوق الملك عبد الله الثّاني للتّنمية ومؤسّسة التّدريب المهنيّ، علاوةً على تميّز إنجاز في شبكتها المتينة مع شركائها في القطاع الخاص، ممن أتاحوا لها رؤيةً بالغة الأهمية من منظور أصحاب العمل، الذين شاركوا في تصميم برامج إنجاز على النّحو الذي يلبي متطلبات سوق العمل. كما أبرمت إنجاز شراكة إستراتيجية مع البنك المركزي الأردني ووزارة التّربية والتّعليم لتعميم برنامج الثقافة المالية (FEP) في كافة مدارس المملكة.
    </p>
    <p>
    تسمح القدرة التّشغيليّة الواسعة لمؤسّسة إنجاز اليوم بالوصول إلى أكثر من ٨٠٠,٠٠٠ مستفيد سنوياً، بفضل برامج المؤسّسة المتعدّدة لبناء قدرات الشّباب التي استطاعت الوصول إلى حوالي ٤,۰۰۰,۰۰۰ مستفيد منذ انطلاقتها. وتُنفّذ إنجاز برامجها كل عام في كافة محافظات المملكة، من خلال عملها في كافة المدارس من الصف السابع وحتى الثاني عشر و٤٦ جامعة وكُليّة، ومجموعة من المؤسّسات الاجتماعية، بما فيها مراكز التّدريب المهنيّ، ومراكز الشّباب ذوي الإعاقة، والمراكز الشّبابية، ومراكز المجتمع المدنيّ. وتنفّذ المؤسسة مختلف برامجها بالشراكة مع القطاعين الخاص والعام وقطاع المجتمع المدنيّ ومن خلال أكثر من ۲,۰۰۰ متطوع سنوياً من ذوي الكفاءة والخبرة. تتّخذ إنجاز، بالإضافة إلى مقرّها الرئيسيّ في عمّان، مكاتب ميدانيّة في إربد، والزّرقاء، والكرك، والعقبة، ومعان، والطفيلة، بهدف الوصول إلى الشّباب في مختلف محافظات المملكة.
    </p>
    <p>
    في عام ۲۰۱۸ أسّست إنجاز حاضنة الأعمال mySTARTUP لتكون جزءًا من منظومة ريادة الأعمال في الأردن، ولتلبي احتياجات الرّياديّين لتأسيس وتطوير شركاتهم الرّياديّة النّاشئة. تعتبر حاضنة أعمال مؤسّسة إنجاز mySTARTUP من أهمّ المشاريع التي تعمل عليها المؤسّسة؛ لإيمانها بقدرة الشّباب الأردنيّ على الابتكار والإبداع والخروج بأفكار ومشاريع رياديّة قابلة للتّطبيق وقادرة على تقديم خدمات ومنتجات متطوّرة يحتاجها السّوق، حيث تبلغ الطّاقة الاستيعابيّة للحاضنة في العام ۱۰۰ شركة ناشئة، وقد حرصت مؤسّسة إنجاز أثناء تأسيس الحاضنة على الاطّلاع على مجموعة من التّجارب النّاجحة لحاضنات الأعمال في أكثر دول العالم تقدُّماً في هذا المجال للاستفادة من تلك التّجارب ونقل الخبرات والبرامج العالمية، وتعمل الحاضنة مع الرّياديّين الشّباب والشّابات من مختلف مناطقهم لتوفّر لهم احتياجاتهم الأساسيّة لكي يضعوا أنفسهم على المسار الصّحيح في رحلتهم بعالم الرّيادة والابتكار. تتمثّل مهمّة حاضنة الأعمال mySTARTUP في النّهوض بمنظومة ريادة الأعمال في الأردن من خلال تمكين الرّياديّين الشّباب والشّابات من إطلاق شركات ناشئة ذات مسؤوليّة مجتمعيّة وقابلة للتطّور والتّوسع والتي من شأنها خلق فرص عمل وتنمية الاقتصاد الوطني.
    </p>
    <p>
    تفتخر إنجاز بخبرتھا الواسعة ومعرفتھا المؤسّسیة في مجال تصمیم البرامج وتنفیذھا وإدارتھا، إلى جانب التزامھا بأفضل المعاییر الإقلیمیّة والدّولیّة في إدارة البرامج والمشاریع، حیث تتوافق ممارسات المُتابعة والتّقییم التي تُطبّقھا مع أفضل المعاییر الإقلیمیّة والدولیّة.
    </p>
    <p>
    هذا وقد أعلنت مُنظّمة الیونسیف بأن مؤسّسة إنجاز وأنشطتھا تمثّل النّموذج لأفضل الممارسات في إدماج الشّباب في منطقة الشّرق الأوسط وشمال أفریقیا من بین (۷۹) منظّمة یعملون في المنطقة. كما حازت المؤسّسة على جوائز وطنیّة وإقلیمیّة لجھودھا في مجال بناء مھارات الشّباب وتحدیدًا فيما یتعلق بالثّقافة المالیّة وریادة الأعمال والقیادة المجتمعیّة. وتقوم إنجاز بتوسيع نطاق أثرها على المستوى الإقليميّ، حيث يتزايد الاعتراف بها كمركز إقليميّ متخصّص ببرامج ُتمكّن الشّباب اقتصاديًا وتُزوّدهم بحلول مُبتكرة للتحديات التي تواجههم وخصوصًا مشكلة البطالة.
    </p>
      </Segment>
    </>
  );
}
