import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { useStore } from "../../../stores/store";
import ViewProgramDetails from "../../ProgramManagement/ViewProgramDetails";
import AboutInjaz from "./AboutInjaz";
import InjazHome from "./InjazHome";
import InjazNews from "./InjazNews";
import InjazOfferedCourses from "./InjazOfferedCourses";
import TopNavBar from "./TopNavBar";

export default function InjazPublic() {
  const { commonStore } = useStore();

  return (
    <>
      <div className="topnavbar-public">
        <TopNavBar />
      </div>
      <Container className={"injaz-main-public " + (commonStore.lang === "ar" ? "ar-content" : "")}>
        <Switch>
          <Route exact path="/about" component={AboutInjaz} />
          <Route exact path="/news" component={InjazNews} />
          <Route exact path="/offeredcourses" component={InjazOfferedCourses} />
          <Route exact path="/programdetails/:programId" component={ViewProgramDetails} />
          <Route path="/" component={InjazHome} />
        </Switch>
      </Container>
    </>
  );
}
