import React from "react";
import { Link } from "react-router-dom";
import { Button, Segment,Header,Icon } from "semantic-ui-react";

export default function PageNotFound(){
    return <>
    <Segment placeholder>
      <Header icon>
        <Icon name="search" />
        Oops - We've looked everywhere and could not find this
      </Header>
      <Segment.Inline>
        <Button as={Link} to="/" primary>
          Return to HomePage
        </Button>
      </Segment.Inline>
    </Segment>
  </>
}