import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Button, Segment, Header, Grid, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../stores/store";
import AddAdminDto from "../../../models/Users/addAdminDto";
import CustomTextInput from "../../../common/forms/CustomTextInput";
import CustomSelectInput from "../../../common/forms/CustomSelectInput";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface Params {
  userId: string | undefined;
}

export default function AddEditAdministrator() {
  const { userId } = useParams<Params>();
  const { t } = useTranslation("common");
  const history = useHistory();
  const { lookupsStore, commonStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [maleFemaleValues, setMaleFemaleValues] = useState<{ text: string; value: number }[]>([]);
  const [user, setUser] = useState<AddAdminDto>({} as AddAdminDto);

  const handleError = (error: any) => {
    toast.error(t("Common.GeneralErrorMessage"));
  };

  useEffect(() => {
    if (userId == undefined) {
      setUser({} as AddAdminDto);
      return;
    }
    agent.Users.editAdmin(userId)
      .then((result) => setUser(result))
      .catch((error) => handleError(error));
  }, [userId]);

  useEffect(() => {
    let translatedGenders = lookupsStore.getGenders(commonStore.lang);
    setMaleFemaleValues(translatedGenders);
  }, [commonStore.lang]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Common.Required")),
    lastName: Yup.string().required(t("Common.Required")),
    email: Yup.string()
      .typeError(t("Common.InvalidEmailAddress"))
      .required(t("Common.Required"))
      .email(t("Common.InvalidEmailAddress")),
    gender: Yup.string().required(t("Common.Required")),
  });

  const addNewUser = (values: AddAdminDto) => {
    agent.Users.addAdmin(values)
      .then((result) => {
        toast.success(t("UserManagement.UserAdddedSuccessfully"));
        history.push("/manageusers/admin");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const updateUser = (values: AddAdminDto) => {
    agent.Users.updateAdmin(values)
      .then((result) => {
        toast.success(t("UserManagement.UserUpdatedSucessfully"));
        history.push("/manageusers/admin");
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const submitForm = (values: AddAdminDto) => {
    setIsSaving(true);
    setUser(values);

    if (!userId || userId == null) addNewUser(values);
    else updateUser(values);
  };

  return (
    <>
      {(!userId || userId == null) && (
        <Header as="h2">{t("UserManagement.Administrators.NewAdmin")}</Header>
      )}
      {userId && userId != null && (
        <Header as="h2">{t("UserManagement.Administrators.EditAdmin")}</Header>
      )}
      <div>
        <div className="injaz-forms-cancel-btn-container">
          <Button
            className="injaz-forms-cancel-btn"
            basic
            color="blue"
            content={t("Common.Cancel")}
            as={NavLink}
            to={"/manageusers/admin"}
          />
        </div>
        <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
          <Segment color="yellow" className="injaz-forms-segment-nopad">
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={user}
              onSubmit={(values) => submitForm(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-btn-container ui menu injaz-forms-btns-incl">
                    <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                  </div>
                  <Grid className="injaz-form-pad">
                    <Grid.Row>
                    <Grid.Column>
                      <Form.Group widths='equal'>
                        <CustomTextInput
                          label={t("UserManagement.FirstName") + " *"}
                          placeholder={t("UserManagement.FirstName")}
                          name="firstName"
                        />
                        <CustomTextInput
                          label={t("UserManagement.MiddleName")}
                          placeholder={t("UserManagement.MiddleName")}
                          name="middleName"
                        />
                        <CustomTextInput
                          label={t("UserManagement.LastName") + " *"}
                          placeholder={t("UserManagement.LastName")}
                          name="lastName"
                        />
                        <CustomSelectInput
                          label={t("UserManagement.Gender") + " *"}
                          options={maleFemaleValues}
                          name="gender"
                          placeholder={t("UserManagement.Gender")}
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <CustomTextInput
                          label={t("UserManagement.EmailAddress") + " *"}
                          placeholder={t("UserManagement.EmailAddress")}
                          name="email"
                        />
                        <CustomTextInput
                          label={t("UserManagement.PhoneNumber")}
                          placeholder={t("UserManagement.PhoneNumber")}
                          name="phoneNumber"
                        />
                        <CustomTextInput
                          label={t("UserManagement.Address")}
                          placeholder={t("UserManagement.Address")}
                          name="address"
                        />
                      </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Segment>
        </Segment.Group>
      </div>
    </>
  );
}
