import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import App from "./App";
import InjazPublic from "./Components/PublicPages/Injaz/InjazPublic";
import { createBrowserHistory } from "history";
import { store, StoreContext } from "./stores/store";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

export const history = createBrowserHistory();

const config: Configuration = {
  auth: {
    //clientId: process.env.REACT_APP_CLIENTID!,
    clientId: "cdfc7308-2870-4fc7-b095-b166d0b6c615",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};
const publicClientApplication = new PublicClientApplication(config);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en", // language to use
  resources: {
    en: {
      common: common_en,
    },
    ar: {
      common: common_ar,
    },
  },
});

root.render(
  <>
    <StoreContext.Provider value={store}>
      <MsalProvider instance={publicClientApplication}>
        <I18nextProvider i18n={i18next}>
          <Router history={history}>
            <UnauthenticatedTemplate>
              <InjazPublic />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <App />
            </AuthenticatedTemplate>
          </Router>
        </I18nextProvider>
      </MsalProvider>
    </StoreContext.Provider>
  </>
);
