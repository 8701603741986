import React from "react";
import { Grid, Image, Card, Header, Button, Segment } from "semantic-ui-react";
import NewsSlider from "./NewsSlider";
import { NavLink } from "react-router-dom";
import LatestPrograms from "../../ProgramManagement/LatestPrograms";
import { useTranslation } from "react-i18next";

export default function InjazHome() {
  const { t } = useTranslation("common");
  /*var authService = new AuthService();
  const signIn = async () => {
    console.log("Signing in ...");
    await authService.login();
    console.log("logged in");
    console.log(authService.request.account);
  };

  const getCourses = async () => {
    await authService.getAccessToken();
    var token = authService.accessToken;

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.get("http://localhost:5099/courses", config).then((respose) => {
      console.log(respose);
    });
  };*/

  return (
    <>
      <Grid stackable>
        <Header className="home-header" as="h2">{t("PublicSite.LatestNews")}</Header>
        <Grid.Row className="injaz-public-row1">
          <Grid.Column className="injaz-public-row1-col1" width={11}>
          <NewsSlider/>
          </Grid.Column>
          <Grid.Column className="injaz-public-row1-col3" width={5}>
            <div className="home-clickable">
            <a href="/about">
            <Card fluid className="injaz-public-row1-col3-card1">
              <Image src="/img/card-img-3.png" wrapped ui={false} />
              <div className="card-body card-overlay">
                <h2 className="card-title">{t("PublicSite.AboutInjaz")}</h2>
                <p className="injaz card-text">
                {t("PublicSite.AboutInjazMessage")}
                </p>
              </div>
            </Card>
            </a>
            </div>
            <div>
            <a href="https://injaz.org.jo/volunteer/">
            <Card fluid className="injaz-public-row1-col3-card2">
              <Image src="/img/card-img-4.png" wrapped ui={false} />
              <div className="card-body card-overlay">
                <h2 className="card-title">{t("PublicSite.Volunteer")}</h2>
                <p className="injaz card-text">
                {t("PublicSite.VolunteerMessage")}
                </p>
              </div>
              </Card>
              </a>
              </div>
            {/*<Grid>
              <Grid.Row>
                <Card fluid>
              <Image className="inj-home-rt-img" src="/img/card-img-3.png" wrapped ui={false} />
              <Card.Content className="inj-home-rt-column firstcol">
                <Card.Header>{t("PublicSite.AboutInjaz")}</Card.Header>
                <Card.Description>
                  <p className="card-text">
                    {t("PublicSite.AboutInjazMessage")} 
                  </p>
                  <Button
                  basic
                  inverted
                  className="pointer-event more-btn"
                  content={t("Common.More")+"..."}
                  as={NavLink}
                  to="/about"
                />
                </Card.Description>
              </Card.Content>
            </Card>
              </Grid.Row>
              <Grid.Row>
                <Card fluid>
              <Image className="inj-home-rt-img" src="/img/card-img-4.png" wrapped ui={false} />
              <Card.Content className="inj-home-rt-column secondcol">
                <Card.Header>{t("PublicSite.Volunteer")}</Card.Header>
                <Card.Description>
                  <p className="card-text">
                  {t("PublicSite.VolunteerMessage")}
                  </p>
                  <a className="ui basic inverted button more-btn" role="button" href="https://injaz.org.jo/volunteer/" target="_blank">More...</a>
                </Card.Description>
              </Card.Content>
            </Card>
              </Grid.Row>
  </Grid>*/}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h2">{t("PublicSite.Programs")}</Header>
          </Grid.Column>
          <Grid.Column width={6}>
            {/*<Search
              className="injaz-home-search"
              placeholder={t("Common.Search")+"..."}
              floated="right"
              position="right"
            />*/}
          </Grid.Column>
        </Grid.Row>
        <LatestPrograms />
      </Grid>

      <div></div>
      <div></div>
    </>
  );
}
