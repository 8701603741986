import { makeAutoObservable, reaction } from "mobx";

export default class CommonStore {

    lang: string | null = window.localStorage.getItem("lang");
    defaultLang = "ar";
    appLoaded = false;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.lang,
            (lang) => {
                if (lang) window.localStorage.setItem("lang", lang);
                else window.localStorage.removeItem("lang");
            }
        );
    }

    setLanguage = (language: string | null | undefined) => {
        if (language === undefined)
            this.lang = null;
        else
            this.lang = language;
    };

    setAppLoaded = () => {
        this.appLoaded = true;
    };
}