import { useField } from "formik";
import React from "react";
import { Checkbox, Form, Input, Label } from "semantic-ui-react";

interface Props {
  name: string;
  type: string;
  label?: string;
}

export default function CustomCheckboxInput(props: Props) {
  const [field, meta, helpers] = useField({ name: props.name, type: "checkbox" });
  return (
    <>
      <Form.Field error={meta.touched && !!meta.error}>
        <label>
          <input {...field} type="checkbox" />
          <span className="text-sm">{props.label}</span>
        </label>
      </Form.Field>
      {/*<Form.Field error={meta.touched && !!meta.error}>
        <label>{props.label}</label>
        <Checkbox
          type="checkbox"
          //value=
          onChange={(e, d) => {
            console.log(d.checked);
            helpers.setValue(d.value);
          }}
          onBlur={() => helpers.setTouched(true)}
          checked={field.value || null}
        />
        {meta.touched && meta.error ? (
          <Label basic color="red">
            {meta.error}
          </Label>
        ) : null}
      </Form.Field>*/}
    </>
  );
}
