import React, { useState } from "react";
import Papa from "papaparse";
import { Button, Dimmer, Grid, Icon, Loader, Message, Segment, Table, List } from "semantic-ui-react";
import StudentImportItem from "../../../models/Users/Students/studentImportItem";
import { StudentCsvValidationResult } from "../../../models/Users/Students/studentCsvValidationResult";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

interface Params {
  institutionId: string;
}

export default function ImportStudents() {
  const { t } = useTranslation("common");
  const history = useHistory();
  let { institutionId } = useParams<Params>();
  const [loaderMessage, setLoaderMessage] = useState<string>(t("Common.Loading"));
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [file, setFile] = useState();
  const [students, setStudents] = useState<StudentImportItem[]>();
  const [validationResult, setValidationResult] = useState<StudentCsvValidationResult[]>();

  const downloadTemplate = () => {
    var link = document.createElement("a");
    link.download = "studenttemplate.csv";
    link.href = "/csvTemplates/studenttemplate.csv";
    link.click();
  };

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {
          setLoaderMessage(t("Institutions.ValidatingData"));
          setInProgress(true);
          let data = parseStudents(results.data);
          console.log(data);
          setStudents(data);
          agent.Users.validateStudentsCsv(data)
            .then((result) => {
              if (result && result.length > 0) {
                let faulty = result.filter((r) => r.errorsCount > 0);
                setValidationResult(faulty);
              }
            })
            .catch((error) => handleError(error))
            .finally(() => {
              setInProgress(false);
              setLoaderMessage(t("Common.Loading"));
            });
        },
      });
    }
  };

  const parseStudents = (data: any): StudentImportItem[] => {
    let result: StudentImportItem[] = [];
    if (data == null || data.length <= 1) return result;

    let invalidRowCount = 0;
    data.forEach((element: any, index: number) => {
      if (index === 0) return;
      if (element.length < 13) {
        invalidRowCount++;
        return;
      }
      let student: StudentImportItem = {
        firstName: element[0],
        middleName: element[1],
        lastName: element[2],
        fullArabicName: element[3],
        birthdate: element[4],
        email: element[5],
        phoneNumber: element[6],
        nationality: element[7],
        nationalId: element[8],
        gender: element[9],
        grade: element[10] == null || element[10] === "" ? 0 : element[10],
        section: element[11] == null || element[11] === "" ? 0 : element[11],
        previousInjazCompetition: element[12] && element[12].toLowerCase() === "true" ? true : false,
      };
      result.push(student);
    });

    if (invalidRowCount > 0) toast.warn(`${invalidRowCount} rows were not loaded due to missing fields.`);

    return result;
  };

  const startImport = () => {
    if (students == null) return;
    setLoaderMessage(t("Institutions.Importing"));
    setInProgress(true);
    agent.Users.importStudents(students, institutionId)
      .then((result) => {
        toast.success(t("Institutions.ImportCompleted"));
        history.push(`/institutions/${institutionId}/details?setup=1`);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setInProgress(false);
        setLoaderMessage(t("Common.Loading"));
      });
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{loaderMessage} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <h1>{t("Institutions.ImportStudents")}</h1>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <List ordered className="import-steps">
              <List.Item>
                <div className="li-order-number">Download the student template </div>
                <div className="li-order-number">
                  <Button color="blue" basic compact onClick={downloadTemplate}>
                    {t("Institutions.DownloadTemplate")}
                  </Button>
                </div>
              </List.Item>
              <List.Item>
                <div className="li-order-number">Complete all columns without changing the column headers.</div>
              </List.Item>
              <List.Item>
                <div className="li-order-number">Save the template to your device.</div>
              </List.Item>
              <List.Item>
                <div className="li-order-number">Select and upload your file.</div>
                <form>
                  <div className="li-order-number">
                    <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />
                    <Button
                      basic
                      color="blue"
                      disabled={file == null}
                      onClick={(e) => {
                        handleOnSubmit(e);
                      }}
                    >
                      {t("Institutions.Load")}
                    </Button>
                  </div>
                </form>
              </List.Item>
              <List.Item>
                <div className="li-order-number">
                  Select start importing
                  {validationResult != null && validationResult.length > 0 && validationResult.filter((r) => r.errorsCount > 0).length > 0 && (
                    <Message className="import-error-msg" error content={t("Institutions.CsvValidationMessage")} />
                  )}
                </div>
                <div className="li-order-number">
                  <Button
                    color="blue"
                    basic
                    content={t("Institutions.StartImporting")}
                    onClick={() => {
                      startImport();
                    }}
                    disabled={validationResult == null || validationResult.length > 0 || validationResult.filter((r) => r.errorsCount > 0).length > 0}
                  />
                </div>{" "}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {validationResult && validationResult.length > 0 && (
            <Segment color="yellow" className="injaz-imported-students">
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.FirstName")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.MiddleName")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.LastName")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.Nationality")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.Students.Birthdate")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.Students.Class")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("UserManagement.Students.Section")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("Institutions.Errors")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {validationResult.map((result, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Icon color={result.errorsCount === 0 ? "olive" : "red"} name="circle"></Icon>
                      </Table.Cell>
                      <Table.Cell>{result.student.firstName}</Table.Cell>
                      <Table.Cell>{result.student.middleName}</Table.Cell>
                      <Table.Cell>{result.student.lastName}</Table.Cell>
                      <Table.Cell>{result.student.email}</Table.Cell>
                      <Table.Cell>{result.student.nationality}</Table.Cell>
                      <Table.Cell>{result.student.birthdate}</Table.Cell>
                      <Table.Cell>{result.student.grade}</Table.Cell>
                      <Table.Cell>{result.student.section}</Table.Cell>
                      <Table.Cell>
                        <List>
                          <List.Item>
                            <List.Content>
                              <List.Description>
                                {result.errors &&
                                  result.errors.length > 0 &&
                                  result.errors.map((error) => (
                                    <>
                                      <div className="import-error">
                                        <Icon name="marker" />
                                        {error}
                                      </div>
                                      <br />
                                    </>
                                  ))}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          )}
        </Grid.Row>
      </Grid>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
