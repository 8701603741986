import { useField } from "formik";
import React from "react";
import { Form, Label, Select } from "semantic-ui-react";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  options?: any;
  onChange?: (value: any, text: any) => void;
  disabled?: any;
}

export default function CustomSelectInput(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <Form.Field error={meta.touched && !!meta.error}>
        <label>{props.label}</label>
        <Select
          clearable
          options={props.options}
          value={field.value || null}
          onChange={(e, d) => {
            if (d.value === "") helpers.setValue(null);
            else helpers.setValue(d.value);
            if (props.onChange && d.value != "") {
              let text = props.options.filter((o: any) => o.value === d.value)[0].text;
              props.onChange(d.value, text);
            }
          }}
          onBlur={() => helpers.setTouched(true)}
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
        {meta.touched && meta.error ? (
          <Label basic color="red">
            {meta.error}
          </Label>
        ) : null}
      </Form.Field>
    </>
  );
}
