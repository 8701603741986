import React, { useState } from "react";
import Login from "./Login";
import { Menu, Image, Button, MenuItem, Accordion, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";

interface SideNavMenuProps {
  onClose: () => void;
}

export default observer(function SideNavMenu({ onClose }: SideNavMenuProps) {
  /* <a
          href="https://lms.injaz.edu.jo/auth/login/azuread-oauth2/?auth_entry=login&amp "
          target="_blank"
          rel="noreferrer"
        >
          EDX
  </a> */
  const { t } = useTranslation("common");
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(-1);
  const { userStore } = useStore();
  return (
    <>
      <Menu.Item className="personal-info">
        <Login />
      </Menu.Item>
      <div className="sidenavbar-links">
        <Menu.Item header as={NavLink} to="/" content={t("Navigation.SideNavMenu.Home")} onClick={() => onClose()}></Menu.Item>
        {userStore.user && (
          <Menu.Item
            header
            as={NavLink}
            to={`/${userStore.user.userRole}/${userStore.user.id}/profile`}
            content={t("UserManagement.MyProfile")}
            onClick={() => onClose()}
          ></Menu.Item>
        )}
        {!userStore.isAdmin && !userStore.isEmployee && (
          <Menu.Item header as={NavLink} to="/mycourses" content={t("UserManagement.MyCourses")} onClick={() => onClose()}></Menu.Item>
        )}
        <Menu.Item className="hide-demo" header as={NavLink} to="/dashboard" content={t("Navigation.SideNavMenu.Dashboard")}></Menu.Item>
        {userStore && (userStore.isAdmin || userStore.isEmployee) && (
          <MenuItem>
            <Accordion fluid className="injaz-accordion">
              <Accordion.Title
                index={0}
                onClick={() => {
                  setActiveMenuIndex(activeMenuIndex === 0 ? -1 : 0);
                }}
              >
                <Icon name="dropdown" />
                {t("Navigation.SideNavMenu.ManageUsers")}
              </Accordion.Title>
              <Accordion.Content active={activeMenuIndex === 0}>
                {userStore.isAdmin && (
                  <Menu.Item as={NavLink} to="/manageusers/admin" onClick={() => onClose()}>
                    {t("UserManagement.AdministratorsTitle")}
                  </Menu.Item>
                )}
                <Menu.Item as={NavLink} to="/manageusers/teacher" onClick={() => onClose()}>
                  {t("UserManagement.TeachersTitle")}
                </Menu.Item>
                <Menu.Item as={NavLink} to="/manageusers/student" onClick={() => onClose()}>
                  {t("UserManagement.StudentsTitle")}
                </Menu.Item>
                <Menu.Item as={NavLink} to="/manageusers/volunteer" onClick={() => onClose()}>
                  {t("UserManagement.VolunteersTitle")}
                </Menu.Item>
                <Menu.Item as={NavLink} to="/manageusers/employee" onClick={() => onClose()}>
                  {t("UserManagement.EmployeesTitle")}
                </Menu.Item>
                <Menu.Item as={NavLink} to="/manageusers/coursemanager" onClick={() => onClose()}>
                  {t("UserManagement.CourseManagersTitle")}
                </Menu.Item>
              </Accordion.Content>
            </Accordion>
          </MenuItem>
        )}
        {userStore && userStore.isAdmin && (
          <Menu.Item header as={NavLink} to="/manageprograms" content={t("Navigation.SideNavMenu.ManagePrograms")} onClick={() => onClose()}></Menu.Item>
        )}
        {userStore && (userStore.isAdmin || userStore.isEmployee) && (
          <Menu.Item header as={NavLink} to="/managecourses" content={t("Navigation.SideNavMenu.ManageCourses")} onClick={() => onClose()}></Menu.Item>
        )}
        {userStore && (userStore.isAdmin || userStore.isEmployee) && (
          <Menu.Item header as={NavLink} to="/institutions/manage" content={t("Institutions.ManageInstitutions")} onClick={() => onClose()}></Menu.Item>
        )}
        {userStore && userStore.isAdmin && (
          <MenuItem>
            <Accordion fluid className="injaz-accordion">
              <Accordion.Title
                index={0}
                onClick={() => {
                  setActiveMenuIndex(activeMenuIndex === 2 ? 1 : 2);
                }}
              >
                <Icon name="dropdown" />
                {t("Navigation.SideNavMenu.Settings")}
              </Accordion.Title>
              <Accordion.Content active={activeMenuIndex === 2}>
                <Menu.Item as={NavLink} to="/settings/majors" onClick={() => onClose()}>
                  {t("Navigation.SideNavMenu.Majors")}
                </Menu.Item>
                <Menu.Item as={NavLink} to="/settings/donors" onClick={() => onClose()}>
                  {t("Navigation.SideNavMenu.Donors")}
                </Menu.Item>
                <Menu.Item as={NavLink} to="/settings/countries" onClick={() => onClose()}>
                  {t("Navigation.SideNavMenu.Countries")}
                </Menu.Item>
              </Accordion.Content>
            </Accordion>
          </MenuItem>
        )}
        {userStore && userStore.isAdmin && (
          <Menu.Item header as={NavLink} to="/reports" content={t("Navigation.SideNavMenu.Reports")} onClick={() => onClose()}></Menu.Item>
        )}
      </div>
    </>
  );
});
