import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Header, Icon, Loader, Segment, Table } from "semantic-ui-react";
import agent from "../../api/agent";
import { CourseProgramInformationDto } from "../../models/Reports/courseProgramInformationDto";
import { toast } from "react-toastify";
import { useStore } from "../../stores/store";
import * as XLSX from 'xlsx';

export default function CourseProgramInformationReport() {
  const { t } = useTranslation("common");
  const { lookupsStore, commonStore } = useStore();
  const [records, setRecords] = useState<CourseProgramInformationDto[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(true);

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    agent.Reports.courseProgramReport()
      .then((result) => setRecords(result))
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, []);

  const exportExcel = ()=>{
    var table_elt = document.getElementById("tblReport");
    const workSheet=XLSX.utils.table_to_sheet(table_elt);
      const workBook=XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook,workSheet,"Data")
      XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
      XLSX.writeFile(workBook,"course_program_report.xlsx")
  }

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

const table = (
<div className="reports-tbl">
<Table id="tblReport">
<Table.Header>
  <Table.Row>
    <Table.HeaderCell>{t("ProgramManagement.AcademicYear")}</Table.HeaderCell>
    <Table.HeaderCell>{t("ProgramManagement.Semester")}</Table.HeaderCell>
    <Table.HeaderCell>{t("Reports.Area")}</Table.HeaderCell>
    <Table.HeaderCell>{t("Reports.Governate")}</Table.HeaderCell>
    <Table.HeaderCell>{t("ProgramManagement.ProgramName")}</Table.HeaderCell>
    <Table.HeaderCell>{t("Reports.TotalCourses")}</Table.HeaderCell>
  </Table.Row>
</Table.Header>
<Table.Body>
  {records &&
    records.length > 0 &&
    records.map((record, index) => (
      <Table.Row key={index}>
        <Table.Cell>{record.academicYear}</Table.Cell>
        <Table.Cell>
          {lookupsStore.getSemsterValue(record.semesterId, commonStore.lang)}
        </Table.Cell>
        <Table.Cell>
          {record.areaId == null
            ? "-"
            : lookupsStore.getAreaValue(record.areaId, commonStore.lang)}
        </Table.Cell>
        <Table.Cell>{record.governate == null ? "-" : record.governate}</Table.Cell>
        <Table.Cell>{record.programName}</Table.Cell>
        <Table.Cell>{record.totalCourses}</Table.Cell>
      </Table.Row>
    ))}
</Table.Body>
</Table>
</div>
)

  const content = (
    <>
    <div className="ui menu injaz-forms-btns">
    <div className="item">
    <Button basic color="blue" onClick={exportExcel}>
        <Icon name="download"/>
      </Button>
    </div>
      </div>
      {table}
    </>
  );

  return (
    <>
      <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="/reports" className="injaz-breadcrumbs__item">Reports</a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">
            <span>{t("Reports.CourseProgramInformationReport")}</span>
          </a>
      </nav>
      <Header as="h1">{t("Reports.CourseProgramInformationReport")}</Header>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
