import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Grid, Button, Dimmer, Loader, Header, Segment, List } from "semantic-ui-react";
import agent from "../../../api/agent";
import VolunteerDetailsDto from "../../../models/Users/volunteerDetailsDto";
import { useStore } from "../../../stores/store";
import VolunteerCourses from "./VolunteerCourses";

interface VolunteerProfileParams {
  userId: string;
}

export default function VolunteerProfile() {
  let { userId } = useParams<VolunteerProfileParams>();
  const { lookupsStore, commonStore, userStore } = useStore();
  const [user, setUser] = useState<VolunteerDetailsDto>();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const { t } = useTranslation("common");

  useEffect(() => {
    agent.Profiles.volunteer(userId)
      .then((result) => {
        result.preferredVolunteeringDaysNames = lookupsStore.getWeekDaysValues(
          result.preferredVolunteeringDays,
          commonStore.lang
        );
        setUser(result);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <h2>{t("UserManagement.Volunteers.VolunteerProfile")}</h2>
      <div className="ui menu injaz-users-forms-btns">
        {userStore.isLoggedIn && userStore.isAdmin && (
          <div className="item">
            <Button basic color="blue" as={NavLink} to="/manageusers/volunteer">
              {t("Common.Back")}
            </Button>
          </div>
        )}
        <div className="item">
          {(userStore.isAdmin || userStore.isEmployee) && (
            <Button
              content={t("Common.Edit")}
              basic
              color="blue"
              as={NavLink}
              to={`/volunteer/${userId}/edit`}
            />
          )}
        </div>
      </div>
      <Segment className="injaz-forms-segment">
        <Grid columns={3} className="profile-info" divided stackable>
          <Grid.Row className="">
            <Grid.Column className="">
              <Header as="h3">{t("UserManagement.PersonalDetails")}</Header>
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.FirstName")}:</List.Header>
                  {user?.firstName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.MiddleName")}:</List.Header>
                  {user?.middleName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.LastName")}:</List.Header> {user?.lastName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Gender")}: </List.Header>
                  {lookupsStore.getGender(user?.gender, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Nationality")}: </List.Header>
                  {user?.nationalityName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.NationalID")}: </List.Header>
                  {user?.nationalID}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.EmailAddress")}:</List.Header>
                  {user?.email}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.PhoneNumber")}: </List.Header>
                  {user?.phoneNumber}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.VolunteerAddress")}: </List.Header>
                  {user?.volunteerAddress}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Reports.Governate")}: </List.Header>
                  {user?.governateName}
                </List.Item>

                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.VolunteerType")}: </List.Header>
                  {lookupsStore.getVolunteerType(user?.volunteeringType, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.VolunteerCompany")}: </List.Header>
                  {user?.volunteerCompany}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.VolunteerCompanyAddress")}: </List.Header>
                  {user?.volunteerCompanyAddress}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.VolunteerStatus")}: </List.Header>
                  {lookupsStore.getVolunteerStatus(user?.volunteeringStatus, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.VerifiedVolunteer")}: </List.Header>
                  {user && user.verified
                    ? t("UserManagement.Volunteers.Verified")
                    : t("UserManagement.Volunteers.NotVerified")}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <Header as="h3">{t("UserManagement.Experience")}</Header>
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.EducationDegree")}: </List.Header>
                  {lookupsStore.getEducationDegree(user?.educationalDegreeId, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.EducationInstitutionName")}: </List.Header>
                  {user?.educationInstitutionName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.Major")}: </List.Header>
                  {user?.majorName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.YearOfGraduation")}: </List.Header>
                  {user?.graduationYear}
                </List.Item>
                <List.Item>
                  <List.Header>
                    {t("UserManagement.Volunteers.CumulativeYearsWorkExperience")}:
                  </List.Header>
                  {user?.cumlativeYearsWorkExperience}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.ExperienceField")}: </List.Header>
                  {user?.experienceField}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.PositionTitle")}: </List.Header>
                  {user?.positionTitle}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.PMP")}: </List.Header>
                  {user && user.pmp ? t("Common.Yes") : t("Common.No")}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.PMPExperience")}: </List.Header>
                  {user?.pmpExperience}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.TOT")}: </List.Header>
                  {user && user.tot ? t("Common.Yes") : t("Common.No")}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <Header as="h3">{t("UserManagement.Volunteers.Prefrences")}</Header>
              <List>
                <List.Item>
                  <List.Header>
                    {t("UserManagement.Volunteers.FavoriteRegionToVolunteerIn")}:
                  </List.Header>
                  {user?.preferredVolunteeringGovernateName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.OnlineComputerSkills")}: </List.Header>
                  {user && user.hasOnlineCoursesExperience ? t("Common.Yes") : t("Common.No")}
                </List.Item>
                <List.Item>
                  <List.Header>
                    {t("UserManagement.Volunteers.PreferedVolunteeringApproach")}:
                  </List.Header>
                  {lookupsStore.getCourseDeliveryScheme(
                    user?.preferredVolunteeringScheme,
                    commonStore.lang
                  )}
                </List.Item>
                <List.Item>
                  <List.Header>
                    {t("UserManagement.Volunteers.PreferedVolunteeringSchoolGender")}:
                  </List.Header>
                  {lookupsStore.getGenderPreferenceValue(user?.preferredVolunteeringSchoolGender, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("Reports.Birthdate")}:</List.Header>
                  {user?.birthdate}
                </List.Item>

                <List.Item>
                  <List.Header>
                    {t("UserManagement.Volunteers.PreferVolunteeringMorethanOncePerWeek")}
                  </List.Header>
                  {user && user.preferVolunteeringMorethanOncePerWeek ? t("Common.Yes") : t("Common.No")}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.PreferredVolunteeringDays")}:</List.Header>
                  {user &&
                    user.preferredVolunteeringDaysNames &&
                    user.preferredVolunteeringDaysNames.length > 0 &&
                    user.preferredVolunteeringDaysNames.map((day) => day + "\n")}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.ProgramExperiences")}:</List.Header>
                  {user &&
                    user.volunteerProgramExperiencesNames &&
                    user.volunteerProgramExperiencesNames.length > 0 &&
                    user.volunteerProgramExperiencesNames.map((name) => name + "\n")}
                </List.Item>
                {(userStore.isAdmin || userStore.isEmployee) && (
                  <List.Item>
                    <List.Header>{t("UserManagement.Volunteers.SuggestedPrograms")}:</List.Header>
                    {user &&
                      user.volunteerSuggestedProgramsNames &&
                      user.volunteerSuggestedProgramsNames.length > 0 &&
                      user.volunteerSuggestedProgramsNames.map((name) => name + "\n")}
                  </List.Item>
                )}
                <List.Item>
                  <List.Header>{t("UserManagement.Volunteers.PreferredInstitutionTypes")}: </List.Header>
                  {user &&
                    user.preferredInstitutionTypes &&
                    user.preferredInstitutionTypes.length > 0 &&
                    user.preferredInstitutionTypes.map(
                      (inst) => lookupsStore.getInstitutionGenderTypeValue(inst, commonStore.lang) + "\n"
                    )}
                </List.Item>
                {(userStore.isAdmin || userStore.isEmployee) && (
                  <List.Item>
                    <List.Header>{t("UserManagement.Volunteers.VDNotes")}: </List.Header>
                    {user?.vdNotes}
                  </List.Item>
                )}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {userStore.user?.id != +userId && <VolunteerCourses volunteerId={+userId} />}
      {/*<div className="profile-certificates">
        <Header as="h2">My Certifications</Header>
        <Segment>
          <Item>
            <Item.Image size="tiny" src="https://react.semantic-ui.com/images/wireframe/image.png" />
            <Item.Content>
              <Item.Header as="a">Header</Item.Header>
              <Item.Meta>Description</Item.Meta>
              <Item.Description>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet.
              </Item.Description>
              <Item.Extra>Additional Details</Item.Extra>
            </Item.Content>
          </Item>
        </Segment>
                  </div>*/}
      {/*<div className="profile-history">
        <Header as="h2">My Volunteering History</Header>
        <Segment>
          <Item>
            <Item.Image size="tiny" src="https://react.semantic-ui.com/images/wireframe/image.png" />
            <Item.Content>
              <Item.Header as="a">Header</Item.Header>
              <Item.Meta>Description</Item.Meta>
              <Item.Description>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet.
              </Item.Description>
              <Item.Extra>Additional Details</Item.Extra>
            </Item.Content>
          </Item>
        </Segment>
                  </div>*/}
      {/*<Header as="h2">Completed Courses</Header>
      <Grid>
        <Grid.Row columns={5} className="completed-courses">
          <Grid.Column className="completed-col">
            <Card>
              <Card.Content>
                <Card.Header>Course Name</Card.Header>
                <Image floated="left" size="medium" src="/img/program-1.png" />
                <Card.Description>
                  <strong>Supported By: </strong> Artlink_mashrek
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                More <Icon name="arrow right" floated="left" />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
                </Grid>*/}
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
