import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Icon, Input } from "semantic-ui-react";
import agent from "../../api/agent";
import InstitutionDto from "../../models/Institutions/institutionDto";
import { UserSummary } from "../../models/Users/userSummary";
import UsersList from "../UserManagement/UsersList";

interface Props {
  institution: InstitutionDto | undefined;
}

export default function InstitutionStudents({ institution }: Props) {
  const { t } = useTranslation("common");
  const [users, setUsers] = useState<UserSummary[]>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [totalUserCount, setTotalUserCount] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number | string | undefined>(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const searchUsers = () => {
    setCurrentPageIndex(1);
    setFilterQuery(searchText);
  };

  useEffect(() => {
    if (institution == null) return;
    agent.Institutions.students(institution?.id, filterQuery, currentPageIndex, pageSize)
      .then((result) => {
        setUsers(result);
      })
      .catch((error) => handleError(error));

    agent.Institutions.studentsCount(institution?.id, filterQuery, currentPageIndex, pageSize)
      .then((result) => {
        setTotalUserCount(result);
      })
      .catch((error) => handleError(error));
  }, [institution, filterQuery]);

  useEffect(() => {
    if (institution == null) return;
    agent.Institutions.students(institution?.id, filterQuery, currentPageIndex, pageSize)
      .then((result) => {
        setUsers(result);
      })
      .catch((error) => handleError(error));
  }, [currentPageIndex]);

  return (
    <>
      <div className="ui menu injaz-forms-btns">
        <div className="item">
          <Button basic color="blue" content={t("UserManagement.Import")} as={NavLink} to={`/student/import/${institution?.id}`} />
        </div>

        <div className="item injaz-srch">
          <Input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            autoComplete="new-password"
            placeholder={t("Common.Search") + " ..."}
            icon={
              <Icon
                name="delete"
                link
                onClick={() => {
                  setSearchText("");
                  setCurrentPageIndex(1);
                  setFilterQuery("");
                }}
              />
            }
          />
          <Button
            onClick={() => {
              setCurrentPageIndex(1);
              searchUsers();
            }}
          >
            <Icon className="search" />
          </Button>
        </div>
      </div>
      <UsersList
        users={users}
        userType="student"
        loadingInProgress={inProgress}
        currentPage={currentPageIndex}
        pageSize={pageSize}
        totalUserCount={totalUserCount}
        blockActions={inProgress}
        onItemSelected={(u) => {}}
        onPageChanged={(pageIndex) => {
          setCurrentPageIndex(pageIndex);
        }}
      />
    </>
  );
}
