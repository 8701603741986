import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import agent from "../../api/agent";
import StudentCourseCertificateDto from "../../models/Courses/studentCourseCertificateDto";
import StudentCertificate from "./StudentCertificate";

interface Params {
  courseId: string;
}

export default function DownloadCourseCertificates() {
  let { courseId } = useParams<Params>();
  const [certificates, setCertificates] = useState<StudentCourseCertificateDto[]>();

  useEffect(() => {
    agent.Courses.certicates(+courseId).then((result) => {
      setCertificates(result);
    });
  }, [courseId]);

  useEffect(() => {
    if (certificates) downloadCertificates();
  }, [certificates]);

  const downloadCertificates = async () => {
    if (certificates == null || certificates.length === 0) return;
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      putOnlyUsedFonts: true,
    });

    let index = 0;
    for (const c of certificates) {
      const staticElement: HTMLElement | null = document.getElementById(`pdf-${c.studentId}`);
      if (staticElement == null) return;
      const data = await html2canvas(staticElement);
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      index++;
      if (index < certificates.length) pdf.addPage();
    }
    pdf.save("certificates.pdf");
  };

  return (
    <>
      {certificates != null &&
        certificates.length > 0 &&
        certificates.map((certificate) => (
          <StudentCertificate key={certificate.studentId} certificate={certificate} />
        ))}
    </>
  );
}
