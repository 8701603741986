import { UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Menu, Modal, Image, List, Button, Flag } from "semantic-ui-react";
import { useStore } from "../../../stores/store";
import PublicLogin from "../PublicLogin";

export default function TopNavBar() {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation("common");
  const { commonStore, userStore } = useStore();
  const switchLanguage = function (language: string) {
    i18n.changeLanguage(language);
    commonStore.setLanguage(language);
  };

  return (
    <>
      <Menu className="injaz-topnavbar">
        <Menu.Item className="injaz-logo" name="Home" as={NavLink} to="/">
          <Image src="/img/injaz-blue.png" size="small" />
        </Menu.Item>
        <Menu.Item position="right" className="auth-btn">
          <UnauthenticatedTemplate>
            <PublicLogin />
          </UnauthenticatedTemplate>
        </Menu.Item>
        <Menu.Item>
          <Modal
            className={"lang-modal lang-switcher" + (commonStore.lang === "ar" ? "-ar" : "")}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
              <Button basic color="blue" className="navbar-btn">
                {t("LangugeSwitcher.Language")}
              </Button>
            }
          >
            <Modal.Header>{t("LangugeSwitcher.LangHeader")}</Modal.Header>
            <Modal.Content image scrolling>
              <Modal.Description className={"language" + (commonStore.lang === "ar" ? "-ar" : "")}>
                <div className="lang-instructions">{t("LangugeSwitcher.TopMessage")}</div>
                <List>
                  <List.Item as="li">
                    <div className="li-order-number">{t("LangugeSwitcher.One")}</div>
                    <List.Content verticalAlign="middle">{t("LangugeSwitcher.ChangeLms")}</List.Content>
                    <div className="lang-btn">
                      <Button
                        basic
                        onClick={() => switchLanguage("ar")}
                        active={commonStore.lang === "ar"}
                      >
                        <Flag name="jo" />
                      </Button>
                      <Button
                        basic
                        onClick={() => switchLanguage("en")}
                        active={commonStore.lang === "en"}
                      >
                        <Flag name="uk" />
                      </Button>
                    </div>
                  </List.Item>
                  {userStore.isLoggedIn && <List.Item as="li">
                    <div className="li-order-number">{t("LangugeSwitcher.Two")}</div>
                    <List.Content verticalAlign="middle">
                      {t("LangugeSwitcher.LmsCourseModule")}
                      <a href="https://lms.injaz.edu.jo/auth/login/azuread-oauth2/?auth_entry=login&next=https://apps.lms.injaz.edu.jo/account" className="lang-switch-item" target="_blank">
                        {t("LangugeSwitcher.Here")}
                      </a>
                    </List.Content>
                  </List.Item>}
                  {userStore.isLoggedIn &&<List.Item as="li">
                    <div className="li-order-number">{t("LangugeSwitcher.Three")}</div>
                    <List.Content verticalAlign="middle">
                      {t("LangugeSwitcher.ChangeTeamsLanguage")}:
                    </List.Content>
                  </List.Item>}
                </List>
                {userStore.isLoggedIn &&<Image
                  className="lang-modal-img"
                  size="huge"
                  wrapped
                  src="/img/teams-language_HR.gif"
                  centered
                  style={{ marginBottom: 10 }}
                />}
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpen(false)} primary>
                {t("Common.Ok")}
              </Button>
            </Modal.Actions>
          </Modal>
        </Menu.Item>
      </Menu>
    </>
  );
}
