import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import LookupsStore from "./lookupsStore";
import { UserStore } from "./userStore";

export const store = {
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    lookupsStore: new LookupsStore()
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}