import Papa from "papaparse";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dimmer, Grid, List, Loader, Message, Segment } from "semantic-ui-react";
import agent from "../../api/agent";

interface Params {
  courseId: string;
}

export default function ImportStudentMemberships() {
  const { t } = useTranslation("common");
  const history = useHistory();
  let { courseId } = useParams<Params>();
  const [loaderMessage, setLoaderMessage] = useState<string>(t("Common.Loading"));
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [students, setStudents] = useState<string[]>();
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [file, setFile] = useState();

  const handleError = () => toast.error(t("Common.GeneralErrorMessage"));

  const downloadTemplate = () => {
    var link = document.createElement("a");
    link.download = "coursestudentstemplate.csv";
    link.href = "/csvTemplates/coursestudentstemplate.csv";
    link.click();
  };

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const parseStudents = (data: any): string[] => {
    let result: string[] = [];
    if (data == null || data.length <= 1) return result;

    let invalidRowCount = 0;
    data.forEach((element: any, index: number) => {
      if (index === 0) return;
      if (element.length < 1) {
        invalidRowCount++;
        return;
      }

      let student: string = element[0];
      result.push(student);
    });

    if (invalidRowCount > 0) toast.warn(`${invalidRowCount} rows were not loaded due to missing fields.`);

    return result;
  };

  const startImport = () => {
    if (students == null) return;
    setLoaderMessage(t("Institutions.Importing"));
    setInProgress(true);
    agent.Courses.importStudents(students, courseId)
      .then((result) => {
        toast.success(t("Institutions.ImportCompleted"));
        history.push(`/coursedetails/${courseId}`);
      })
      .catch((error) => handleError())
      .finally(() => {
        setInProgress(false);
        setLoaderMessage(t("Common.Loading"));
      });
  };

  const handleOnSubmit = (e: any) => {
    setInvalidEmails([]);
    e.preventDefault();
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {
          setLoaderMessage(t("Institutions.ValidatingData"));
          setInProgress(true);
          let data = parseStudents(results.data);
          setStudents(data);
          agent.Users.validateCourseStudentsCsv(data, courseId)
            .then((result) => {
              if (result && result.length > 0) {
                setInvalidEmails(result);
              } else {
                toast.success("Data validated, please proceed with importing");
              }
            })
            .catch(() => handleError())
            .finally(() => {
              setInProgress(false);
              setLoaderMessage(t("Common.Loading"));
            });
        },
      });
    }
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{loaderMessage}...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <h1>{t("Institutions.ImportStudents")}</h1>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <List ordered className="import-steps">
              <List.Item>
                <div className="li-order-number">Download the student template </div>
                <div className="li-order-number">
                  <Button color="blue" basic compact onClick={downloadTemplate}>
                    {t("Institutions.DownloadTemplate")}
                  </Button>
                </div>
              </List.Item>
              <List.Item>
                <div className="li-order-number">Complete all columns without changing the column headers.</div>
              </List.Item>
              <List.Item>
                <div className="li-order-number">Save the template to your device.</div>
              </List.Item>
              <List.Item>
                <div className="li-order-number">Select and upload your file.</div>
                <form>
                  <div className="li-order-number">
                    <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />
                    <Button
                      basic
                      color="blue"
                      disabled={file == null}
                      onClick={(e) => {
                        handleOnSubmit(e);
                      }}
                    >
                      {t("Institutions.Load")}
                    </Button>
                  </div>
                </form>
              </List.Item>
              <List.Item>
                <div className="li-order-number">
                  Select start importing
                  {invalidEmails != null && invalidEmails.length > 0 && <Message className="import-error-msg" error content={t("Institutions.CsvValidationMessage")} />}
                  <ul>{invalidEmails != null && invalidEmails.length > 0 && invalidEmails.map((mail) => <li>{mail}</li>)}</ul>
                </div>
                <div className="li-order-number">
                  <Button
                    color="blue"
                    basic
                    content={t("Institutions.StartImporting")}
                    onClick={() => {
                      startImport();
                    }}
                    disabled={invalidEmails == null || invalidEmails.length > 0 || students == null || students.length === 0}
                  />
                </div>{" "}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
