import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  Grid,
  Header,
  Button,
  Image,
  Label,
  Segment,
  Message,
  Pagination,
  Icon,
  Popup,
} from "semantic-ui-react";
import MyCourseDto from "../../models/Courses/myCourseDto";
import { useStore } from "../../stores/store";

export interface Props {
  courses: MyCourseDto[];
  noCoursesMessage: string;
}

export default function MyCoursesList({ courses, noCoursesMessage }: Props) {
  const pageSize = 5;
  const { t } = useTranslation("common");
  const { userStore, lookupsStore, commonStore } = useStore();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentCoursesPage, setCurrentCoursesPage] = useState<MyCourseDto[]>([]);

  useEffect(() => {
    if (courses && courses.length <= pageSize) setCurrentCoursesPage(courses);
    else {
      let page = courses.slice(0, pageSize - 1);
      setCurrentCoursesPage(page);
    }
  }, [courses]);

  const onPageChanged = (pageIndex: number) => {
    setCurrentPage(pageIndex);
    let skip = (pageIndex - 1) * pageSize;
    let page = courses.slice(skip, skip + pageSize - 1);
    setCurrentCoursesPage(page);
  };

  const noCourses = <Message className="empty-list-msg" header={noCoursesMessage} />;

  return (
    <>
      {(courses == null || courses.length === 0) && noCourses}
      {currentCoursesPage &&
        currentCoursesPage.length > 0 &&
        currentCoursesPage.map((course) => (
          <Segment key={course.id} color="yellow">
            <Grid className="injaz-mycourses" divided="vertically" padded stackable>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Image src={course.imageUrl == null ? "/img/program-1.png" : course.imageUrl} />
                </Grid.Column>
                <Grid.Column width={9}>
                  {userStore.isStudent && (
                    <Header as="h3">
                      {course.name}
                      <span className="injaz-lbl injaz-status">
                        {course.courseStatus === 1 && (
                          <Label color="grey"> {t("CourseManagement.NotStarted")}</Label>
                        )}
                        {course.courseStatus === 2 && (
                          <Label color="yellow"> {t("CourseManagement.Running")}</Label>
                        )}
                        {course.courseStatus === 3 && (
                          <Label color="green">{t("CourseManagement.Finished")}</Label>
                        )}
                      </span>
                    </Header>
                  )}
                  {!userStore.isStudent && (
                    <Header as="h3">
                      <NavLink to={`/coursedetails/${course.id}`}>{course.name}</NavLink>
                    </Header>
                  )}
                  <div className="injaz-lbl injaz-inperson">
                    <span className="injaz-program-label">In Person/ Online: </span>
                    <span>{course.inPerson === true ? t("Common.InPerson") : t("Common.Online")}</span>
                  </div>
                  {/*<Label color="grey" className="injaz-thematic-area">
                    {course.inPerson === true ? t("Common.InPerson") : t("Common.Online")}
                  </Label>*/}
                  <div className="injaz-lbl injaz-course-startenddate">
                    <span className="injaz-lbl injaz-course-start">
                      {t("CourseManagement.StartDate")}:{" "}
                    </span>
                    <span>{course.startDate}</span>
                    <span className="injaz-lbl injaz-course-end">{t("CourseManagement.EndDate")}: </span>
                    <span>{course.endDate}</span>
                  </div>
                  <div className="injaz-lbl injaz-course-days">
                    <span className="injaz-program-label">{t("CourseManagement.Days")}: </span>
                    {course.implementationDays &&
                      course.implementationDays.length > 0 &&
                      course.implementationDays
                        .sort()
                        .map((day) => (
                          <span key={day}>{lookupsStore.getWeekdayValue(day, commonStore.lang)}</span>
                        ))}
                  </div>
                  <div className="injaz-lbl injaz-course-year">
                    <span className="injaz-program-label">{t("CourseManagement.AcademicYear")}: </span>
                    <span>{course.academicYear}</span>
                  </div>
                  <div className="injaz-lbl injaz-course-year">
                    <span className="injaz-program-label">{t("CourseManagement.TeacherName")}: </span>
                    <span>{course.teacherName}</span>
                  </div>

                  {userStore.isLoggedIn && userStore.isStudent && (
                    <div className="injaz-lbl attendance-info">
                      <span className="injaz-program-label">{t("CourseManagement.Attendance")}: </span>
                      <Button.Group className="attendance-btn">
                        {course.attendance &&
                          course.attendance.length > 0 &&
                          course.attendance.map((log) =>
                            log.status == null ? (
                              <Popup
                                content={t("CourseManagement.Scheduled")}
                                trigger={
                                  <Button key={log.sessionNumber} color="grey">
                                    {log.sessionNumber}
                                  </Button>
                                }
                              />
                            ) : (
                              <Popup
                                content={
                                  log.status
                                    ? t("CourseManagement.Present")
                                    : t("CourseManagement.Absent")
                                }
                                trigger={
                                  <Button key={log.sessionNumber} color={log.status ? "green" : "red"}>
                                    {log.sessionNumber}
                                  </Button>
                                }
                              />
                            )
                          )}
                      </Button.Group>
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column width={3}>
                  {/*<div className="course-settings">
                  <NavLink to={"/"} className="course-settings">
                    <Icon name="setting" />
                    <span>{t("Common.Settings")}</span>
                  </NavLink>
                          </div>*/}
                  <div className="mycourses-btn">
                    <Button
                      className="course-btn"
                      basic
                      color="blue"
                      onClick={() => {
                        window!.open("https://teams.microsoft.com", "_blank")!.focus();
                      }}
                    >
                      {t("Common.Colaborate")}
                    </Button>

                    <Button
                      className="course-btn"
                      basic
                      disabled={course.courseStatus === 1}
                      color="blue"
                      onClick={() => {
                        window!.open(course.edxCourseUrl!, "_blank")!.focus();
                      }}
                    >
                      {course.courseStatus === 1
                        ? t("CourseManagement.Scheduled")
                        : t("Common.ViewCourse")}
                    </Button>
                    {course.courseCompleted && (
                      <Button
                        className="course-btn"
                        basic
                        color="blue"
                        onClick={() => {
                          window.open(
                            `/certificate/${course.userId}/${course.id}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        <Icon name="download" />
                        {t("CourseManagement.Certificate")}
                      </Button>
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}

      {courses && (
        <Pagination
          boundaryRange={0}
          defaultActivePage={currentPage}
          totalPages={Math.ceil(courses.length / pageSize)}
          onPageChange={(e, d) => onPageChanged(+d.activePage!)}
        />
      )}
    </>
  );
}
