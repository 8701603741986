import React from "react";
import TopNavBar from "./TopNavBar";
export default function InjazOfferedCourses() {
  return (
    <>
      <TopNavBar />
      <h1>Offered Courses</h1>
    </>
  );
}
