import React from "react";
import { Header, Icon, Segment, Grid, Container } from "semantic-ui-react";
import Login from "../Components/Navigation/Login";

export default function UnauthorizedAcess() {
  return (
    <>
      <Segment placeholder className="access-denied">
        <Container className="access-denied-content">
        <Grid>
          <Grid.Column width={8}>
          <Header icon>
          Sorry - You are not authorized to access this app.<br/>Please contact your system administrator to
          grant you access.
        </Header>
        <Segment.Inline>
          <Login />
        </Segment.Inline>
          </Grid.Column>
          <Grid.Column width={8}></Grid.Column>
        </Grid>
        </Container>
      </Segment>
    </>
  );
}
