import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Card, Grid, GridColumn, Header, Icon, Image } from "semantic-ui-react";

export default function ReportsHub() {
  const { t } = useTranslation("common");
  return (
    <>
      <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">
            <span>{t("Navigation.SideNavMenu.Reports")}</span>
          </a>
      </nav>
      <Header as="h2">{t("Navigation.SideNavMenu.Reports")}</Header>
      <Grid className="reports-hub" columns="equal" stackable>
        <Grid.Row>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Card.Header>{t("Reports.CourseProgramInformationReport")}</Card.Header>
                <Image centered size="small" src="/img/Reports-icon.svg" />
                <Card.Description></Card.Description>
              </Card.Content>
              <Card.Content extra>
                <NavLink to={`/reports/courseprograminformation`}>
                  {t("Common.View")} <Icon name="arrow right" floated="left" />
                </NavLink>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Card.Header>{t("Reports.InstitutionInformationReport")}</Card.Header>
                <Image centered size="small" src="/img/Reports-icon.svg" />
                <Card.Description></Card.Description>
              </Card.Content>
              <Card.Content extra>
                <NavLink to={`/reports/InstitutionInformation`}>
                  {t("Common.View")} <Icon name="arrow right" floated="left" />
                </NavLink>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Card.Header>{t("Reports.SemesterReport")}</Card.Header>
                <Image centered size="small" src="/img/Reports-icon.svg" />
                <Card.Description></Card.Description>
              </Card.Content>
              <Card.Content extra>
                <NavLink to={`/reports/semesterreport`}>
                  {t("Common.View")} <Icon name="arrow right" floated="left" />
                </NavLink>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card>
              <Card.Content>
                <Card.Header>{t("Reports.VolunteersReport")}</Card.Header>
                <Image centered size="small" src="/img/Reports-icon.svg" />
                <Card.Description></Card.Description>
              </Card.Content>
              <Card.Content extra>
                <NavLink to={`/reports/volunteersreport`}>
                  {t("Common.View")} <Icon name="arrow right" floated="left" />
                </NavLink>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
