import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Table, Segment, Input, Icon } from "semantic-ui-react";
import agent from "../../api/agent";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import CourseList from "./CourseList";

export default function ManageCourses() {
  const { t } = useTranslation("common");
  const [courses, setCourses] = useState<CourseSummaryDto[]>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [totalCourseCount, setTotalCourseCount] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number | string | undefined>(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const loadCourses = () => {
    setInProgress(true);
    agent.Courses.list(filterQuery, currentPageIndex, pageSize)
      .then((result) => {
        setCourses(result);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  useEffect(() => {
    setInProgress(true);
    agent.Courses.count(filterQuery)
      .then((result) => {
        setTotalCourseCount(result);
        loadCourses();
      })
      .catch((error) => {
        handleError(error);
      });
  }, [currentPageIndex, filterQuery]);

  const searchCourses = () => {
    setCurrentPageIndex(1);
    setFilterQuery(searchText);
  };

  return (
    <>
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          <span>{t("CourseManagement.ManageCourses")}</span>
        </a>
      </nav>
      <h2>{t("CourseManagement.ManageCourses")}</h2>
      <div className="ui menu injaz-forms-btns">
        <div className="item flt-left">
          <Button
            basic
            color="blue"
            content={t("CourseManagement.NewCourse")}
            as={NavLink}
            to={"/addcourse"}
          />
        </div>
        {/*<div className="item flt-left">
          <Button content="Delete" basic />
        </div>*/}
        <div className="right menu flt-right">
        <div className="item injaz-srch">
          <Input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            autoComplete="new-password"
            placeholder={t("Common.Search") + " ..."}
            icon={
              <Icon
                name="delete"
                link
                onClick={() => {
                  setSearchText("");
                  setCurrentPageIndex(1);
                  setFilterQuery("");
                }}
              />
            }
          />
          <Button
            onClick={() => {
              setCurrentPageIndex(1);
              searchCourses();
            }}
          >
            <Icon className="search" />
          </Button>
        </div>
        </div>
      </div>
      <CourseList
        courses={courses}
        loadingInProgress={inProgress}
        currentPage={currentPageIndex}
        pageSize={pageSize}
        totalItemsCount={totalCourseCount}
        blockActions={inProgress}
        onPageChanged={(pageIndex: any) => {
          setCurrentPageIndex(pageIndex);
        }}
      />
    </>
  );
}
