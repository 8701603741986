import React from "react";
import { NavLink } from "react-router-dom";
import { Header, Item, Segment } from "semantic-ui-react";
import { UserSummary } from "../../../models/Users/userSummary";

export interface Props {
  employees: UserSummary[] | undefined;
}

export default function Subordinates({ employees }: Props) {
  return (
    <>
      <Header as="h2">Subordinates</Header>
      <Segment>
        {employees &&
          employees.length > 0 &&
          employees.map((employee) => (
            <>
              <Item>
                <Item.Content className="injaz-sub">
                  <Item.Header as="a">
                    <NavLink to={`/employee/${employee.id}/profile`}>{employee.displayName}</NavLink>
                  </Item.Header>
                  <Item.Meta>{employee.email}</Item.Meta>
                </Item.Content>
              </Item>
            </>
          ))}
      </Segment>
    </>
  );
}
