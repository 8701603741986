import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button, Header, Segment, Grid, Form, Dimmer, Loader } from "semantic-ui-react";
import * as Yup from "yup";
import agent from "../../../api/agent";
import CustomCheckboxInput from "../../../common/forms/CustomCheckboxInput";
import CustomSelectInput from "../../../common/forms/CustomSelectInput";
import CustomTextInput from "../../../common/forms/CustomTextInput";
import AddEmployeeDto from "../../../models/Users/addEmployeeDto";
import { useStore } from "../../../stores/store";
import { toast } from "react-toastify";

interface Params {
  userId: string | undefined;
}

export default function AddEditCourseManager() {
  const { userId } = useParams<Params>();
  const { t } = useTranslation("common");
  const history = useHistory();
  const { lookupsStore, commonStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [departments, setDepartments] = useState<{ text: string; value: number }[]>([]);
  const [areas, setAreas] = useState<{ text: string; value: number }[]>([]);
  const [supervisors, setSupervisors] = useState<{ text: string; value: number }[]>([]);

  const [user, setUser] = useState<AddEmployeeDto>({
    userId: 0,
    firstName: "",
    lastName: "",
    middleName: null,
    email: null,
    phoneNumber: null,
    areaId: null,
    departmentId: null,
    status: true,
    supervisorId: null,
    team: null,
  });

  const handleError = (error: any) => console.log(error);

  useEffect(() => {
    agent.Lookups.employees()
      .then((result) => setSupervisors(result))
      .catch((error) => handleError);
  }, []);

  useEffect(() => {
    if (userId == undefined) return;
    setInProgress(true);
    agent.Users.editEmployee(userId)
      .then((result) => setUser(result))
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [userId]);

  useEffect(() => {
    let translatedDepartments = lookupsStore.getDepartments(commonStore.lang);
    setDepartments(translatedDepartments);

    let translatedAreas = lookupsStore.getAreas(commonStore.lang);
    setAreas(translatedAreas);
  }, [commonStore.lang]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Common.Required")),
    lastName: Yup.string().required(t("Common.Required")),
    email: Yup.string().typeError(t("Common.InvalidEmailAddress")).required(t("Common.Required")).email(t("Common.InvalidEmailAddress")),
    phoneNumber: Yup.string().required(t("Common.Required")),
    departmentId: Yup.string().required(t("Common.Required")),
    areaId: Yup.string().required(t("Common.Required")),
    //supervisorId: Yup.string().required(t("Common.Required")),
  });

  const submitForm = (values: AddEmployeeDto) => {
    setUser(values);
    if (!userId || userId == null) addNewUser(values);
    else updateUser(values);
  };

  const addNewUser = (values: AddEmployeeDto) => {
    setIsSaving(true);
    agent.Users.addCourseManager(values)
      .then((result) => {
        toast.success(t("UserManagement.UserAdddedSuccessfully"));
        history.push("/manageusers/coursemanager");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const updateUser = (values: AddEmployeeDto) => {
    setIsSaving(true);
    agent.Users.updateEmployee(values)
      .then((result) => {
        toast.success(t("UserManagement.UserUpdatedSucessfully"));
        history.push("/manageusers/coursemanager");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <Header as="h2">{t("UserManagement.CourseManager.NewCourseManager")}</Header>
      <div>
        <div className="injaz-forms-cancel-btn-container">
          <Button className="injaz-forms-cancel-btn" basic color="blue" content={t("Common.Back")} as={NavLink} to={"/manageusers/coursemanager"} />
        </div>
        <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
          <Segment color="yellow" className="injaz-forms-segment-nopad">
            <Formik validationSchema={validationSchema} enableReinitialize initialValues={user} onSubmit={(values) => submitForm(values)}>
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-btn-container ui menu injaz-forms-btns-incl">
                    <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                  </div>
                  <Grid className="injaz-form-pad">
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput label={t("UserManagement.FirstName") + " *"} placeholder={t("UserManagement.FirstName")} name="firstName" />
                          <CustomTextInput label={t("UserManagement.MiddleName")} placeholder={t("UserManagement.MiddleName")} name="middleName" />
                          <CustomTextInput label={t("UserManagement.LastName") + " *"} placeholder={t("UserManagement.LastName")} name="lastName" />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomTextInput label={t("UserManagement.EmailAddress") + " *"} placeholder={t("UserManagement.EmailAddress")} name="email" />
                          <CustomTextInput label={t("UserManagement.PhoneNumber") + " *"} placeholder={t("UserManagement.PhoneNumber")} name="phoneNumber" />
                          <CustomSelectInput
                            label={t("UserManagement.Employees.Department") + " *"}
                            options={departments}
                            name="departmentId"
                            placeholder={t("UserManagement.Employees.Department")}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomTextInput label={t("UserManagement.Employees.Team")} placeholder={t("UserManagement.Employees.Team")} name="team" />
                          <CustomSelectInput
                            label={t("UserManagement.Employees.Area") + " *"}
                            options={areas}
                            name="areaId"
                            placeholder={t("UserManagement.Employees.Area")}
                          />
                          <CustomSelectInput
                            label={t("UserManagement.Employees.Supervisor") + " *"}
                            options={supervisors}
                            name="supervisorId"
                            placeholder={t("UserManagement.Employees.Supervisor")}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomCheckboxInput name="status" type="checkbox" label={t("UserManagement.Status")} />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Segment>
        </Segment.Group>
      </div>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
