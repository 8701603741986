import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { AddVolunteerDto } from "../../../models/Users/addVolunteerDto";
import { LookupDto } from "../../../models/lookupDto";
import { useStore } from "../../../stores/store";
import * as Yup from "yup";
import { Button, Form, Header, Segment, Grid, Dimmer, Loader } from "semantic-ui-react";
import { Formik } from "formik";
import CustomCheckboxInput from "../../../common/forms/CustomCheckboxInput";
import CustomTextInput from "../../../common/forms/CustomTextInput";
import CustomSelectInput from "../../../common/forms/CustomSelectInput";
import agent from "../../../api/agent";
import CustomMultiSelectInput from "../../../common/forms/CustomMultiSelectInput";
import CustomTextAreaInput from "../../../common/forms/CustomTextAreaInput";
import CustomDateInput from "../../../common/forms/CustomDateInput";
import { toast } from "react-toastify";

interface Params {
  userId: string | undefined;
}

export default function AddEditVolunteer() {
  const { userId } = useParams<Params>();
  const { t } = useTranslation("common");
  const history = useHistory();
  const { lookupsStore, commonStore } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [maleFemaleValues, setMaleFemaleValues] = useState<{ text: string; value: number }[]>([]);
  const [genderPreference,setGenderPreference] = useState<{ text: string; value: number }[]>([]);
  const [volunteerTypesValues, setVolunteerTypesValues] = useState<{ text: string; value: number }[]>(
    []
  );
  const [volunteerStatusesValues, setVolunteerStatusesValues] = useState<
    { text: string; value: number }[]
  >([]);
  const [educationDegreesValues, setEducationDegreesValues] = useState<
    { text: string; value: number }[]
  >([]);
  const [majors, setMajors] = useState<LookupDto[]>([]);
  const [programs, setPrograms] = useState<LookupDto[]>([]);
  const [governates, setGovernates] = useState<LookupDto[]>([]);
  const [educationInstitutions, setEducationInstitutions] = useState<LookupDto[]>([]);
  const [countries, setCountries] = useState<LookupDto[]>([]);
  const [weekdays, setWeekdays] = useState<{ text: string; value: number }[]>([]);
  const [graduationYears, setGraduationYears] = useState<{ text: string; value: number }[]>([]);
  const [courseDeliverySchemes, setCourseDeliverySchemes] = useState<{ text: string; value: number }[]>(
    []
  );
  const [institutions, setInstitutions] = useState<{ text: string; value: number }[]>([]);
  const [user, setUser] = useState<AddVolunteerDto>({
    userId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    gender: 0,
    nationality: 0,
    nationalId: null,
    volunteerAddress: "",
    volunteerCompany: "",
    volunteerCompanyAddress: "",
    volunteeringType: 0,
    positionTitle: "",
    volunteeringStatus: 0,
    verified: false,
    educationalDegreeId: 0,
    educationInstitutionId: null,
    majorId: null,
    graduationYear: 0,
    cumlativeYearsWorkExperience: null,
    experienceField: null,
    pmp: false,
    pmpExperience: null,
    tot: false,
    preferredVolunteeringGovernateId: 0,
    hasOnlineCoursesExperience: false,
    preferredVolunteeringScheme: 0,
    preferredVolunteeringSchoolGender: 0,
    preferVolunteeringMorethanOncePerWeek: false,
    vdNotes: "",
    volunteerProgramExperiences: [],
    volunteerSuggestedPrograms: [],
    preferredVolunteeringDays: [],
    preferredInstitutionTypes: [],
    governateId: 0,
    birthdate: null,
  });
  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    if (userId == undefined) return;
    setInProgress(true);
    agent.Users.editVolunteer(userId)
      .then((result) => setUser(result))
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [userId]);

  useEffect(() => {
    agent.Lookups.majors()
      .then((result) => setMajors(result))
      .catch((error) => handleError(error));

    agent.Programs.lookupList()
      .then((result) => {
        setPrograms(result);
      })
      .catch((error) => handleError(error));

    agent.Lookups.educationInstitutions()
      .then((result) => {
        setEducationInstitutions(result);
      })
      .catch((error) => handleError(error));

    agent.Lookups.countries()
      .then((result) => {
        setCountries(result);
      })
      .catch((error) => handleError(error));

    agent.Lookups.governates()
      .then((result) => {
        setGovernates(result);
      })
      .catch((error) => handleError(error));

    setGraduationYears(lookupsStore.getGraduationYears(commonStore.lang));
  }, []);

  useEffect(() => {
    let translatedGenders = lookupsStore.getGenders(commonStore.lang);
    setMaleFemaleValues(translatedGenders);

    let translatedGendersPreference = lookupsStore.getGenderPreferenceList(commonStore.lang);
    setGenderPreference(translatedGendersPreference);

    let translatedVolunteersTypesList = lookupsStore.getVolunteersTypes(commonStore.lang);
    setVolunteerTypesValues(translatedVolunteersTypesList);

    let translatedVolunteersStatusList = lookupsStore.getVolunteersStatuses(commonStore.lang);
    setVolunteerStatusesValues(translatedVolunteersStatusList);

    let weekdaysList = lookupsStore.getWeekdays(commonStore.lang);
    setWeekdays(weekdaysList);

    let translatedInstitutions = lookupsStore.getInstitutions(commonStore.lang);
    setInstitutions(translatedInstitutions);

    let translatedCourseDeliverySchemes = lookupsStore.getCourseDeliverySchemes(commonStore.lang);
    setCourseDeliverySchemes(translatedCourseDeliverySchemes);

    let translatedEducationDegrees = lookupsStore.getEducationDegrees(commonStore.lang);
    setEducationDegreesValues(translatedEducationDegrees);
  }, [commonStore.lang]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("Common.Required")),
    lastName: Yup.string().required(t("Common.Required")),
    email: Yup.string()
      .typeError(t("Common.InvalidEmailAddress"))
      .required(t("Common.Required"))
      .email(t("Common.InvalidEmailAddress")),
    phoneNumber: Yup.string().required(t("Common.Required")),
    volunteerAddress: Yup.string().required(t("Common.Required")),
    positionTitle: Yup.string().required(t("Common.Required")),
    volunteeringType: Yup.number().min(1, t("Common.Required")).required(t("Common.Required")),
    gender: Yup.string().required(t("Common.Required")),
    governateId: Yup.number().min(1, t("Common.Required")),
    nationality: Yup.number().min(1, t("Common.Required")),
    educationalDegreeId: Yup.number().min(1, t("Common.Required")),
    graduationYear: Yup.number().min(1, t("Common.Required")),
    cumlativeYearsWorkExperience: Yup.number()
      .typeError(t("Common.Required"))
      .min(0, t("Common.Required"))
      .max(80, t("Common.InvalidNumber"))
      .required(t("Common.Required")),
    experienceField: Yup.number()
      .typeError(t("Common.InvalidNumber"))
      .min(0, t("Common.InvalidNumber"))
      .max(80, t("Common.InvalidNumber"))
      .nullable(true),
    pmpExperience: Yup.number()
      .typeError(t("Common.InvalidNumber"))
      .min(0, t("Common.InvalidNumber"))
      .max(80, t("Common.InvalidNumber"))
      .nullable(true),
    birthdate: Yup.date().typeError(t("Common.InvalidDate")).required(t("Common.Required")),
    preferredVolunteeringGovernateId: Yup.number().min(1, t("Common.Required")),
    preferredVolunteeringSchoolGender: Yup.number().min(1, t("Common.Required")),
    preferredInstitutionTypes: Yup.array().min(1, t("Common.Required")).required(t("Common.Required")),
  });

  const submitForm = (values: AddVolunteerDto) => {
    setUser(values);
    setIsSaving(true);

    //values.verified = values.verified === "true" ? true : false;
    //values.pmp = values.pmp === "true" ? true : false;
    //values.tot = values.tot === "true" ? true : false;
    // values.hasOnlineCoursesExperience = values.hasOnlineCoursesExperience === "true" ? true : false;
    //values.preferVolunteeringMorethanOncePerWeek =
    //  values.preferVolunteeringMorethanOncePerWeek === "true" ? true : false;

    if (!userId || userId == null) addNewUser(values);
    else updateUser(values);
  };

  const addNewUser = (values: AddVolunteerDto) => {
    setIsSaving(true);
    agent.Users.addVolunteer(values)
      .then((result) => {
        toast.success(t("UserManagement.UserAdddedSuccessfully"));
        history.push("/manageusers/volunteer");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const updateUser = (values: AddVolunteerDto) => {
    setIsSaving(true);
    agent.Users.updateVolunteer(values)
      .then((result) => {
        toast.success(t("UserManagement.UserUpdatedSucessfully"));
        history.push("/manageusers/volunteer");
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409)
          toast.error(t("UserManagement.UserEmailAlreadyExists"));
        else handleError(error);
      })
      .finally(() => setIsSaving(false));
  };

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      <Header as="h2">{t("UserManagement.Volunteers.NewVolunteer")}</Header>
      <div>
        <div className="injaz-forms-cancel-btn-container">
          {!isSaving && (
            <Button
              className="injaz-forms-cancel-btn"
              basic
              color="blue"
              content={t("Common.Cancel")}
              as={NavLink}
              to={"/manageusers/volunteer"}
            />
          )}
        </div>
        <Segment.Group raised className="injaz-forms-segment-pad injaz-forms-segment">
          <Segment color="yellow" className="injaz-forms-segment-nopad">
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={user}
              onSubmit={(values) => submitForm(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-btn-container ui menu injaz-forms-btns-incl">
                    <Button type="submit" color="blue" content={t("Common.Save")} loading={isSaving} />
                  </div>
                  <Grid className="injaz-form-pad">
                    <Header as="h3">{t("UserManagement.PersonalDetails")}</Header>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("UserManagement.FirstName") + " *"}
                            placeholder={t("UserManagement.FirstName")}
                            name="firstName"
                          />
                          <CustomTextInput
                            label={t("UserManagement.MiddleName")}
                            placeholder={t("UserManagement.MiddleName")}
                            name="middleName"
                          />
                          <CustomTextInput
                            label={t("UserManagement.LastName") + " *"}
                            placeholder={t("UserManagement.LastName")}
                            name="lastName"
                          />
                          <CustomSelectInput
                            label={t("UserManagement.Gender") + " *"}
                            options={maleFemaleValues}
                            name="gender"
                            placeholder={t("UserManagement.Gender")}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <div className="field">
                            <div className="ui label label">Birthdate</div>
                            <CustomDateInput label={t("Reports.Birthdate")} name="birthdate" />
                          </div>

                          <CustomSelectInput
                            label={t("UserManagement.Nationality")}
                            placeholder={t("UserManagement.Nationality")}
                            name="nationality"
                            options={countries}
                          />
                          <CustomTextInput
                            label={t("UserManagement.NationalID")}
                            placeholder={t("UserManagement.NationalID")}
                            name="nationalId"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("UserManagement.EmailAddress") + " *"}
                            placeholder={t("UserManagement.EmailAddress")}
                            name="email"
                          />
                          <CustomTextInput
                            label={t("UserManagement.PhoneNumber")}
                            placeholder={t("UserManagement.PhoneNumber")}
                            name="phoneNumber"
                          />
                          <CustomTextInput
                            label={t("UserManagement.Volunteers.VolunteerAddress")}
                            placeholder={t("UserManagement.Volunteers.VolunteerAddress")}
                            name="volunteerAddress"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomSelectInput
                            label={t("Reports.Governate")}
                            placeholder={t("Reports.Governate")}
                            name="governateId"
                            options={governates}
                          />

                          <CustomSelectInput
                            label={t("UserManagement.Volunteers.VolunteerType")}
                            options={volunteerTypesValues}
                            name="volunteeringType"
                            placeholder={t("UserManagement.Volunteers.VolunteerType")}
                          />
                          <CustomTextInput
                            label={t("UserManagement.Volunteers.VolunteerCompany")}
                            placeholder={t("UserManagement.Volunteers.VolunteerCompany")}
                            name="volunteerCompany"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomTextInput
                            label={t("UserManagement.Volunteers.PositionTitle")}
                            placeholder={t("UserManagement.Volunteers.PositionTitle")}
                            name="positionTitle"
                          />
                          <CustomTextInput
                            label={t("UserManagement.Volunteers.VolunteerCompanyAddress")}
                            placeholder={t("UserManagement.Volunteers.VolunteerCompanyAddress")}
                            name="volunteerCompanyAddress"
                          />
                          <CustomSelectInput
                            label={t("UserManagement.Volunteers.VolunteerStatus")}
                            options={volunteerStatusesValues}
                            name="volunteeringStatus"
                            placeholder={t("UserManagement.Volunteers.VolunteerStatus")}
                          />
                        </Form.Group>
                        <CustomCheckboxInput
                          name="verified"
                          label={t("UserManagement.Volunteers.VerifiedVolunteer")}
                          type="checkbox"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <div className="ui divider"></div>
                    <Header as="h3">{t("UserManagement.Experience")}</Header>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group widths="equal">
                          <CustomSelectInput
                            label={t("UserManagement.Volunteers.EducationDegree")}
                            options={educationDegreesValues}
                            name="educationalDegreeId"
                            placeholder={t("UserManagement.Volunteers.EducationDegree")}
                          />
                          <CustomSelectInput
                            name="educationInstitutionId"
                            label={t("UserManagement.Volunteers.EducationInstitutionName")}
                            placeholder={t("UserManagement.Volunteers.EducationInstitutionName")}
                            options={educationInstitutions}
                          />
                          <CustomSelectInput
                            name="graduationYear"
                            label={t("UserManagement.Volunteers.YearOfGraduation")}
                            placeholder={t("UserManagement.Volunteers.YearOfGraduation")}
                            options={graduationYears}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <CustomSelectInput
                            label={t("UserManagement.Volunteers.Major")}
                            options={majors}
                            name="majorId"
                            placeholder={t("UserManagement.Volunteers.Major")}
                          />
                          <CustomTextInput
                            label={t("UserManagement.Volunteers.CumulativeYearsWorkExperience")}
                            placeholder={t("UserManagement.Volunteers.CumulativeYearsWorkExperience")}
                            name="cumlativeYearsWorkExperience"
                          />
                          <CustomTextInput
                            label={t("UserManagement.Volunteers.ExperienceField")}
                            placeholder={t("UserManagement.Volunteers.ExperienceField")}
                            name="experienceField"
                          />
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="extra-row-align">
                      <Grid.Column width={6}>
                        <CustomCheckboxInput
                          name="pmp"
                          label={t("UserManagement.Volunteers.PMP")}
                          type="checkbox"
                        />
                        <CustomTextInput
                          label={t("UserManagement.Volunteers.PMPExperience")}
                          placeholder={t("UserManagement.Volunteers.PMPExperience")}
                          name="pmpExperience"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <CustomCheckboxInput
                          name="tot"
                          label={t("UserManagement.Volunteers.TOT")}
                          type="checkbox"
                        />
                        <CustomCheckboxInput
                          name={t("UserManagement.Volunteers.OnlineComputerSkills")}
                          label={t("UserManagement.Volunteers.OnlineComputerSkills")}
                          type="checkbox"
                        />
                      </Grid.Column>
                      <Grid.Column width={5}></Grid.Column>
                    </Grid.Row>
                    <div className="ui divider"></div>
                    <Header as="h3">{t("UserManagement.Volunteers.Prefrences")}</Header>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <CustomSelectInput
                          name="preferredVolunteeringGovernateId"
                          label={t("UserManagement.Volunteers.FavoriteRegionToVolunteerIn")}
                          placeholder={t("UserManagement.Volunteers.FavoriteRegionToVolunteerIn")}
                          options={governates}
                        />
                        <CustomSelectInput
                          name="preferredVolunteeringScheme"
                          label={t("UserManagement.Volunteers.PreferedVolunteeringApproach")}
                          placeholder={t("UserManagement.Volunteers.PreferedVolunteeringApproach")}
                          options={courseDeliverySchemes}
                        />
                        <CustomMultiSelectInput
                          name="preferredInstitutionTypes"
                          label={t("UserManagement.Volunteers.PreferredInstitutionTypes")}
                          placeholder={t("UserManagement.Volunteers.PreferredInstitutionTypes")}
                          options={institutions}
                        />
                        <CustomSelectInput
                          name="preferredVolunteeringSchoolGender"
                          label={t("UserManagement.Volunteers.PreferedVolunteeringSchoolGender")}
                          placeholder={t("UserManagement.Volunteers.PreferedVolunteeringSchoolGender")}
                          options={genderPreference}
                        />
                        <CustomCheckboxInput
                          name="preferVolunteeringMorethanOncePerWeek"
                          label={t("UserManagement.Volunteers.PreferVolunteeringMorethanOncePerWeek")}
                          type="checkbox"
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <CustomMultiSelectInput
                          name="preferredVolunteeringDays"
                          label={t("UserManagement.Volunteers.PreferredVolunteeringDays")}
                          placeholder={t("UserManagement.Volunteers.PreferredVolunteeringDays")}
                          options={weekdays}
                        />

                        <CustomMultiSelectInput
                          name="volunteerSuggestedPrograms"
                          label={t("UserManagement.Volunteers.SuggestedPrograms")}
                          placeholder={t("UserManagement.Volunteers.SuggestedPrograms")}
                          options={programs}
                        />

                        <CustomMultiSelectInput
                          name="volunteerProgramExperiences"
                          label={t("UserManagement.Volunteers.ProgramExperiences")}
                          placeholder={t("UserManagement.Volunteers.ProgramExperiences")}
                          options={programs}
                        />
                        <CustomTextAreaInput
                          name="vdNotes"
                          placeholder={t("UserManagement.Volunteers.VDNotes")}
                          label={t("UserManagement.Volunteers.VDNotes")}
                          rows={5}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Segment>
        </Segment.Group>
      </div>
    </>
  );

  return (
    <>
      {!inProgress && content}
      {inProgress && loader}
    </>
  );
}
