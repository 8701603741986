import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Button, Dimmer, Grid, List, Loader, Segment, Icon } from "semantic-ui-react";
import agent from "../../../api/agent";
import EmployeeDetailsDto from "../../../models/Users/employeeDetailsDto";
import { UserSummary } from "../../../models/Users/userSummary";
import { useStore } from "../../../stores/store";
import Subordinates from "./Subordinates";

interface TeacherProfileParams {
  userId: string;
}

export default function EmployeeProfile() {
  let { userId } = useParams<TeacherProfileParams>();
  const { lookupsStore, commonStore, userStore } = useStore();
  const [user, setUser] = useState<EmployeeDetailsDto>();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [subordinates, setSubordinates] = useState<UserSummary[]>([]);
  const { t } = useTranslation("common");

  const handleError = (error: any) => console.log(error);

  useEffect(() => {
    agent.Profiles.employee(userId)
      .then((result) => {
        setUser(result);
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setInProgress(false);
      });

    agent.Users.employeeSubordinates(userId)
      .then((result) => setSubordinates(result))
      .catch((error) => handleError(error));
  }, [userId]);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  var content = (
    <>
      <nav className="injaz-breadcrumbs">
          <a href="/" className="injaz-breadcrumbs__item"><Icon name="home"/></a>
          <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          {userStore.user?.id !== +userId && <span>{t("UserManagement.Employees.EmployeeProfile")}</span>}
          {userStore.user?.id === +userId && <span>{t("UserManagement.MyProfile")}</span>}
          </a>
      </nav>
      {userStore.user?.id !== +userId && <h2>{t("UserManagement.Employees.EmployeeProfile")}</h2>}
      {userStore.user?.id === +userId && <h2>{t("UserManagement.MyProfile")}</h2>}
      <div className="ui menu injaz-users-forms-btns">
        {userStore.isAdmin && (
          <div className="item">
            <Button basic color="blue" as={NavLink} to="/manageusers/employee">
              {t("Common.Back")}
            </Button>
          </div>
        )}
        <div className="item">
          {userStore.isAdmin && (
            <Button
              content={t("Common.Edit")}
              basic
              color="blue"
              as={NavLink}
              to={`/employee/${userId}/edit`}
            />
          )}
        </div>
      </div>
      <Segment className="injaz-forms-segment">
        <Grid columns={2} className="profile-info" divided stackable>
          <Grid.Row className="">
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.FirstName")}: </List.Header>
                  {user?.firstName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.MiddleName")}:</List.Header>
                  {user?.middleName == null ? "--" : user?.middleName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.LastName")}: </List.Header> {user?.lastName}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.EmailAddress")}: </List.Header>
                  {user?.email}
                </List.Item>
                <List.Header>{t("UserManagement.PhoneNumber")}:</List.Header>
                {user?.phoneNumber == null ? "--" : user?.phoneNumber}
                <List.Item></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column className="">
              <List>
                <List.Item>
                  <List.Header>{t("UserManagement.Employees.Department")}: </List.Header>
                  {lookupsStore.getDepartmentValue(user?.departmentId, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Employees.Area")}: </List.Header>
                  {user?.areaId == null
                    ? "--"
                    : lookupsStore.getAreaValue(user?.areaId, commonStore.lang)}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Employees.Team")}: </List.Header>
                  {user?.team == null ? "--" : user?.team}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Employees.Supervisor")}: </List.Header>
                  {user?.supervisorId == null ? "--" : user?.supervisorName}
                  {<br />}
                  {user?.supervisorId == null ? "" : user?.supervisorEmail}
                </List.Item>
                <List.Item>
                  <List.Header>{t("UserManagement.Status")}: </List.Header>
                  {user?.status === true ? t("UserManagement.Active") : t("UserManagement.InActive")}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {subordinates && subordinates.length > 0 && <Subordinates employees={subordinates} />}
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
