import React, { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Segment, Loader, Dimmer } from "semantic-ui-react";
import agent from "../../api/agent";
import MyCourseDto from "../../models/Courses/myCourseDto";
import MyCoursesList from "./MyCoursesList";

export default function MyCourses() {
  const [currentCourses, setCurrentCourses] = useState<MyCourseDto[]>([]);
  const [completedCourses, setCompletedCourses] = useState<MyCourseDto[]>([]);

  const [inProgress, setInProgress] = useState<boolean>(true);

  const { t } = useTranslation("common");
  useEffect(() => {
    agent.Courses.myCourses()
      .then((result) => {
        setCurrentCourses(result);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setInProgress(false);
      });

    agent.Courses.myCompletedCourses()
      .then((result) => {
        setCompletedCourses(result);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  var tabs = [
    {
      menuItem: t("CourseManagement.CurrentCourses"),
      pane: {
        key: "CurrentCourses",
        content: (
          <MyCoursesList
            courses={currentCourses}
            noCoursesMessage={t("CourseManagement.YouAreNotEnrolledInAnyCourses")}
          />
        ),
      },
    },
    {
      menuItem: t("CourseManagement.CompletedCourses"),
      pane: {
        key: "CompletedCourses",
        content: (
          <MyCoursesList
            courses={completedCourses}
            noCoursesMessage={t("CourseManagement.NoCompletedCourses")}
          />
        ),
      },
    },
  ];
  var content = (
    <>
      <h1>{t("UserManagement.MyCourses")}</h1>
      <Tab panes={tabs} renderActiveOnly={false} />
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
