import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import agent from "../../api/agent";
import StudentCourseCertificateDto from "../../models/Courses/studentCourseCertificateDto";
import StudentCertificate from "./StudentCertificate";

interface Params {
  userId: string;
  courseId: string;
}

export default function DownloadStudentCertificate() {
  let { userId, courseId } = useParams<Params>();
  const [certificate, setCertificate] = useState<StudentCourseCertificateDto>();

  useEffect(() => {
    agent.Courses.studentCerticateInfo(+userId, +courseId).then((result) => {
      setCertificate(result);
    });
  }, [userId, courseId]);

  useEffect(() => {
    if (certificate) downloadCertificate();
  }, [certificate]);

  const downloadCertificate = async () => {
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      putOnlyUsedFonts: true,
    });
    const staticElement: HTMLElement | null = document.getElementById(`pdf-${certificate?.studentId}`);
    if (staticElement == null) return;
    const data = await html2canvas(staticElement);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    let fileName = certificate?.studentFirstName + "_" + certificate?.studentLastName + ".pdf";
    pdf.save(fileName);
  };

  return (
    <>
      <StudentCertificate certificate={certificate} />
    </>
  );
}
