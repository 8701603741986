import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Table, Checkbox, Icon, Segment, Loader, Dimmer, Button } from "semantic-ui-react";
import agent from "../../api/agent";
import CourseStudentSurveyDto from "../../models/Courses/courseStudentSurveyDto";
import CourseSummaryDto from "../../models/Courses/courseSummaryDto";
import { useStore } from "../../stores/store";

export interface Props {
  course: CourseSummaryDto | undefined;
  courseId: string;
  surveysUpdated: () => void;
  timeStamp: Date;
}

export default function CourseSurveys({ course, courseId, surveysUpdated, timeStamp }: Props) {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  const [edit, setEdit] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<CourseStudentSurveyDto[]>();
  const [originalSurveys, setOriginalSurveys] = useState<CourseStudentSurveyDto[]>();

  const handleError = (error: any) => toast.error(t("Common.GeneralErrorMessage"));

  const saveSurveys = () => {
    setSaving(true);
    if (surveys) {
      surveys.forEach((s) => {
        if (s.surveyFilled == null) s.surveyFilled = true;
      });

      agent.Courses.saveStudentSurveys(+courseId, surveys)
        .then((result) => {
          surveysUpdated();
        })
        .catch((error) => handleError(error))
        .finally(() => {
          setEdit(false);
          setSaving(false);
        });
    }
  };

  const changeStatus = (studentId: number, status: boolean | undefined) => {
    setSurveys((current) =>
      current?.map((obj) => {
        if (obj.studentId === studentId) return { ...obj, surveyFilled: status! };
        return obj;
      })
    );
  };

  useEffect(() => {
    agent.Courses.getStudentSurveys(+courseId)
      .then((result) => {
        setSurveys(result);
        setOriginalSurveys(result);
      })
      .catch((error) => handleError(error))
      .finally(() => setInProgress(false));
  }, [courseId, timeStamp]);

  const loader = (
    <Segment className="injaz-loader-tbl">
      <Dimmer active inverted>
        <Loader size="large">{t("Common.Loading")} ...</Loader>
      </Dimmer>
    </Segment>
  );

  const content = (
    <>
      {(userStore.isAdmin || userStore.isCourseManager) && !edit && course != null && (
        <Button className="attendance_btn" basic color="blue" disabled={course.isPublished} content={t("CourseManagement.Edit")} onClick={() => setEdit(true)} />
      )}
      {edit && (
        <div className="session-select-btns">
          <Button className="attendance_btn" color="blue" loading={saving} content={t("Common.Save")} onClick={() => saveSurveys()} />
          {!saving && (
            <Button
              className="attendance_btn"
              color="blue"
              content={t("Common.Cancel")}
              onClick={() => {
                setSurveys(originalSurveys);
                setEdit(false);
              }}
            />
          )}
        </div>
      )}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("UserManagement.FirstName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.LastName")}</Table.HeaderCell>
            <Table.HeaderCell>{t("UserManagement.EmailAddress")}</Table.HeaderCell>
            <Table.HeaderCell>{t("CourseManagement.SurveyFilled")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {surveys &&
            surveys.length > 0 &&
            surveys.map((survey, index) => (
              <Table.Row key={index}>
                <Table.Cell>{survey.studentFirstName}</Table.Cell>
                <Table.Cell>{survey.studentLastName}</Table.Cell>
                <Table.Cell>{survey.studentEmail}</Table.Cell>
                {!edit && (
                  <Table.Cell>
                    {survey.surveyFilled === true && <Icon color="olive" name="check"></Icon>}
                    {survey.surveyFilled === false && <Icon color="red" name="close"></Icon>}
                  </Table.Cell>
                )}
                {edit && (
                  <Table.Cell>
                    <Checkbox
                      toggle
                      checked={survey.surveyFilled != null ? survey.surveyFilled : true}
                      onChange={(e, d) => {
                        changeStatus(survey.studentId, d.checked);
                      }}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );

  return (
    <>
      {inProgress && loader}
      {!inProgress && content}
    </>
  );
}
