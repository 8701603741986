import React from "react";
import axios from "axios";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import {
  useMsal,
  MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

export default function AdminDashboard() {
  function WelcomeUser() {
    const { accounts } = useMsal();
    const username = accounts[0].username;
    return <p>Welcome, {username}</p>;
  }

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <div>
        <h1>Admin Dashboard</h1>
      </div>
      <AuthenticatedTemplate>
        <p>This will only render if a user is signed-in.</p>
        <WelcomeUser />
      </AuthenticatedTemplate>
    </>
  );
}
